import { Dispatch, SetStateAction, useContext, useRef, useState } from "react";
import { Alert, Button, Form, InputGroup, Modal, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import GlobalContext from "../Componets/GlobalContext";
import axios from "axios";
import DrawSpinner from "../Componets/DrawSpinner";
import { Helmet } from "react-helmet-async";
import { MdPreview, MdRemoveCircle } from "react-icons/md";
import { FaEye } from "react-icons/fa6";

interface recordFavoritesListItem {
    ID:number;
    QuestionID:number;
    LibraryID:number|null;
    LibraryName:number|null;
    Year:number|null;
    SeasonName:string|null;
    SpecID:number|null;
    SpecName:string|null;
    CategoryName:string|null;
    ContentShort:string|null;
};

interface recordFavoriteCategoryListItem {
    ID:number;
    Name:string;
    Total:number;
};

interface stateExamCreatePageFavorites{
    favCategories:recordFavoriteCategoryListItem[]|null;
    useCategory:number|null;
    reloadFavList:boolean;
    favList:recordFavoritesListItem[]|null;
    showRemoveConfirm:boolean;
    favRemove:number|null;
    favPreremove:number|null;
    favSelected:number[];
    favCreate:boolean;
    isMore:boolean;
    loadMore:boolean;
}

function ExamCreatePageFavoritesRemoveModal({state,setState}:{state:stateExamCreatePageFavorites,setState:Dispatch<SetStateAction<stateExamCreatePageFavorites>>}){
    if (state.favPreremove===null) return <></>

    let record = state.favList?.find(i=>i.QuestionID===state.favPreremove);
    if (!record) return <></>

    return <Modal backdrop="static" centered size="sm" show={true} onHide={()=>setState({...state,favPreremove:null})}>
        <Modal.Header closeButton>
            <Modal.Title>Usuwanie</Modal.Title>
        </Modal.Header>
        <Modal.Body>Czy na pewno chcesz usunąć wybrane pytanie z listy ulubionych?
            <b className="my-2">{record.ContentShort}...</b>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="danger" onClick={()=>setState({...state,favPreremove:null,favRemove:state.favPreremove})}>
                Tak
            </Button>
            <Button variant="secondary" onClick={()=>setState({...state,favPreremove:null})}>
                Nie
            </Button>
        </Modal.Footer>
    </Modal>
}

function ExamPageFavorites() {
    const {globalState,globalDispatch} = useContext(GlobalContext);
    let navigate                    = useNavigate();
    let [state,setState]            = useState<stateExamCreatePageFavorites>({reloadFavList:false,useCategory:null,favCategories:null,loadMore:false,isMore:false,favList:null,favRemove:null,showRemoveConfirm:false,favPreremove:null,favSelected:[],favCreate:false});
    let isRemoving                  = useRef<boolean>(false);
    let isCreating                  = useRef<boolean>(false);
    let inputName                   = useRef<HTMLInputElement|null>(null);
    let inputType                   = useRef<HTMLSelectElement|null>(null);
// console.log(state);
    if (state.favList===null || (state.useCategory !== null && state.reloadFavList)){
        axios.post('/pytanie/get-favorites',{questionID:state.useCategory,id:null}).then((r)=>{
            let newState = {...state,
                isMore : r.data.isMore,
                favList : r.data.data,
                reloadFavList:false
            };
            if (r.data.categories)
                newState.favCategories = r.data.categories;
            // console.log(newState);
            // console.log(r.data.favList);
            setState(newState);
        }).catch((e)=>{

        });
        return <><DrawSpinner text="Pobieranie Twoich ulubionych pytań..." /></>
    }

    if (state.isMore && state.loadMore){
        const last = state.favList[state.favList.length-1];
        if (last){
            axios.post('/pytanie/get-favorites',{id:last.ID,questionID:state.useCategory}).then((r)=>{
                const newList = state.favList?.concat(r.data.data);
                setState({...state,
                        favRemove:null,
                        showRemoveConfirm:false,
                        favCreate:false,
                        favPreremove:null,
                        isMore:r.data.isMore,loadMore:false,
                        favList:newList!});
            }).catch((e)=>{

            });
        }else{
            setState({...state,isMore:false,loadMore:false});
        }
    }

    if (state.favRemove!==null&&isRemoving.current===false){
        isRemoving.current=true;
        axios.post('/pytanie/favorite',{id:state.favRemove}).then((r)=>{
            isRemoving.current=false;
            setState({...state,
                      favRemove:null,
                      showRemoveConfirm:false,
                      favCreate:false,
                      favPreremove:null,
                      favSelected:[],
                      favList:state.favList?state.favList?.filter(i=>i.QuestionID!==state.favRemove):null});
        }).catch((e)=>{

        });
    }

    if (state.favCreate===true&&isCreating.current===false){
        isCreating.current=true;
        let selectedType = inputType.current?inputType.current.options[inputType.current.selectedIndex].value:'nauka';
        axios.post('/egzamin/favorite',{
                ids:state.favSelected,
                name:inputName.current?inputName.current.value:null,
                type:selectedType
        }).then((r)=>{
            navigate(`/app/egzamin/${r.data.data.ID}/1`);
        }).catch((e)=>{
            isCreating.current=false;
            setState({...state,favCreate:false});
        });
    }

    // console.log(globalState);

    return <>
        <Helmet>
            <title>Moje ulubione pytania</title>
        </Helmet>
        {state.favList.length > 0 ?
        <>
            <Table striped bordered hover responsive size="sm">
                <thead>
                    <tr>
                        <td className="text-center">
                            <Form.Check type="checkbox"
                                checked={state.favSelected.length===state.favList.length} 
                                onChange={()=>{
                                    state.favSelected.length===state.favList?.length?
                                        setState({...state,favSelected:[]})
                                        :
                                        setState({...state,favSelected:state.favList?state.favList.map(x=>x.QuestionID):[]})}
                                    }
                            />
                        </td>
                        <td>
                            <Form.Select aria-label="Kategoria ulubionych pytań" size="sm" value={state.useCategory?state.useCategory:''} onChange={(e:any) => setState({...state,reloadFavList:true,useCategory:e.target.value})}>
                                <option>Kategoria</option>
                                {state.favCategories?.map(function(c,idx){
                                    return <option value={c.ID} key={`category-drop-${idx}`}>{c.Name} - {c.Total} pytań</option>
                                })}
                            </Form.Select>
                        </td>
                        {globalState.user.SpecsCount!>1 ? <td>Specjalizacja</td> : null }
                        <td>Skrót</td>
                        <td></td>
                    </tr>
                </thead>
                <tbody>
                    {state.favList.map(function(i,idx){
                        let isChecked = state.favSelected.some(f=>f===i.QuestionID);
                        return <tr key={`fav-item-${idx}`}>
                            <td className="text-center">
                                <Form.Check type="checkbox" checked={isChecked}
                                    onChange={() => {isChecked?
                                                setState({...state,favSelected:state.favSelected.filter(f=>f!==i.QuestionID)})
                                                :
                                                setState({...state,favSelected:[...state.favSelected,i.QuestionID]})
                                    }}
                                    />
                            </td>
                            <td>{i.CategoryName?i.CategoryName:<>-</>}</td>
                            {globalState.user.SpecsCount!>1 ? <td>{i.SpecName?i.SpecName:<>-</>}</td> : null }
                            <td>{i.ContentShort}...</td>
                            <td>
                                <Button variant="secoundary" size="sm"  onClick={()=>navigate(`/app/pytanie/${i.QuestionID}`,{state:{back:'/app/egzaminy/ulubione-pytania'}})}>
                                    <FaEye /></Button>

                                <Button disabled={state.favRemove!==null} variant="secoundary" size="sm" title="Usuń z ulubionych" 
                                    onClick={()=>setState({...state,favPreremove:i.QuestionID})}><MdRemoveCircle /></Button>
                            </td>
                        </tr>
                    })}
                </tbody>
                <caption>{state.favList.length} pytań w ulubionych. 
                    <Button disabled={!state.isMore || state.loadMore} variant="primary" size="sm" className="ms-2" onClick={()=>setState({...state,loadMore:true})}>
                        <DrawSpinner show={state.loadMore} size="sm" />
                        Pobierz więcej ulubionych
                    </Button>
                </caption>
            </Table>
            
            
            <InputGroup className="mb-5">
                <Form.Select ref={inputType} disabled={state.favCreate} aria-label="Wybierz tryb">
                    <option value="nauka">Tryb nauki</option>
                    <option value="egzamin">Tryb egzaminu</option>
                </Form.Select>
                <Form.Control maxLength={40} ref={inputName} disabled={state.favCreate} type="text" placeholder="Nazwa..." aria-label="Wprowadź nazwę dla egzaminu..." />
                <Button disabled={state.favCreate || state.favSelected.length===0} onClick={()=>setState({...state,favCreate:true})}>
                    <DrawSpinner show={state.favCreate} size="sm" />
                    Rozpocznij
                </Button>
            </InputGroup>
            <ExamCreatePageFavoritesRemoveModal state={state} setState={setState} />
            </>
        :
        <Alert variant="info">Tutaj pojawi się lista ulubionych Twoich pytań, każde pytanie na które odpowiadasz możesz dodać do ulubionych.</Alert> }
        </>
}

export default ExamPageFavorites;