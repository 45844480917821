import { Alert, Button, Col, FloatingLabel, Form, InputGroup, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import ExamNav from "../Exam/Nav";
import { useContext, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as formik from 'formik';
import * as yup from 'yup';
import axios from "axios";
import AsyncSelect from 'react-select/async';
import { BtnBold, BtnBulletList, BtnClearFormatting, BtnItalic, BtnNumberedList, BtnStrikeThrough, BtnUnderline, Editor, EditorProvider, Toolbar } from "react-simple-wysiwyg";
import { RecordCategory } from "../Type/RecordCategory";
import DrawSpinner from "../Componets/DrawSpinner";
import { RowSpec } from "../Type/RowSpec";
import { RecordTag } from "../Type/RecordTag";
import Select, { ActionMeta, MultiValue, OnChangeValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { RecordSource } from "../Type/RecordSource";
import { MdAdd, MdRemove } from "react-icons/md";
import GlobalContext from "../Componets/GlobalContext";
import { recordQuestionEditV2, recordQuestionV2 } from "../Componets/QuestionV2/Record";
import { QuestionV2, stateQuestionV2 } from "../Componets/QuestionV2/Question";

interface UserLibraryIndexPageProps{
    page?:string;
}

function UserLibraryQuestionPageAnswerButtonCorrect({label,value,onClick,CurrentCorrectAnswer,hasError}:{hasError:boolean,CurrentCorrectAnswer:string,value:string,label:string,onClick:()=>void}){
    let btnVariant = 'success';
    let btnLabel   = 'Odpowiedź prawidłowa';
    if (CurrentCorrectAnswer){
        if (CurrentCorrectAnswer!==value){
            btnVariant = 'warning';
            btnLabel   = `Zmień odpowiedź prawidłową na ${value}`;
        }else{
            // btnLabel   = 'O'
        }
    }
    return <>
        <label htmlFor={`questionAnswer-${value}`} className="col-sm-2 col-form-label"><span>{label}</span>
            <Button disabled={CurrentCorrectAnswer===value} className="ms-1 ms-md-0" 
                    variant={btnVariant} size="sm" onClick={onClick}>{btnLabel}</Button>
            <Form.Control.Feedback type="invalid" className={hasError?'d-block':undefined}>Pytanie musi posiadać informacje o poprawnej odpowiedzi.</Form.Control.Feedback>
        </label>
    </>
}

function isinvalidClass(b:string|false|undefined|string[]|never[]){
    if (b) return 'is-invalid';
    return undefined;
}

function UserLibraryQuestionPage(){
    let [categories,setCategories] = useState<{label:string,value:number}[]|null|"loading">(null);
    let [specs,setSpecs]           = useState<{label:string,value:number}[]|null|"loading">(null);
    let [tags,setTags]             = useState<{label:string,value:number}[]|null|"loading">(null);
    let [sources,setSources]       = useState<{label:string,value:number}[]|null|"loading">(null);
    let [question,setQuestion]     = useState<recordQuestionEditV2|'loading'|'error'|null>(null);
    let { questionID }             = useParams();
    const {globalState,flashMessages,globalDispatch}    = useContext(GlobalContext);
    let navigate                                        = useNavigate();
    let iQuestionID = null;
    if (questionID!==undefined&&questionID!==null)
        iQuestionID = parseInt(questionID);

    let defInitialValues : 
        {ID:number|null,SpecID:number|null,Content:string|null,ContentAbout:string|null,CorrectAnswer:string|null,IsPublished:number,Category:string|null,Tags:string[],Source:{ID:number|null,Addon:string|null,Name:string|null}[],
        AnswerA:string|null,AnswerB:string|null,AnswerC:string|null,AnswerD:string|null,AnswerE:string|null,} 
    =  {ID:null,
        SpecID:null,
        Content:null,
        ContentAbout:null,
        CorrectAnswer:null,
        IsPublished:0,
        Category:null,
        Tags:[],
        Source:[{Addon:null,Name:null,ID:null}],
        AnswerA:null,AnswerB:null,AnswerC:null,AnswerD:null,AnswerE:null,};

    if (iQuestionID!==null&&question===null){
        setQuestion('loading');
        axios.post(`/v2/pytanie/get-to-edit`,
            {id:iQuestionID}
        ).then((response) => {
            setQuestion(response.data.data);
            let q = response.data.data as recordQuestionEditV2;
            defInitialValues.AnswerA = q.AnswerA;
            defInitialValues.AnswerB = q.AnswerB;
            defInitialValues.AnswerC = q.AnswerC;
            defInitialValues.AnswerD = q.AnswerD;
            defInitialValues.AnswerE = q.AnswerE;
            defInitialValues.CorrectAnswer = q.CorrectAnswer;
            defInitialValues.ID = q.ID;
            defInitialValues.Content = q.Content;
            defInitialValues.ContentAbout = q.ContentAbout;
            defInitialValues.Category = q.CategoryName;
            defInitialValues.IsPublished = q.IsPublished;
            defInitialValues.SpecID = q.SpecID;
            defInitialValues.Tags   = q.Tags;
            defInitialValues.Source = q.Sources;
            f.setValues(defInitialValues);
        }, (error) => {
            setQuestion('error');
        });
    }

    const schema = yup.object({
        ID:yup.number().notRequired().nullable(),
        SpecID:yup.number().required('Pytanie musi zostać przydzielone do specjalizacji'),
        Content:yup.string().required('Pytanie musi składać się z treści zadania.'),
        ContentAbout:yup.string().required('Omówienie pytania jest wymagane.'),
        CorrectAnswer:yup.string().required(),
        IsPublished:yup.number().required(),
        Category:yup.string().required('Pytanie musi zostać przydzielone do kategorii.'),
        Source:yup.array().of(
            yup.object({
                Name:yup.string().required("Musisz podać źródło do pytania.").nonNullable("Pytanie musi mięc podane źródło."),
                Addon:yup.string().notRequired().nullable()
            })
        ).required(),
        Tags:yup.array().of(
            yup.string().required("Dodaj tagi do pytania.")
        ).required(),
        // Answer:yup.array().length(5).of(
        //     yup.object({
        //         Letter:yup.string().required(),
        //         Content:yup.string().required()
        //     })
        // )
        AnswerA:yup.string().required('Odpowiedź jest wymagana.'),
        AnswerB:yup.string().required('Odpowiedź jest wymagana.'),
        AnswerC:yup.string().required('Odpowiedź jest wymagana.'),
        AnswerD:yup.string().required('Odpowiedź jest wymagana.'),
        AnswerE:yup.string().required('Odpowiedź jest wymagana.'),
    });
    
    const f = formik.useFormik({
        validationSchema:schema,
        onSubmit(values, formikHelpers) {
            axios.post(`/v2/pytanie/pytanie-uzytkownika`,values).then((r)=>{
                
                if (r.data.data.isValid===true){
                    let row = r.data.data.data as recordQuestionV2;
                    globalDispatch({type:'SET_MESSAGES',flashMessages:[{type:'success',text:`Twoje pytanie zostało zapisane, dla <b>${row.SpecName}</b> w katalogu pytań autorskich <b>${row.LibraryName}</b>.`,time:r.data.time}]});
                    navigate('/app/egzaminy/pytania-uzytkownikow');
                }else{
                    // console.log(r.data);
                    if (r.data.data.messages){
                        // console.log(typeof r.data.data.messages);
                        // console.log(r.data.data.messages);
                        Object.keys(r.data.data.messages).map((k:any)=>{
                            f.setFieldError(k, Object.values(r.data.data.messages[k])! );
                        });
                        // f.

                        // f.setErrors(r.data.data.messsages);
                        // console.log(r.data.data);
                        // r.data.data.messages.map((i:any,idx:any)=>{
                        //     console.log(i);
                        // });
                    }
                }
            }, (e) => {
            });
        },
        initialValues:defInitialValues
    });
    

    

    if (iQuestionID!==null&&(question==='loading'||question==='error'||question===null)){
        return <>
        <Helmet>
            <title>Pytanie do użytkowników</title>
        </Helmet>
        <Row>
            <Col xs={12}>
                <DrawSpinner show={question==='loading'} text="Pobieranie informacji o pytaniu do edycji..." />
                {/* {question==='error'?<Alert variant="danger"><p className="mb-0">Nie udało się pobrać informacji o pytaniu.</p></Alert>:null} */}
            </Col>
        </Row>
        </>
    }
/*
    if (iQuestionID!==null&&question){
        let q = question as recordQuestionV2;
        let newValues = {ID: q.ID,
            IsPublished: 1,
            SpecID: q.SpecID!,
            CorrectAnswer:q.CorrectAnswer,
            Content : q.Content!,
            ContentAbout: q.Content!,
            Category: q.CategoryName!,
            Tags:[],
            Source:[],
            AnswerA:q.Options?.find(x=>x.Letter==='A')?.Content,
            AnswerB:q.Options?.find(x=>x.Letter==='B')?.Content,
            AnswerC:q.Options?.find(x=>x.Letter==='C')?.Content,
            AnswerD:q.Options?.find(x=>x.Letter==='D')?.Content,
            AnswerE:q.Options?.find(x=>x.Letter==='E')?.Content,};
        if ((q.Sources?.length??0)>0){
            newValues.Source =  q.Sources?.reduce<{Name:string,Addon:string}[]>(function(prev,c){
                prev.push({Name:c.Content,Addon:c.Addon});
            },[]);
        }
        if ((q.Tags?.length??0)>0){
            newValues.Tags =  q.Tags?.reduce<string[]>(function(prev,c){
                prev.push(c.Name);
                return prev;
            },[]);
        }
        
        // console.log(newValues);
        // f.setValues(newValues!);
        // f.setFieldValue("Category",q.CategoryName);
        // f.setFieldValue("SpecID",q.SpecID);
        // f.setFieldValue("Content",q.Content);
        // f.setFieldValue("AnswerA",q.Options?.find(x=>x.Letter==='A')?.Content);
        // f.setFieldValue("AnswerB",q.Options?.find(x=>x.Letter==='B')?.Content);
        // f.setFieldValue("AnswerC",q.Options?.find(x=>x.Letter==='C')?.Content);
        // f.setFieldValue("AnswerD",q.Options?.find(x=>x.Letter==='D')?.Content);
        // f.setFieldValue("AnswerE",q.Options?.find(x=>x.Letter==='E')?.Content);
    }*/

    function onChange(e:any) {
        f.setFieldValue("Content",e.target.value);
    }

    if (categories===null){
        setCategories('loading');
        axios.post(`/v2/pytanie/kategorie`).then((r)=>{
            let cats = r.data.data as RecordCategory[];
            setCategories(cats.map(function(i){return {label:i.Name,value:i.ID}}));
        })
    }
    if (tags===null){
        setTags('loading');
        axios.post(`/v2/pytanie/tags`).then((r)=>{
            let cats = r.data.data as RecordTag[];
            setTags(cats.map(function(i){return {label:i.Name,value:i.ID}}));
        })
    }
    if (specs===null){
        setSpecs('loading');
        axios.post(`/specjalizacja/list`).then((r)=>{
            let l = r.data.data as RowSpec[];
            setSpecs(l.map(function(i){return {label:i.Name,value:i.ID}}));
            if (l.length===1){
                f.setFieldValue("SpecID",l[0].ID);
            }
        })
    }
    if (sources===null){
        setSources('loading');
        axios.post(`/v2/pytanie/sources`).then((r)=>{
            let cats = r.data.data as RecordSource[];
            setSources(cats.map(function(i){return {label:i.Name,value:i.ID}}));
        })
    }

    function onChangeTags(newValue:MultiValue<{label:string,value:number}>|null, actionMeta:any|null){
        if (newValue===null)
            f.setFieldValue("Tags",null);
        else
            f.setFieldValue("Tags",newValue?.map(function(i){return i.label}));
    }

    // let selectedTags = null;
    // if (f.values.Tags&&tags!==null&&tags!=='loading'){
    //     if (f.values.Tags){
    //         if ((f.values.Tags?.length??0)>0){
    //             // if (tags.length)
    //             let lTags = f.values.Tags as string[];
    //             selectedTags = tags.filter(x=>lTags.includes(x.label));
    //         }
    //     }
    // }

    // console.log(f.values);
    // console.log(f.errors.Tags);

    let correctAnswerRequired = f.touched.CorrectAnswer&&f.errors.CorrectAnswer?true:false;

    // console.log(question?{value:question?.SpecID,label:question?.SpecName}:null);

    // let selectedSpec        = null;
    // let selectedCategory    = null;
    // let selectedTags        = null;
    // // let selectedSources     = null;
    // if (question !== 'loading' && question !== 'error' && question !== null){
    //     if (specs !== 'loading'){
    //         if (specs && question.SpecID){
    //             selectedSpec        = specs.find(x=>x.value===f.values.SpecID);
    //         }
    //     }

    //     if (categories !== 'loading'){
    //         if (categories && question.CategoryID){
    //             selectedCategory   = categories.find(x=>x.label===f.values.Category);
    //         }
    //     }

    //     if (tags!=='loading'){
    //         if (tags && question.Tags){
    //             selectedTags = tags.filter(x=>f.values.Tags.includes(x.label));
    //         }
    //     }

    //     // if (sources!=='loading'){

    //     // }
    // }

    // console.log(f.errors);
    // console.log(f.values);

    return <>
        <Helmet>
            <title>Pytanie do użytkowników</title>
        </Helmet>
        <Row>
            <Col xs={12}>
                <Form noValidate onSubmit={f.handleSubmit}>
                    <Form.Control type="hidden" name="ID" defaultValue={f.values.ID!} />
                    {f.values.ID === null ?
                    <div className="row mb-1">
                        <label htmlFor="qSoec" className="col-sm-2 col-form-label">Specjalizacja</label>
                        <div className="col-sm-10">
                            {specs===null||specs==='loading'?
                                <DrawSpinner show={specs===null||specs==='loading'} />:
                                <Select value={specs.find(x=>x.value===f.values.SpecID)}
                                        className={isinvalidClass(f.touched.SpecID&&f.errors.SpecID)}
                                        placeholder="Wybierz specjalizację..." 
                                        isSearchable={true} 
                                        options={specs!} 
                                        onChange={(e)=>f.setFieldValue("SpecID",e?.value??null)}  
                                        // isValid={f.touched.SpecID&&!f.errors.SpecID} isInvalid={f.touched.favNIP&&f.errors.favNIP?true:false} 
                                        />}
                            <Form.Control.Feedback type="invalid">{f.errors.SpecID}</Form.Control.Feedback>
                        </div>
                    </div>: null}
                    <div className="row mb-1">
                        <label htmlFor="qCat" className="col-sm-2 col-form-label">Kategoria</label>
                        <div className="col-sm-10">
                            {categories===null||categories==='loading'?
                                <DrawSpinner show={true} />:
                                <Select value={categories.find(x=>x.label===f.values.Category)} 
                                        className={isinvalidClass(f.touched.Category&&f.errors.Category)} 
                                        placeholder="Wybierz kategorię..." 
                                        isSearchable={true} 
                                        options={categories!} 
                                        onChange={(e)=>f.setFieldValue("Category",(e?.label??null))} />}
                            <Form.Control.Feedback type="invalid">{f.errors.Category}</Form.Control.Feedback>
                        </div>
                    </div>
                    <div className="row mb-1">
                        <label htmlFor="qCat" className="col-sm-2 col-form-label">Tagi</label>
                        <div className="col-sm-10">
                            {tags===null||tags==='loading'?
                                <DrawSpinner show={true} />:
                                <Select 
                                    // defaultValue={question?}
                                    className={isinvalidClass(f.touched.Tags&&f.errors.Tags)}
                                    placeholder="Wybierz tagi..." 
                                    isSearchable={true} 
                                    isMulti 
                                    options={tags!} 
                                    onChange={onChangeTags} 
                                    value={tags.filter(x=>f.values.Tags.includes(x.label))} />}
                            <Form.Control.Feedback type="invalid">{f.errors.Tags}</Form.Control.Feedback>
                        </div>
                    </div>
                    <div className="row mb-1">
                        <div className="col-sm-2"></div>
                        <div className="col-sm-10">
                            <Form.Check type="checkbox" 
                                        id={`IsPublished`} 
                                        label="Chcę opublikować pytanie w katalogu pytań autorskich użytkowników" 
                                        checked={f.values.IsPublished?true:false} 
                                        onChange={(e)=>f.setFieldValue("IsPublished",e.target.checked?1:0)} />
                            <Form.Control.Feedback type="invalid">{f.errors.IsPublished}</Form.Control.Feedback>
                        </div>
                    </div>
                    
                        
                        {f.values.Source.map(function(i,idx){
                            let valueCopy = f.values.Source;

                            function changeSource(e:any){
                                valueCopy[idx].Name=e.label;
                                f.setFieldValue("Source",valueCopy);
                            }
                            function changeSourceAddon(e:any){
                                valueCopy[idx].Addon=e.target.value;
                                f.setFieldValue("Source",valueCopy);
                            }

                            let errors = f.errors.Source?.at(idx);
                            // console.log(errors);

                            // let selectedSource = null;
                            // if (question !== 'loading' && question !== 'error' && question !== null && sources!=='loading'){
                            //     if (sources&&question.Sources){
                            //         selectedSource = sources.find(x=>x.label===i.Name);
                            //     }
                            // }

                            // console.log(valueCopy);
                            // console.log(selectedSource);

                            return <div className="row mb-1" key={`questionSource-${idx}`}>
                                <Form.Control type="hidden" defaultValue={i.ID!} />
                                <label htmlFor="qCat" className="col-sm-2 col-form-label">Źródło</label>
                                <div className="col-sm-10">
                                    <div className="row">
                                        <div className="col-md-8">
                                            {sources===null||sources==='loading'?
                                                <DrawSpinner show={true} />:
                                                <Select value={sources.find(x=>x.label===i.Name)}
                                                        placeholder="Wybierz źródło..." isSearchable={true} options={sources!} 
                                                        // defaultValue={i.Name} 
                                                        // className={isinvalidClass(f.touched.Source?.at(idx)&&f.errors.Source?.at(idx))} 
                                                        onChange={changeSource} />}
                                                <Form.Control.Feedback type="invalid" className={errors!==undefined?'d-block':undefined}>{errors!==undefined?'Źródło jest wymagane.':undefined}</Form.Control.Feedback>
                                        </div>
                                        <div className="col-md-3">
                                            <Form.Control type="text" placeholder="Np. strona...." className="mt-md-0 mt-1" value={i.Addon!}
                                                onChange={changeSourceAddon}
                                             />
                                        </div>
                                        <div className="col-md-1">
                                            {idx===0?
                                                <Button onClick={()=>f.setFieldValue("Source",[...f.values.Source,{Name:null,Addon:null}])}><MdAdd /></Button>
                                                :
                                                <Button variant="danger" onClick={()=>f.setFieldValue("Source",f.values.Source.toSpliced(idx,1))}><MdRemove /></Button>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })}
                    
                    <div className="row mb-1">
                        <label htmlFor="qCat" className="col-sm-2 col-form-label">Treść zadania</label>
                        <div className="col-sm-10">
                        <EditorProvider>
                            <Editor value={f.values.Content!} onChange={(e)=>f.setFieldValue("Content",e.target.value)} className={isinvalidClass(f.touched.Content&&f.errors.Content)}>
                                <Toolbar>
                                    <BtnBold />
                                    <BtnItalic />
                                    <BtnStrikeThrough />
                                    <BtnUnderline />
                                    <BtnBulletList />
                                    <BtnNumberedList />
                                    <BtnClearFormatting />
                                </Toolbar>
                            </Editor>
                        </EditorProvider>
                        <Form.Control.Feedback type="invalid" className={f.touched.Content&&f.errors.Content?'d-block':undefined}>{f.errors.Content}</Form.Control.Feedback>
                        </div>
                    </div>
                    <div className="row mb-1">
                        <label htmlFor="qCat" className="col-sm-2 col-form-label">Omówienie</label>
                        <div className="col-sm-10">
                        <EditorProvider>
                            <Editor value={f.values.ContentAbout!} onChange={(e)=>f.setFieldValue("ContentAbout",e.target.value)} className={isinvalidClass(f.touched.ContentAbout&&f.errors.ContentAbout)}>
                                <Toolbar>
                                    <BtnBold />
                                    <BtnItalic />
                                    <BtnStrikeThrough />
                                    <BtnUnderline />
                                    <BtnBulletList />
                                    <BtnNumberedList />
                                    <BtnClearFormatting />
                                </Toolbar>
                            </Editor>
                        </EditorProvider>
                        <Form.Control.Feedback type="invalid" className={f.touched.ContentAbout&&f.errors.ContentAbout?'d-block':undefined}>{f.errors.ContentAbout}</Form.Control.Feedback>
                        </div>
                    </div>
                    <div className="row mb-1">
                        <UserLibraryQuestionPageAnswerButtonCorrect hasError={correctAnswerRequired} label="Odpowiedź A" value="A" onClick={()=>f.setFieldValue("CorrectAnswer",'A')} CurrentCorrectAnswer={f.values.CorrectAnswer!} />
                        <div className="col-sm-10">
                        <EditorProvider>
                            <Editor value={f.values.AnswerA!} onChange={(e)=>f.setFieldValue("AnswerA",e.target.value)} className={isinvalidClass(f.touched.AnswerA&&f.errors.AnswerA)}>
                                <Toolbar>
                                    <BtnBold />
                                    <BtnItalic />
                                    <BtnStrikeThrough />
                                    <BtnUnderline />
                                </Toolbar>
                            </Editor>
                        </EditorProvider>
                        <Form.Control.Feedback type="invalid" className={f.touched.AnswerA&&f.errors.AnswerA?'d-block':undefined}>{f.errors.AnswerA}</Form.Control.Feedback>
                        </div>
                    </div>
                    <div className="row mb-1">
                        <UserLibraryQuestionPageAnswerButtonCorrect hasError={correctAnswerRequired} label="Odpowiedź B" value="B" onClick={()=>f.setFieldValue("CorrectAnswer",'B')} CurrentCorrectAnswer={f.values.CorrectAnswer!} />
                        <div className="col-sm-10">
                        <EditorProvider>
                            <Editor value={f.values.AnswerB!} onChange={(e)=>f.setFieldValue("AnswerB",e.target.value)} className={isinvalidClass(f.touched.AnswerB&&f.errors.AnswerB)}>
                                <Toolbar>
                                    <BtnBold />
                                    <BtnItalic />
                                    <BtnStrikeThrough />
                                    <BtnUnderline />
                                </Toolbar>
                            </Editor>
                        </EditorProvider>
                        <Form.Control.Feedback type="invalid" className={f.touched.AnswerB&&f.errors.AnswerB?'d-block':undefined}>{f.errors.AnswerB}</Form.Control.Feedback>
                        </div>
                    </div>
                    <div className="row mb-1">
                        <UserLibraryQuestionPageAnswerButtonCorrect hasError={correctAnswerRequired} label="Odpowiedź C" value="C" onClick={()=>f.setFieldValue("CorrectAnswer",'C')} CurrentCorrectAnswer={f.values.CorrectAnswer!} />
                        <div className="col-sm-10">
                        <EditorProvider>
                            <Editor value={f.values.AnswerC!} onChange={(e)=>f.setFieldValue("AnswerC",e.target.value)} className={isinvalidClass(f.touched.AnswerC&&f.errors.AnswerC)}>
                                <Toolbar>
                                    <BtnBold />
                                    <BtnItalic />
                                    <BtnStrikeThrough />
                                    <BtnUnderline />
                                </Toolbar>
                            </Editor>
                        </EditorProvider>
                        <Form.Control.Feedback type="invalid" className={f.touched.AnswerC&&f.errors.AnswerC?'d-block':undefined}>{f.errors.AnswerC}</Form.Control.Feedback>
                        </div>
                    </div>
                    <div className="row mb-1">
                        <UserLibraryQuestionPageAnswerButtonCorrect hasError={correctAnswerRequired} label="Odpowiedź D" value="D" onClick={()=>f.setFieldValue("CorrectAnswer",'D')} CurrentCorrectAnswer={f.values.CorrectAnswer!} />
                        <div className="col-sm-10">
                        <EditorProvider>
                            <Editor value={f.values.AnswerD!} onChange={(e)=>f.setFieldValue("AnswerD",e.target.value)} className={isinvalidClass(f.touched.AnswerD&&f.errors.AnswerD)}>
                                <Toolbar>
                                    <BtnBold />
                                    <BtnItalic />
                                    <BtnStrikeThrough />
                                    <BtnUnderline />
                                </Toolbar>
                            </Editor>
                        </EditorProvider>
                        <Form.Control.Feedback type="invalid" className={f.touched.AnswerD&&f.errors.AnswerD?'d-block':undefined}>{f.errors.AnswerD}</Form.Control.Feedback>
                        </div>
                    </div>
                    <div className="row mb-1">
                        <UserLibraryQuestionPageAnswerButtonCorrect hasError={correctAnswerRequired} label="Odpowiedź E" value="E" onClick={()=>f.setFieldValue("CorrectAnswer",'E')} CurrentCorrectAnswer={f.values.CorrectAnswer!} />
                        <div className="col-sm-10">
                        <EditorProvider>
                            <Editor value={f.values.AnswerE!} onChange={(e)=>f.setFieldValue("AnswerE",e.target.value)} className={isinvalidClass(f.touched.AnswerE&&f.errors.AnswerE)}>
                                <Toolbar>
                                    <BtnBold />
                                    <BtnItalic />
                                    <BtnStrikeThrough />
                                    <BtnUnderline />
                                </Toolbar>
                            </Editor>
                        </EditorProvider>
                        <Form.Control.Feedback type="invalid" className={f.touched.AnswerE&&f.errors.AnswerE?'d-block':undefined}>{f.errors.AnswerE}</Form.Control.Feedback>
                        </div>
                    </div>
                    <div className="d-grid mt-5">
                        <Button type="submit">{f.values.ID!==null?'Zapisz zmiany w pytaniu':'Dodaj moje pytanie'}</Button>
                    </div>
                </Form>
            </Col>
        </Row>
    </>
}

function DrawMyQuestion({item}:{item:recordQuestionV2}){
    let [state,setState] = useState<stateQuestionV2>({isLoading:null,resetAnswer:false,showAbout:false,showAnswer:false,showComments:false});
    return <>
        <Col xs={12} className="mb-2">
            <QuestionV2 rowQuestion={item} state={state} setState={setState} mode="userQuestion" disableCommentsBtn={true} />
        </Col>
    </>
}

export default function UserLibraryIndexPage({page}:UserLibraryIndexPageProps){
    let [filter,setFilter]              = useState<string>('');
    let [myQuestions,setMyQuestions]    = useState<recordQuestionV2[]|null|'loading'|'error'>(null);
    let navigate                        = useNavigate();

    if (page==='question'){
        return <UserLibraryQuestionPage />
    }

    if (myQuestions===null){
        setMyQuestions('loading');
        axios.get(`/v2/pytanie/moje`).then((r) => {
            setMyQuestions(r.data.data);
        }, (error) => {
            setMyQuestions('error');
        });
    }

    let filtered = myQuestions;
    if (myQuestions!==null&&myQuestions!=='loading'&&myQuestions!=='error'){
        if (filter.length>2){
            filtered    = myQuestions.filter(x=>x.Content.includes(filter));
        }
    }

    return <>
        <Helmet>
            <title>Biblioteka pytań użytkowników</title>
        </Helmet>
        <Row>
            <Col xs={12}>
                <Alert variant="info">
                    <p>Jeśli chcesz możesz stworzyć pytanie publiczne i podzielić się nim z innymi użytkownikami, którzy się uczą.</p>
                    <p>Pytanie musi składać się z treści zadania oraz 5 odpowiedzi (A,B,C,D,E), dla ułatwienia nauki prosimy dodaj również kategorię, tagi oraz omówienie do pytania.</p>
                    <Button size="lg" onClick={()=>navigate('/app/egzaminy/pytania-uzytkownikow/pytanie')}>Dodaj pytanie</Button>
                </Alert>
                
                <hr />
                <FloatingLabel controlId="itemsFilter" label="Filtruj... Czego szukasz?" className="mb-3">
                    <Form.Control type="text" placeholder="np. PES" onChange={(e)=>setFilter(e.target.value!)} autoComplete="false" />
                </FloatingLabel>
            </Col>
            <Col xs={12}>
                <DrawSpinner show={myQuestions==='loading'} text="Pobieranie listy Twoich pytań...." />
                {filtered!==null&&filtered!=='loading'&&filtered!=='error'?
                    filtered.length>0?<Row>{filtered.map(function(i){
                        return <DrawMyQuestion item={i} />
                    })}</Row>:
                        filter.length<2?<Alert variant="info"><p>Nie masz jeszcze utworzonych pytań.</p></Alert>:<Alert variant="info"><p>Żadne z pytań nie pasuje do filtra.</p></Alert>
                :null}
            </Col>
        </Row>
    </>
}