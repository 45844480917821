import { Helmet } from 'react-helmet-async';
import { useParams } from "react-router-dom";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RowExamNew } from "../Type/RowExamNew";
import {  Alert, Button, Placeholder } from "react-bootstrap";
import QuestionLoadingPlaceholder from "../Componets/QuestionLoadingPlaceholder";
import axios from "axios";
import { RowExamIndex } from "../Type/RowExamIndex";
import { QuestionV2, stateQuestionV2 } from '../Componets/QuestionV2/Question';
import { recordQuestionV2 } from '../Componets/QuestionV2/Record';

function MyExamPagePlaceholder(){
    return <>
        <Helmet><title>Mój egzamin...</title></Helmet>
        <QuestionLoadingPlaceholder />
    </>
}


function MyExamPagePaginatorButton({examType,correctAnswer,userAnswer,isActive,order,examID}:{examType:string|null,correctAnswer:string,userAnswer:string|null,isActive:boolean,order:number,examID:number}){
    let navigate = useNavigate();
    if (examType==='egzamin')
    {
        if (correctAnswer==='X')
            return <Button active={isActive} size="sm" className="m-1" variant="outline-warning" onClick={() => navigate(`/app/egzamin/${examID}/${order}`)} key={`page-${order}`}>{order}</Button>
        if (userAnswer===null)
            return <Button active={isActive} size="sm" className="m-1" variant="outline-light" onClick={() => navigate(`/app/egzamin/${examID}/${order}`)} key={`page-${order}`}>{order}</Button>
        else
            return <Button active={isActive} size="sm" className="m-1" variant="dark" onClick={() => navigate(`/app/egzamin/${examID}/${order}`)} key={`page-${order}`}>{order}</Button>
    }
    else
    {
        if (correctAnswer==='X')
            return <Button active={isActive} size="sm" className="m-1" variant="outline-warning" onClick={() => navigate(`/app/egzamin/${examID}/${order}`)} key={`page-${order}`}>{order}</Button>
        if (userAnswer===null)
            return <Button active={isActive} size="sm" className="m-1" variant="outline-light" onClick={() => navigate(`/app/egzamin/${examID}/${order}`)} key={`page-${order}`}>{order}</Button>
        else{
            // console.log(correctAnswer+'-'+userAnswer);
            if (correctAnswer===userAnswer)
                return <Button active={isActive} size="sm" className="m-1" variant="success" onClick={() => navigate(`/app/egzamin/${examID}/${order}`)} key={`page-${order}`}>{order}</Button>
            else
                return <Button active={isActive} size="sm" className="m-1" variant="danger" onClick={() => navigate(`/app/egzamin/${examID}/${order}`)} key={`page-${order}`}>{order}</Button>
        }
    }
}

function MyExamPagePaginator({examID,current,rowExam,examIndexData}:{examID:number,current:number,rowExam:RowExamNew,examIndexData:Array<RowExamIndex>|null}){
    if (examIndexData===null)return <></>;
    return <>
            {examIndexData.map(function(item,idx){
                return <MyExamPagePaginatorButton key={`paginator-btn-${item.Order}`}
                                                  examType={rowExam.Type} 
                                                  examID={examID} order={item.Order}
                                                  userAnswer={item.Answer} correctAnswer={item.CorrectAnswer}
                                                  isActive={current===item.Order} />
            })}
    </>
}

function MyExamPageDrawQuestion({iQuestionOrder,examData,question,setQuestion,questionState,setQuestionState,onParentOptionClick}:
        {iQuestionOrder:number,examData:RowExamNew,question:recordQuestionV2|'error'|'access-denied',setQuestion:Dispatch<SetStateAction<recordQuestionV2|null|'error'|'access-denied'>>,
            questionState:stateQuestionV2,setQuestionState:Dispatch<SetStateAction<stateQuestionV2>>,
            onParentOptionClick?:(answer:string|null,questionID:number) => void
        }){
    let [ loading, setLoading ]                     = useState<string|null>(null);
    let [ error, setError ]                         = useState<string|null>(null);
    let navigate                                    = useNavigate();

    if (question===null){
        if (loading===null&&error===null){
            setLoading('getExamQuestion');
            axios.post(`/v2/pytanie/get-exam-question`,
                {UserExamID:examData.ID,Order:iQuestionOrder,QuestionID:null}
            ).then((response) => {
                if (response.data.access==='Brak dostępu.'){
                    setQuestion('access-denied');
                }
                else
                    setQuestion(response.data.data);
                setLoading(null);
            }, (error) => {
                setLoading(null);
                setError('Nie udało się pobrać indeksu pytań w Twoim egzaminie.');
            });
        }
    }

    if (question===null){
        return <MyExamPagePlaceholder />
    }

    function navNextQuestion(){
        let next = iQuestionOrder + 1;
        // console.log({current:iQuestionOrder,next:next});
        // setQuestion(null);
        navigate(`/app/egzamin/${examData?.ID}/${next}`);
    }

    function navPrevQuestion(){
        let prev = iQuestionOrder - 1;
        // setQuestionState({...questionState,showAnswer:false,showComments:false,showAbout:false});
        // setQuestion(null);
        navigate(`/app/egzamin/${examData?.ID}/${prev}`);
    }

    function onOptionClick(option:string|null,questionID:number){
        if (question==='error'||question==='access-denied')
            return;
        if (question?.ID===questionID&&question){
            setQuestion({...question,UserExamAnswer:option});
            if (onParentOptionClick) onParentOptionClick(option,questionID);
        }
        if (examData?.Type==='egzamin'){
            if (iQuestionOrder!==examData.QuestionCount){
                navNextQuestion();
            }
        }
    }

    return <QuestionV2 setState={setQuestionState} state={questionState} mode={examData.Type==='nauka'?'nauka':'exam'}
                rowQuestion={question} disableCardTitle={true} 
                onNext={navNextQuestion} onPrev={navPrevQuestion} 
                disableNextBtn={iQuestionOrder===examData.QuestionCount}
                disablePrevBtn={iQuestionOrder===1}
                onOptionClick={onOptionClick}
                showFinishBtn={iQuestionOrder===examData.QuestionCount}
                onExamClose={()=>navigate(`/app/egzamin-wynik/${examData?.ID}`)}
                />
}

export default function MyExamPage(){
    let [ loading, setLoading ]                     = useState<string|null>(null);
    let [ error, setError ]                         = useState<string|null>(null);
    let [ question, setQuestion ]                   = useState<recordQuestionV2|null|'access-denied'|'error'>(null);
    let [ questionState, setQuestionState ]         = useState<stateQuestionV2>({showAbout:false,showComments:false,isLoading:null,showAnswer:false,resetAnswer:false});
    let [ examData, setExamData ]                   = useState<RowExamNew|null>(null);
    const [examIndexData,setExamIndexData]          = useState<Array<RowExamIndex>|null>(null);
    let { examID, questionID }                      = useParams();
    let navigate                                    = useNavigate();
    // console.log(questionID);
    useEffect(()=>{
        if (!questionID || questionID===undefined){
            navigate(`/app/egzamin/${examID}/1`);
        }
    });

    useEffect(()=>{
        if (question!==null){
            setQuestion(null);
            setQuestionState({...questionState,showAnswer:false,showComments:false,showAbout:false});
        }
    },[questionID]);

    let iQuestionOrder = parseInt(questionID!);
    if (iQuestionOrder<=0)iQuestionOrder = 1;
    // if (question&&question!=='error'&&question!=='access-denied'){
    //     if (question?.UserExamCurrentQuestionOrder!==iQuestionOrder){
    //         setQuestion(null);
    //         setQuestionState({...questionState,showAnswer:false,showComments:false,showAbout:false});
    //         return <></>
    //     }
    // }

    if (examData===null){
        if (loading===null&&error===null){
            setLoading('examData');
            axios.get(`/egzamin/get-user-exam/${examID}`).then((response) => {
                setExamData(response.data.data);
                setLoading(null);
              }, (error) => {
                setLoading(null);
                setError('Nie udało się pobrać informacji o Twoim egzaminie.');
            });
        }
    
        return <MyExamPagePlaceholder />
    }

    if (examIndexData===null){
        if (loading===null&&error===null){
            setLoading('examIndexData');
            axios.get(`/egzamin/get-exam-index/${examID}`).then((response) => {
                setExamIndexData(response.data.data);
                setLoading(null);
            }, (error) => {
                setLoading(null);
                setError('Nie udało się pobrać indeksu pytań w Twoim egzaminie.');
            });
        }
        return <MyExamPagePlaceholder />
    }

    function updateIndexData(option:string|null,questionID:number){
        if (examIndexData){
            setExamIndexData(examIndexData?.map(function(i){
                if (i.QuestionID===questionID)
                    i.Answer=option;
                return i;
            }));
        }
    }

    return <>
        <Helmet>
            <title>{`Pytanie ${iQuestionOrder}/${examData.QuestionCount} - ${examData.Name} - ${examData.Type}`}</title>
        </Helmet>
        <div className="main-title">
            <h1>{examData.Name} - Pytanie {questionID}</h1>
        </div>
        <MyExamPageDrawQuestion onParentOptionClick={updateIndexData} examData={examData} question={question!} iQuestionOrder={iQuestionOrder} setQuestion={setQuestion} questionState={questionState} setQuestionState={setQuestionState} />
        <hr />
        <MyExamPagePaginator examIndexData={examIndexData} examID={examData.ID} current={iQuestionOrder} rowExam={examData} />
    </>
}