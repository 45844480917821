import { Dispatch, SetStateAction } from "react";
import { Button, Col } from "react-bootstrap";
import { FaBars } from "react-icons/fa6";

export default function ExamPageIndexMainTitle({title,setShowNav}:{title:string,setShowNav:Dispatch<SetStateAction<boolean>>}){
    return <>
        <Col xs={12}>
            <div className="main-title d-flex">
                <div className="d-block d-lg-none mb-1 me-2">
                    <Button onClick={()=>setShowNav(true)} variant="outline-primary" size="sm"><FaBars /></Button>
                </div>
                <h1>{title}</h1>
            </div>
        </Col>
    </>
}