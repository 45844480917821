import { Dispatch, SetStateAction, useContext } from "react";
import { Nav, Offcanvas } from "react-bootstrap";
import { MdFileOpen } from "react-icons/md";
import { Link } from "react-router-dom";
import GlobalContext from "../Componets/GlobalContext";


function BrowseNav({activeTab,show,setShow}:{activeTab:string,show:boolean,setShow:Dispatch<SetStateAction<boolean>>}){
    const {globalState} = useContext(GlobalContext);

    let userAccess = globalState.user&&!globalState.user.HasAccess;
    // console.log(globalState);

    function hideNav(){
        setShow(false);
    }

    return <>
        <Offcanvas responsive="lg" show={show} onHide={hideNav}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Przeglądaj</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Nav className="flex-column app-sidenav">
                    <Nav.Item>
                        <Nav.Link as={Link} to="/app/przegladaj/sesje" active={activeTab==='ses'} onClick={hideNav}>
                            <MdFileOpen /> Sesje
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={Link} to="/app/przegladaj/kategorie" active={activeTab==='cat'} onClick={hideNav}>
                            <MdFileOpen /> Kategorie
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={Link} to="/app/przegladaj/tagi" active={activeTab==='tag'} onClick={hideNav}>
                            <MdFileOpen /> Tagi
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={Link} to="/app/przegladaj/zrodla" active={activeTab==='sur'} onClick={hideNav}>
                            <MdFileOpen /> Źródła
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
            </Offcanvas.Body>
        </Offcanvas>
    </>
}

export default BrowseNav;