import { useContext, useState } from 'react';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { useCookies } from 'react-cookie';
import GlobalContext from '../Componets/GlobalContext';
import { Helmet } from 'react-helmet-async';
import ProductIndex from '../Product/Index';
import DrawSpinner from '../Componets/DrawSpinner';
import { FaCrown } from 'react-icons/fa6';
import DrawHowItWorks from '../ComponentsV2/DrawHowItWorks';

// interface IndexLoaderData {
//   naukaList: RowExamNew[],
//   egzaminList: RowExamNew[],
//   closed: RowExamResult[]|null
// }

export interface QuestionCommentItem{
  CommentID:number;
  QuestionID:number;
  UserID:number;
  UserName:string;
  Content:string;
  Created:EpochTimeStamp,
  SpecID:number;
  SpecName:string;
  ExamSessionYear:number|null;
  ExamSessionSeason:number|null;
  ExamSessionSeasonName:string|null;
  ExamOrder:number|null;
  LibraryID:number|null;
  LibraryName:string|null;
}

interface stateIndexPage{
  comments:QuestionCommentItem[]|null;
  loadMore:boolean;
  lastID:number|null;
  isMore:boolean;
}



function QuestionCommentItemDraw({comment}:
  {comment:QuestionCommentItem})
{
  let navigate = useNavigate();
  let questionTitle = null;

  function gotoQuestion(){
    navigate(`/app/pytanie/${comment.QuestionID}/dyskusja`,{state:{back:'/app'}});
  }

  if (comment.LibraryID){
    questionTitle = <>
            <a onClick={gotoQuestion} className="link-primary comment-question">Autorskie <i>{comment.SpecName}</i> : {comment.LibraryName}</a>
        </>
  }
  if (comment.ExamSessionYear){
    questionTitle = <>
            <a onClick={gotoQuestion} className="link-primary comment-question">PES <i>{comment.SpecName}</i> : {comment.ExamSessionYear}/{comment.ExamSessionSeasonName}/pytanie {comment.ExamOrder}</a>
        </>
  }

  let commentClass = 'mb-2 comment-item p-2';
  if (comment.UserID===2){
    commentClass += ' bg-warning-subtle';
  }

  return <>
      <div className={commentClass}>
          <div className="d-flex align-items-center mb-1">
              <div className=" flex-grow-1">{comment.UserID===2?<FaCrown className="me-1" />:null}<b>{comment.UserName}</b> - <small>{comment.Created}</small><br />{questionTitle}</div>
          </div>
          <div className="comment-content" dangerouslySetInnerHTML={{__html:comment.Content}} />
          <div className="d-grid">
            <Button size="sm" className="my-1 py-0" onClick={gotoQuestion}>przejdź do dyskusji</Button>
          </div>
      </div>
  </>
}

export default function IndexPage(){
  const {globalState,globalDispatch} = useContext(GlobalContext);
  let [state,setState]               = useState<stateIndexPage>({comments:null,loadMore:false,lastID:null,isMore:false});
  const [cookie, setCookie, removeCookie] = useCookies(['discordAlert']);
  let navigate = useNavigate();

  let userAccess = globalState.user&&!globalState.user.HasAccess;
// console.log(userAccess);
  if (userAccess){
    if (!globalState.user)
      return <ProductIndex />

    if (globalState.user.HasAccess===false){
      return <ProductIndex />
    }
  }

  if (!globalState.user?.HasAccess){
    return <>
      <Helmet>
        <title>ZdajLEKko.pl</title>
    </Helmet>
    <Row className="mt-3 mb-5">
        <Col xs={12}>
                <div className="main-title">
                    <h1 className="text-primary">Nie masz dostępu do aplikacji, w celu zakupu skontaktuj się z nami kontakt@zdajlekko.pl</h1>
                </div>
        </Col>
      </Row>
    </>
  }

  if (state.comments===null||state.loadMore===true){
    axios.post('/pytanie/comments',{id:state.lastID}).then((r) => {
      const newList = (state.comments??[]).concat(r.data.data);
      let lastID=null;
      if (newList.length>0)
        lastID  = newList[newList.length-1].CommentID;
      let newState = {
        ...state,
        loadMore:false,
        comments:newList,
        lastID:lastID,
        isMore:r.data.IsMore?true:false
      }
      setState(newState);
    }, (error) => {
    });
  }

  // console.log(state);

    return <>
    <Helmet>
        <title>ZdajLEKko.pl</title>
    </Helmet>
    <Row className="mt-3 mb-5">
      <Col xs={12} className="comments-latest" lg={3} md={4}>
        <h1 className="my-3 text-primary">Ostatnie komnetarze</h1>
      {state.comments===null ?
        <DrawSpinner text="Pobieranie ostatnich komentarzy..." /> 
        : 
        state.comments.length > 0 ?
        <>
          {state.comments.map(function(i){
            return <QuestionCommentItemDraw key={`comment-item-${i.CommentID}`} comment={i} />
          })}
          {state.isMore && state.lastID !== null ?
            <Button onClick={()=>setState({...state,loadMore:true})}>Pobierz więcej...</Button>
            : null
          }
        </>
        :
          <Alert variant='info'>Brak komentarzy do wyświetlenia.</Alert>
      }
      </Col>
      <Col xs={12} className="howTo" lg={4} md={4}>
        <h1 className="my-3 text-primary">Jak to działa?</h1>
        <DrawHowItWorks />
      </Col>
    </Row>
    </>;
}