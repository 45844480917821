import { useParams } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import QuestionLoadingPlaceholder from "../Componets/QuestionLoadingPlaceholder";
import { Helmet } from "react-helmet-async";
import { RecordQuestionDescription } from "../Type/RecordQuestionDescription";
import { QuestionV2, stateQuestionV2 } from "../Componets/QuestionV2/Question";
import { recordQuestionV2 } from "../Componets/QuestionV2/Record";

interface QuestionPreviewState{
    question:recordQuestionV2|null|'error'|'access-denied';
    about:RecordQuestionDescription|null;
    showQuestionAbout:boolean;
    showAnswer:boolean;
};

// interface QuestionPreviewNavParams {
//     isMore:boolean;
//     onClickShowMoreAboutQuestion:(mode:boolean)=>void;
//     showDescription:boolean;
//     isLoadingDescription:boolean;
//     setShowComments:Dispatch<SetStateAction<boolean>>;
//     showAnswer:boolean;
//     commentsCount:number|null;
//     onClickShowCorrect:(mode:boolean)=>void;
// }

// function QuestionPreviewNav({isMore,onClickShowMoreAboutQuestion,showDescription,isLoadingDescription,setShowComments,showAnswer,onClickShowCorrect,commentsCount}:QuestionPreviewNavParams){
//     let navigate = useNavigate();
//     let {state}  = useLocation();

//     let backItem = <></>
//     // console.log(state);
//     if (state){
//         if ("back" in state!){
//             backItem = <Nav.Item><Nav.Link onClick={()=>navigate(state.back)}><MdArrowBack /> powrót</Nav.Link></Nav.Item>
//         }
//     }

//     return <>
//         <Nav fill variant="underline" className="mt-3">
//             {backItem}
//             <Nav.Item key="showcorrect">
//                 <Nav.Link active={showAnswer} onClick={()=>onClickShowCorrect(!showAnswer)}>
//                     {!showAnswer?'Pokaż odpowiedź':'Ukryj odpowiedź'}
//                 </Nav.Link>
//             </Nav.Item>
//             {isMore === true ? 
//             <Nav.Item key="navmore">
//                 <Nav.Link className="position-relative" disabled={isLoadingDescription} active={showDescription} title="Pokaź omówienie do tego pytania" 
//                         onClick={()=>onClickShowMoreAboutQuestion(!showDescription)}>
//                             <DrawSpinner show={isLoadingDescription} />
//                             <MdQuestionMark /> <span className="d-none d-md-inline">Pokaż omówienie</span><span className="d-sm-inline d-md-none">Omówienie</span>
//                 </Nav.Link>
//             </Nav.Item> : null }
//             <Nav.Item key="navComments">
//                 <Nav.Link onClick={()=>setShowComments(true)}>
//                     <span className="position-relative">Dyskusja&nbsp;&nbsp;
//                     {commentsCount! > 0 ? <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">{commentsCount}</span> : null}
//                     </span>
//                 </Nav.Link>
//             </Nav.Item>
//         </Nav>
//     </>
// }

function QuestionPreview({showNowComments,QuestionID,showProposalBtn,showProposal}:{showNowComments:boolean,QuestionID:number|null,showProposalBtn?:boolean,showProposal?:boolean}){
    let { questionID }                      = useParams();
    let [ state, setState ]                 = useState<QuestionPreviewState>({question:null,about:null,showQuestionAbout:false,showAnswer:false});
    let [ questionState, setQuestionState ] = useState<stateQuestionV2>({showAbout:false,showComments:showNowComments,isLoading:null,showAnswer:false,resetAnswer:false});
    // let isLoadingAbout            = useRef<boolean>(false);
    let question                  = state.question;
    // let about                     = state.about;
    // let showQuestionAbout         = state.showQuestionAbout;

    if (questionID===undefined||questionID===null||questionID.length<=0){
        questionID=QuestionID+'';
    }

    if (question===null){
        axios.post('/v2/pytanie/get',{id:questionID}).then((r) => {
            if (r.data.access==='Brak dostępu.')
                setState({...state,question:'access-denied'});
            else
                setState({...state,question:r.data.data});
          }, (error) => {
            // console.log(error);
        });
        return <QuestionLoadingPlaceholder />
    }

    return <>
    <Helmet>
        <title>Podgląd pytania</title>
    </Helmet>
        <QuestionV2 rowQuestion={question} state={questionState} setState={setQuestionState} disableCardTitle={true} showAnswerButton={true} />
    </>
}

export default QuestionPreview;