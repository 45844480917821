function ExamCardFooter({score,time}:{score:string,time:string}){
    if (score){
        let fscore  = parseFloat(score);
        let xtime   = new Date(time);
        if (fscore<80.0){
            return <div className="py-1 card-footer text-body-secondary bg-danger-subtle">Ostatni wynik <strong>{score}%</strong> z {xtime.toLocaleDateString()}.</div>
        }
        return <div className="py-1 card-footer text-body-secondary bg-success-subtle">Ostatni wynik <strong>{score}%</strong> z {xtime.toLocaleDateString()}.</div>
    }
    return <></>
}

export default ExamCardFooter;