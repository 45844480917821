import { Helmet } from "react-helmet-async";

function ExamLEKPageCEM(){
    return <>
        <Helmet>
            <title>Baza pytań LEK - ZdajLEKko</title>
        </Helmet>
    </>
}

export default ExamLEKPageCEM;