import { Helmet } from "react-helmet-async";
import { RowExamResult } from "../Type/RowExamResult";
import { Dispatch, MutableRefObject, SetStateAction, useRef, useState } from "react";
import axios from "axios";
import { Button, Modal, ProgressBar, Table, Form, InputGroup } from "react-bootstrap";
import { MdCheckCircle, MdPreview, MdRemove } from "react-icons/md";
import { FaCheck, FaPen, FaTrashCan } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import DrawSpinner from "../Componets/DrawSpinner";

interface stateExamCreatePageHistory{
    list:RowExamResult[]|null;
    examCreate:boolean;
    questionCount:number|null;
    preremove:number|null;
    remove:number|null;
    selected:number[];
    tryAgain:number|null;
    try:boolean;
    saving:boolean;
}

function ExamCreatePageHistoryPreRemove({state,setState}:{state:stateExamCreatePageHistory,setState:Dispatch<SetStateAction<stateExamCreatePageHistory>>}){
    if (state.preremove===null) return <></>
    let record = state.list?.find(i=>i.ID===state.preremove);
    if (!record) return <></>

    return <>
    <Modal backdrop="static" centered show={state.preremove!==null} onHide={()=>setState({...state,preremove:null})}>
        <Modal.Header closeButton>
            <Modal.Title>Usuwanie</Modal.Title>
        </Modal.Header>
            <Modal.Body>
                    Czy na pewno chcesz usunać <b>{record.Name}</b>?
                    {/* <ProgressBar className="d-flex my-2">
                        <ProgressBar variant="success" now={((record.CorrectAnswerCount * 100.0) / record.QuestionCount)} label={`${((record.CorrectAnswerCount * 100.0) / record.QuestionCount).toFixed(2)}%`} key={1} />
                        <ProgressBar variant="danger" now={(((record.AnswerCount - record.CorrectAnswerCount) * 100.0) / record.QuestionCount)} key={2} />
                    </ProgressBar> */}
            </Modal.Body>
        <Modal.Footer>
            <Button variant="danger" onClick={()=>setState({...state,preremove:null,remove:state.preremove})}>
                Tak
            </Button>
            <Button variant="secondary" onClick={()=>setState({...state,preremove:null})}>
                Nie
            </Button>
        </Modal.Footer>
    </Modal>
    </>
}

interface stateDrawHistoryEditableName{
    editID:number|null;
    saveName:boolean;
}


function DrawHistoryEditableName({record,state,setState}:
    {   record:RowExamResult,state:stateExamCreatePageHistory,setState:Dispatch<SetStateAction<stateExamCreatePageHistory>>
    })
{
    let [editState,setEditState]  = useState<stateDrawHistoryEditableName>({editID:null,saveName:false});
    let inputName                 = useRef<HTMLInputElement|null>(null);
    if (!record.Name)record.Name='Brak nazwy';

    if (editState.saveName){
        let newName = inputName.current?inputName.current.value:null;
        axios.post('/egzamin/save-name',
                {id:editState.editID,
                 name:newName}).
            then((r)=>{
                let newState = {...state};
                let item = state.list?.findIndex(x=>x.ID===editState.editID);
                if (item){
                    let newList = state.list;
                    if(newList){
                        newList[item].Name = newName;
                        newState.list = newList;
                    }
                }
                // console.log(newState.list.fin)
                setState(newState);
                setEditState({editID:null,saveName:false});
            }).catch((e)=>{
                setState({...state});
                setEditState({editID:null,saveName:false});
            })
    }

    if (editState.editID===record.ID){
        return <>
            <InputGroup size="sm">
                <Form.Control disabled={editState.saveName} size="sm" ref={inputName} defaultValue={record.Name} maxLength={40} minLength={1} />
                <Button disabled={editState.saveName} onClick={()=>setEditState({...editState,saveName:true})}>
                    <DrawSpinner show={editState.saveName} size="sm" />
                    <FaCheck /><span className="d-none d-md-inline"> Zapisz</span>
                </Button>
            </InputGroup>   
        </>
    }

    return <>
    {record.Name.length>32?
        <>
            <span className="d-inline d-md-none">{record.Name?.substring(0,32)}...</span>
            <span className="d-none d-md-inline">{record.Name}</span>
        </>
        :
        record.Name}
        <Button size="sm" variant="secoundary" className="ms-2" onClick={()=>setEditState({editID:record.ID,saveName:false})}><FaPen /></Button>
    </>    
}

function ExamPageHistory() {
    let [state,setState] = useState<stateExamCreatePageHistory>({list:null,preremove:null,remove:null,selected:[],examCreate:false,questionCount:null,tryAgain:null,saving:false,try:false});
    let isLoading        = useRef<boolean>(false);
    let isCreating       = useRef<boolean>(false);
    let navigate         = useNavigate();
    let inputName        = useRef<HTMLInputElement|null>(null);
    let inputType        = useRef<HTMLSelectElement|null>(null);

    if (state.list===null){
        axios({url:'/egzamin/history'}).then((r)=>{
            setState({...state,list:r.data.data});
        }).catch((e)=>{

        });
        return <><DrawSpinner text="Pobieranie historii egzaminów..." /></>
    }
    if (state.preremove===null && state.remove!==null&&isLoading.current===false){
        isLoading.current=true;
        axios.post('/egzamin/remove-history',{id:state.remove}).then((r)=>{
            isLoading.current=false;
            let copy = state.list?.filter(i=>i.ID!==state.remove);
            setState({...state,list:copy?copy:null,remove:null});
        }).catch((e)=>{
            isLoading.current=false;
            setState({...state,remove:null});
        });
    }

    if (state.examCreate===true&&isCreating.current===false){
        isCreating.current=true;
        let selectedType = inputType.current?inputType.current.options[inputType.current.selectedIndex].value:'nauka';
        axios.post('/egzamin/create-from-history',{
                ids:state.selected,
                name:inputName.current?inputName.current.value:null,
                type:selectedType
        }).then((r)=>{
            navigate(`/app/egzamin/${r.data.data.ID}/1`);
        }).catch((e)=>{
            isCreating.current=false;
            setState({...state,examCreate:false});
        });
    }

    if (state.questionCount===null&&state.selected.length>0&&isLoading.current===false){
        isLoading.current=true;
        axios.post('/egzamin/question-count',{
            ids:state.selected
        }).then((r)=>{
            isLoading.current=false;
            // if(r.data.data===0)r.data.data=null;
            setState({...state,questionCount:r.data.data});
        }).catch((e)=>{
            isLoading.current=false;
        });
    }

    if (state.try===true && state.tryAgain!==null &&state.saving===false){
        setState({...state,saving:true});
        axios.post('/egzamin/try-again',{
            id:state.tryAgain
        }).then((r)=>{
            setState({...state,try:false,tryAgain:null,saving:false});
            navigate(`/app/egzamin/${r.data.data.ID}/1`);
        }).catch((e)=>{});
    }

    // console.log(state.questionCount);

    const disabledForm = state.examCreate || 
                         state.selected.length===0 || 
                         (state.questionCount!==undefined&&state.questionCount!==null?(state.questionCount>360||state.questionCount===0):false);

    let tryRow = state.list.find(x=>x.ID===state.tryAgain);

    return <>
        <Helmet>
            <title>Historia egzaminów</title>
        </Helmet>
        <Table striped bordered hover responsive size="sm" className="align-middle mb-3">
            <thead>
                <tr>
                    <td className="text-center">
                        <Form.Check type="checkbox"
                            checked={state.selected.length===state.list.length} 
                            onChange={()=>{
                                state.selected.length===state.list?.length?
                                        setState({...state,selected:[],questionCount:null})
                                        :
                                        setState({...state,selected:state.list?state.list.map(x=>x.ID):[],questionCount:null})}
                                    }
                            />
                    </td>
                    <th>Nazwa</th>
                    <th>Tryb</th>
                    <th>Wynik</th>
                    <th></th>
                </tr>
            </thead>
            <tbody className="table-group-divider">
                {state.list.map(function(i,idx){
                    if (!i.Name){
                        i.Name='Brak nazwy';
                    }
                    let isChecked = state.selected.some(f=>f===i.ID);
                    return <tr key={`historyEntry-${idx}`}>
                        <td className="text-center">
                            <Form.Check type="checkbox" checked={isChecked}
                                    onChange={() => {isChecked?
                                            setState({...state,selected:state.selected.filter(f=>f!==i.ID),questionCount:null})
                                            :
                                            setState({...state,selected:[...state.selected,i.ID],questionCount:null})
                                    }}
                            />
                        </td>
                        <td>{i.Name}</td>
                        <td>{i.Type}</td>
                        <td style={{width:'25%'}}>
                            <ProgressBar className="d-flex">
                                <ProgressBar variant="success" now={((i.CorrectAnswerCount * 100.0) / i.ValidQuestionCount)} label={`${((i.CorrectAnswerCount * 100.0) / i.ValidQuestionCount).toFixed(2)}%`} key={1} />
                                <ProgressBar variant="danger" now={(((i.AnswerCount - i.CorrectAnswerCount) * 100.0) / i.ValidQuestionCount)} key={2} />
                            </ProgressBar>
                        </td>
                        <td>
                            <Button size="sm" variant="secoundary" title="Spróbuj ponownie" onClick={()=>setState({...state,tryAgain:i.ID})}><MdCheckCircle /> <span className="d-md-inline d-none">spróbuj ponownie</span></Button>
                            <Button size="sm" variant="secoundary" title="Przeglądaj wynik" onClick={()=>navigate(`/app/egzamin-wynik/${i.ID}`)}><MdPreview /></Button>
                            <Button size="sm" variant="secoundary" title="Usuń egzamin" onClick={()=>setState({...state,preremove:i.ID})}><FaTrashCan /></Button>
                        </td>
                    </tr>
                })}
            </tbody>
            <caption>{state.list.length} egzaminów.</caption>
        </Table>
                <p className="text-secondary">Możesz stworzyć egzamin złożony z pytań na które udzieliłeś błędnych odpowiedzi. Wybierz interesujące Cię egzaminy z historii i skorzystaj z formularza poniżej.</p>
            <InputGroup className="mb-4">
                <Form.Select ref={inputType} disabled={disabledForm} aria-label="Wybierz tryb">
                    <option value="nauka">Tryb nauki</option>
                    <option value="egzamin">Tryb egzaminu</option>
                </Form.Select>
                <Form.Control maxLength={40} ref={inputName} disabled={disabledForm} type="text" placeholder="Nazwa..." aria-label="Wprowadź nazwę dla egzaminu..." />
                <Button disabled={disabledForm} 
                        onClick={()=>setState({...state,examCreate:true})}>
                    <DrawSpinner show={state.examCreate} size="sm" />
                    Rozpocznij {state.questionCount?<>({state.questionCount} pytań)</>:null}
                </Button>
            </InputGroup>
        <ExamCreatePageHistoryPreRemove state={state} setState={setState} />
        <Modal show={state.tryAgain!==null} onHide={()=>setState({...state,tryAgain:null,try:false})}>
            <Modal.Header closeButton>
                <Modal.Title>Chcesz spróbować jeszcze raz?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Czy chcesz spróbować rozwiązać jeszcze raz pytania z egzaminu <b>{tryRow?.Name}</b>?</p>
                <p>Pytania ({tryRow?.QuestionCount}) będą te same, zmieni się tylko ich kolejność w nowo utworzonym podejściu.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={state.try} variant="secondary" onClick={()=>setState({...state,try:false,tryAgain:null})}>Nie</Button>
                <Button disabled={state.try} variant="primary" onClick={()=>setState({...state,try:true})}>
                    <DrawSpinner show={state.try} />
                    Tak
                </Button>
            </Modal.Footer>
        </Modal>
        </>
}

export default ExamPageHistory;