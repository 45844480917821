import React, { useEffect } from 'react';
import './App.scss';
//import { useState, createContext, useContext, useReducer } from "react";
import { useReducer, lazy } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { GlobalContext, GlobalReducer, GlobalReducerInitialState } from './Componets/GlobalContext';
import { AppNavBar } from './Componets/NavBar';
import IndexPage from './Pages/Index';
import { HelmetProvider } from 'react-helmet-async';
import UserAccountPage from './Pages/UserAccountPage';
import MyExamPage from './Pages/MyExamPage';
import MyExamResultPage from './Pages/MyExamResultPage';
import MyExamResultPreviewPage from './Pages/MyExamResultPreviewPage';
import MyAppError from './Pages/MyAppError';
// import { RecordGetLastExams } from './Type/RecordGetLastExams';
// import axios from 'axios';
import ExamPageIndex from './Exam/Index';
import QuestionPreview from './Question/Preview';
import ProductPaymentDone from './Product/ProductPaymentDone';
import ProductIndex from './Product/Index';
import ExamLEKPageIndex from './ExamLEK/Index';
import BrowseIndex from './Browse/Browse';
import UserLibraryIndexPage from './UserLibrary/Index';
import axios from 'axios';

const baseRoute = createBrowserRouter([
  {
    // feature:{{v7_relativeSplatPath:true}},
    path: "/",
    // loader : async () => { 
    //   const result = await fetch('/uzytkownik').then((response) => { 
    //       return response.json().then((data) => {
    //         return data.identity as UserIdentity;
    //    })});
    //   if (result.ID===null){
    //     // window.location.href='/';
    //   }
    //   return result;
    // },
    // errorElement: <MyAppError />,
    ErrorBoundary: MyAppError,
    element: <AppNavBar />,
    children: [
        { path : 'app', element : <IndexPage /> },
        { path : 'app/oferta', element : <ProductIndex /> },
        { path : 'app/moje-konto', element : <UserAccountPage /> },
        { path : 'app/pytanie/:questionID', element : <QuestionPreview QuestionID={null} showNowComments={false} /> },
        { path : 'app/pytanie/:questionID/dyskusja', element : <QuestionPreview QuestionID={null} showNowComments={true} /> },
        { path : 'app/pytanie/:questionID/propozycja', element : <QuestionPreview QuestionID={null} showNowComments={false} showProposal={true} /> },
        { path : 'app/egzaminy', element: <ExamPageIndex activeTab="otwarte" /> },
        { path : 'app/egzaminy/cem', element: <ExamPageIndex activeTab="cem" /> },
        { path : 'app/egzaminy/generator', element: <ExamPageIndex activeTab="generator" /> },
        { path : 'app/egzaminy/generator-v2', element: <ExamPageIndex activeTab="generatorv2" /> },
        { path : 'app/egzaminy/probny-egzamin', element: <ExamPageIndex activeTab="egzamin-probny" /> },
        { path : 'app/egzaminy/pytania-autorskie', element: <ExamPageIndex activeTab="autorskie" /> },
        { path : 'app/egzaminy/ulubione-pytania', element: <ExamPageIndex activeTab="ulubione" /> },
        { path : 'app/egzaminy/pytania-ktorych-nie-widziales', element: <ExamPageIndex activeTab="not-seen" /> },
        { path : 'app/egzaminy/historia', element: <ExamPageIndex activeTab="historia" /> },
        { path : 'app/przegladaj', element: <BrowseIndex page="index" /> },
        { path : 'app/przegladaj/sesje/:SpecID?/:ID?/:QuestionID?', element: <BrowseIndex page="ses" /> },
        { path : 'app/przegladaj/kategorie/:SpecID?/:ID?/:QuestionID?', element: <BrowseIndex page="cat" /> },
        { path : 'app/przegladaj/zrodla/:SpecID?/:ID?/:QuestionID?', element: <BrowseIndex page="sur" /> },
        { path : 'app/przegladaj/tagi/:SpecID?/:ID?/:QuestionID?', element: <BrowseIndex page="tag" /> },

        
        { path : 'app/egzamin-lek', element: <ExamLEKPageIndex activeTab="otwarte" /> },
        { path : 'app/egzamin-lek/cem', element: <ExamLEKPageIndex activeTab="cem" /> },
        // , children : [
        //   { path: 'generator', element: <ExamCreatePage activeTab="generator" /> },
        //   { path: 'pytania-autorskie', element: <ExamCreatePage activeTab="autorskie" /> },
        // ] },
/*
        { path : 'app/egzaminy', element : <ExamLibraryPage />, loader : async () => { 
            const result = await axios({url:'/specjalizacja/list',maxRedirects:0}).then((response)=>{
              return response.data.data;
            }).catch((err)=>{
              return null;
            });
            return result;
        } },*/
        { path: 'app/egzamin/:examID/:questionID?', element : <MyExamPage /> },
        { path: 'app/egzaminy/pytania-uzytkownikow', element : <ExamPageIndex activeTab='usersLibrary' /> },
        { path: 'app/egzaminy/pytania-uzytkownikow/pytanie/:questionID?', element : <ExamPageIndex activeTab="usersLibraryAdd" /> },
        { path: 'app/platnosc/podsumowanie/:sessionID', element: <ProductPaymentDone /> },
        // { path: 'app/egzamin/generator', element : <ExamGeneratorPage /> },
        { path: 'app/egzamin-przegladaj/:examID/:questionID?', element : <MyExamResultPreviewPage /> },
        { path: 'app/egzamin-wynik/:examID', element : <MyExamResultPage />, loader : async({params}) => {
          return await fetch(`/v2/egzamin/get-user-exam-close/${params.examID}`).then((response)=>{
            return response.json().then((data)=>{return data.data;});
          });
        }},
        // { path: 'app/login', render={() => window.location.replace('http://zdajlekko.laminas.local/')}},
    ]
  }],{future:{
    v7_fetcherPersist:true,
    v7_relativeSplatPath:true,
    v7_normalizeFormMethod:true,
    v7_partialHydration:true,
    v7_skipActionErrorRevalidation:true
  }});

function App() {
  const [ state, dispatch] = useReducer(GlobalReducer,GlobalReducerInitialState);

//   useEffect(() => {
//     const handleContextmenu = (e:any) => {
//         e.preventDefault()
//     }
//     document.addEventListener('contextmenu', handleContextmenu)
//     return function cleanup() {
//         document.removeEventListener('contextmenu', handleContextmenu)
//     }
// }, [ ])

  return (
    <HelmetProvider>
      <GlobalContext.Provider value={{globalState:state,globalDispatch:dispatch}}>
        <RouterProvider router={baseRoute} future={{v7_startTransition:true}} />
          {/* <Routes>
            <Route element={<AppNavBar />}>
              <Route path="/app/" element={<IndexPage />} />
              <Route path="/app/moje-konto" element={<UserAccountPage />} />
              <Route path="/app/egzaminy" element={<ExamPage />} loader={async () => {
                
              }} />
              <Route path="/app/egzamin/:examID?/:questionID" element={<MyExamPage />} loader={async ({ params }) => {
                return fetch(`/exam/get-user-exam/${params.examID}`);
              }}/>
            </Route>
          </Routes> */}
        {/* </BrowserRouter> */}
      </GlobalContext.Provider>
    </HelmetProvider>
  );
}

axios.interceptors.response.use(
  (response) => {
      return response;
  },
  (error) => {
      if (error.response && error.response.status === 401) {
          return Promise.reject('Unauthorized');
      }
      return Promise.reject(error);
  }
);

export default App;
