import { Button, Col, Form, InputGroup, Row, ToggleButton } from "react-bootstrap";
import DrawSpinner from "../Componets/DrawSpinner";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RecordGeneratorSession } from "../Type/RecordGeneratorSession";

interface TemplateCategoryListItem{
    SpecID:number|null,
    CategoryID:number|null,
    CategoryName:string|null,
    QuestionsCount:number|null;
}

interface TemplateSessionCategoryListItem{
    id:number;
    value:number|null;
}

interface TemplateSessionListItem{
    name:string;
    value:TemplateSessionCategoryListItem[]|null;
}

interface ExamCreatePageGeneratorByCategoriesParams{
    sessions:RecordGeneratorSession[]|null,
    useSessions:number[],
    showCategories:boolean,
    templateCategories:TemplateCategoryListItem[]|null;
    templateSessions:TemplateSessionListItem[]|null;
}

interface ExamCreatePageGeneratorByCategoriesCategoryState{
    id:number|null;
    count:number|null;
    max:number|null;
    value:number|null;
}

interface ExamCreatePageGeneratorByCategoriesState{
    freeNumber:number;
    categories:ExamCreatePageGeneratorByCategoriesCategoryState[]|null;
    inCreatingProgress:boolean;
}

export function ExamPageGeneratorCategories(){
    let [state,setState]        = useState<ExamCreatePageGeneratorByCategoriesParams>({sessions:null,useSessions:[],showCategories:false,templateCategories:null,templateSessions:null});
    let [category,setCategory]  = useState<ExamCreatePageGeneratorByCategoriesState>({freeNumber:120,categories:null,inCreatingProgress:false});
    let navigate                = useNavigate();
    let inputName               = useRef<HTMLInputElement|null>(null);
    let inputType               = useRef<HTMLSelectElement|null>(null);
    let isLoading               = useRef<boolean>(false);
    let sesjeKK                 = [45,6,3,42,41,43,44,46,1427];
    const maxQuestions          = 120;

    if ( state.sessions === null && isLoading.current === false ){
            isLoading.current   = true;
            axios.post(`/generator/get-sessions`,{
                    usePES:1,
                    useLIB:1
            }).then((response) => {
                isLoading.current=false;
                setState({...state,sessions:response.data.data});
            }, (error) => {
                isLoading.current=false;
            });
    }

    if (state.showCategories===true && state.templateCategories===null){
        axios.post(`/generator/get-templates`,{
                sessions:state.useSessions
        }).then((response) => {
            isLoading.current=false;
            setState({...state,templateCategories:response.data.data.categories,templateSessions:response.data.data.sessions});
        }, (error) => {
            isLoading.current=false;
        });
    }

    if (category.categories===null && state.templateCategories!==null){
        setCategory({...category,
            categories : state.templateCategories.map(function(i){
                return {id:i.CategoryID,count:i.QuestionsCount as number,value:0,max:i.QuestionsCount as number}
            })
        });
    }

    function sessionOnClick(id:number){
        if (state.useSessions.some(i=>i===id))
            setState({...state,useSessions:state.useSessions.filter(i=>i!==id)});
        else
            setState({...state,useSessions:[...state.useSessions,id]});
    }

    function sessionSetKK(){
        setState({...state,useSessions:sesjeKK});            
    }

    function sessionAllOnClick(){
        setState({...state,useSessions:[]});
    }

    function getCategoryState(id:number|null){
        return category.categories?.find(x=>x.id===id)?.value??0;
    }

    function getCategoryMaxValue(id:number|null){
        let cur = category.categories?.find(x=>x.id===id);
        if (cur){
            let lVal    = (cur.value??0);
            let lCount  = (cur.count??0);
            let freeN   = (category.freeNumber);

            if (freeN>lCount)
                return lCount;

            if (lVal){
                if (lVal+freeN>lCount)
                    return lCount;
                return lVal+freeN;
            }
            
            return freeN;
        }
        return 0;
    }

    function setCategoryValue(id:number|null,value:number|null){
        let newCategories = category.categories?.map(function(i){
            if (i.id===id){
                i.value = value??0;
            }
            return i;
        })??null;
        let used    = (newCategories?.reduce((total,i) => total + (i.value??0) , 0 )??0);
        let newFree = 120-used;
        setCategory({freeNumber:newFree,categories:newCategories,inCreatingProgress:false});
    }

    function onTemplateSelect(e:any) {
        e.preventDefault();
        if (state.templateSessions===null)return;
        let curTemplate = state.templateSessions.find(x=>x.name===e.target.value);
        if (curTemplate){
            let newCategories = category.categories?.map(function(i){
                let cur = curTemplate?.value?.find(x=>x.id===i.id);
                i.value=(cur?.value??0) as number;
                return i;
            });
            let used    = (newCategories?.reduce((total,i) => total + (i.value??0) , 0 )??0);
            let newFree = 120-used;
            setCategory({...category,
                freeNumber:newFree,
                categories : newCategories!
            });
        }else{
            setCategory({...category,
                freeNumber : 120,
                categories : category.categories?.map(function(i){i.value=0;return i;})!
            });
        }
    }

    if (state.sessions===null){
        return <DrawSpinner text="Pobieranie sesji..." />
    }
    if (state.showCategories===false){
        return <>
            <Helmet>
                <title>Stwórz własny egzamin - Generator po kategoriach losowo</title>
            </Helmet>
                <Form>
                    <Row>
                        <Col xs={12}>
                            {/* <p>Za pomocą tego modułu możesz stworzyć losowy egzamin składający się </p> */}
                            <p>Pytania będą miszane pomiędzy wybranymi sesjami i autorskimi.</p>
                            
                            <div className="mb-5 d-flex flex-wrap">
                            <ToggleButton className="m-1 flex-fill" 
                                            variant={state.useSessions.length===0?'primary':'outline-primary'}
                                            type="checkbox" id="session-all" value={0} 
                                            checked={state.useSessions.length===0}
                                            onClick={()=>sessionAllOnClick()}>
                                            Wszystkie sesje</ToggleButton>
                            <ToggleButton className="m-1 flex-fill" 
                                            variant='outline-primary'
                                            type="checkbox" id="session-all" value={0} 
                                            onClick={()=>sessionSetKK()}>
                                            Sesje aktualna KK</ToggleButton>
                            {state.sessions.map(function(c,idx){
                                // let val=c.ID;
                                let isCheck = state.useSessions?.some(i=>i===c.ID);
                                return  <ToggleButton className="m-1 flex-fill" key={`sessionBtn-${c.ID}`} 
                                            variant={isCheck?'primary':'outline-primary'}
                                            type="checkbox" id={`session-${c.ID}`} value={c.ID} 
                                            checked={isCheck}
                                            onClick={()=>sessionOnClick(c.ID)}>
                                            {c.Year} - {c.Season} <span>({c.Total})</span></ToggleButton>
                                
                            })}
                            </div>
                        </Col>
                        <Col xs={12}>
                            <div className="d-grid gap-2 mb-4">
                                <Button variant="primary" onClick={()=>setState({...state,showCategories:true})} size="lg">
                                    Dobierz kategorie
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
        </>
    }
    else{

        if (category.inCreatingProgress){
            if (isLoading.current===false){
                isLoading.current   = true;
                let selectedType = inputType.current?inputType.current.options[inputType.current.selectedIndex].value:'nauka';
                  axios.post(`/generator/create-by-category`,{
                      useSessions:state.useSessions,
                      useCategories:category.categories,
                      type:selectedType,
                      name:inputName.current?inputName.current.value:null,
                  }).then((response) => {
                      isLoading.current=false;
                      navigate(`/app/egzamin/${response.data.data.ID}/1`);
                    //   setParams({...params,precreateCount:response.data.data});
                  }, (error) => {
                      isLoading.current=false;
                  });
            }
        }
// console.log(state.templateSessions);
        return <>
            <Helmet>
                <title>Stwórz własny egzamin - Generator po kategoriach losowo</title>
            </Helmet>
                <Form className="mb-4">
                    <Row>
                        <Col xs={12}>
                            <hr />
                            <p>Wybierz z jakich kategorii ma składać się egzamin i ile ma być pytań z danej kategorii. Egzamin generowany składa się z maksymalnie 120 pytań.</p>
                            <p>Przy losowaniu pytań brane są pod uwagę tylko pytania, które nie zostały oznaczone jako "niezgodne z aktualną wiedzą medyczną".</p>
                        </Col>
                        <Col xs={12}>
                            {state.templateSessions ? 
                            <Form.Group as={Row} className="mb-3" controlId="formSpec">
                                <Form.Label column sm={2}>
                                    Skorzystaj ze wzoru
                                </Form.Label>
                                <Col sm={10}>
                                    <Form.Select aria-label="Wybierz wzór na podstawie egzaminów CEM..." onChange={onTemplateSelect}>
                                        <option value="0">Wybierz wzór...</option>
                                        {state.templateSessions.map(function(d){
                                            return <option value={d.name} key={d.name}>{d.name}</option>
                                        })}
                                    </Form.Select>
                                </Col>
                            </Form.Group>
                            : null }
                        </Col>
                        {state.templateCategories === null ? <DrawSpinner text="Pobieranie kategorii...." /> : 
                            state.templateCategories.map(function(i,idx){
                                let curMax      = getCategoryMaxValue(i.CategoryID);
                                let curState    = getCategoryState(i.CategoryID);
                                return <Col xs={12} key={`category-range-${idx}`} className="mb-2">
                                      <Form.Label>{i.CategoryName} <span className="text-primary"><strong>{curState}</strong>/{i.QuestionsCount}</span></Form.Label>
                                      <Form.Range min={0} disabled={curMax>0?false:true} value={curState} max={curMax} onChange={(v)=>setCategoryValue(i.CategoryID,v.target.valueAsNumber)} />
                                </Col>
                            })
                        }
                        <Col xs={12}>
                            <InputGroup>
                                <Form.Select ref={inputType} disabled={category.inCreatingProgress} aria-label="Wybierz tryp" defaultValue="egzamin">
                                    <option value="nauka">Tryb nauki</option>
                                    <option value="egzamin">Tryb egzaminu</option>
                                </Form.Select>
                                <Form.Control maxLength={40} ref={inputName} disabled={category.inCreatingProgress} type="text" placeholder="Nazwa..." aria-label="Wprowadź nazwę dla egzaminu..." />
                                <Button disabled={category.inCreatingProgress || category.freeNumber!==0} 
                                    onClick={()=>setCategory({...category,inCreatingProgress:true})}>
                                    <DrawSpinner show={category.inCreatingProgress} size="sm" />
                                    Rozpocznij ({120-category.freeNumber} pytań)
                                </Button>
                            </InputGroup>
                        </Col>
                    </Row>
                </Form>
            
        </>
    }
}

export default ExamPageGeneratorCategories;