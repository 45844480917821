import { Nav } from "react-bootstrap";
import { stateQuestionV2 } from "../Question";
import { MdQuestionMark } from "react-icons/md";
import { recordQuestionV2, recordQuestionV2Description } from "../Record";
import { Dispatch, SetStateAction } from "react";
import DrawSpinner from "../../DrawSpinner";
import { FaAngleLeft, FaAngleRight, FaFlagCheckered } from "react-icons/fa6";
// import { Link } from "react-router-dom";

export function QuestionV2ElementTitleButtons({disableCommentsBtn,mode,rowQuestion,state,setState,showAnswerButton,onNext,onPrev,disableNextBtn,disablePrevBtn,showFinishBtn,onExamClose}:
    {rowQuestion:recordQuestionV2,rowAbout:recordQuestionV2Description|null,state:stateQuestionV2,setState:Dispatch<SetStateAction<stateQuestionV2>>
                onOptionClick:(answer:string,questionID:number) => void,
                mode?:'exam'|'nauka'|'result'|'userQuestion',
                showAnswerButton?:boolean,
                onExamClose?:()=>void,
                onNext?:()=>void,
    disableNextBtn?:boolean,
    onPrev?:()=>void,
    disablePrevBtn?:boolean,
    showFinishBtn?:boolean,
    disableCommentsBtn?:boolean
            })
{
    // let questionID = rowQuestion.ID;
    let isMore     = rowQuestion.IsAboutContent ? true : false;
    let prevQuestionLink = null;
    let nextQuestionLink = null;

    if (mode==='userQuestion') 
        return <></>

    if (onPrev)
        prevQuestionLink = <Nav.Item><Nav.Link disabled={disablePrevBtn===true}  onClick={onPrev}><FaAngleLeft /><span className="d-none d-md-inline"> Poprzednie pytanie</span></Nav.Link></Nav.Item>
    
    if (onNext)
        nextQuestionLink = <Nav.Item><Nav.Link disabled={disableNextBtn===true} onClick={onNext}><span className="d-none d-md-inline">Kolejne pytanie</span> <FaAngleRight className="fw-bold" /></Nav.Link></Nav.Item>

    return <>
        <Nav fill variant="underline" className="mt-3">
            {prevQuestionLink}
            {showAnswerButton !== undefined && rowQuestion.CorrectAnswer != null ?
            <Nav.Item>
                <Nav.Link active={state.showAnswer} title="Pokaż odpowiedź do tego pytania" onClick={() => setState({...state,showAnswer:!state.showAnswer})}>
                        <MdQuestionMark /> <span className="d-none d-md-inline">Pokaż odpowiedź</span><span className="d-sm-inline d-md-none">Odpowiedź</span>
                </Nav.Link>
            </Nav.Item> : null }
            {isMore === true ? 
            <Nav.Item>
                {/* (state.Type==='egzamin' && rowQuestion.Answer===null) */}
                <Nav.Link className="position-relative" disabled={(rowQuestion.UserExamType==='egzamin' && rowQuestion.UserExamAnswer === null && rowQuestion.CorrectAnswer!=='X') || (state.isLoading!==null)} 
                    active={state.showAbout}
                    title="Pokaź omówienie do tego pytania" onClick={() => setState({...state,showAbout:!state.showAbout})}>
                        <MdQuestionMark /> <span className="d-none d-md-inline">Pokaż omówienie</span><span className="d-sm-inline d-md-none">Omówienie</span>
                        <DrawSpinner show={state.isLoading==='description'} className="ms-1" />
                </Nav.Link>
            </Nav.Item> : null }

            {disableCommentsBtn !== true ?
            <Nav.Item>
                <Nav.Link disabled={rowQuestion.UserExamType==='egzamin' && rowQuestion.UserExamAnswer === null && rowQuestion.CorrectAnswer!=='X'} onClick={()=>setState({...state,showComments:!state.showComments})} title="Pokaż komentarze do tego pytania" active={state.showComments}>
                    <span className="position-relative">Dyskusja&nbsp;&nbsp;
                        {rowQuestion.CommentsCount! > 0 ? <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">{rowQuestion.CommentsCount}</span> : null}
                    </span>
                </Nav.Link>
            </Nav.Item>: null }
            {nextQuestionLink}
            {showFinishBtn&&onExamClose?
            <Nav.Item>
                <Nav.Link title="Zakończ i pokaż podsumowanie" onClick={onExamClose}>
                    <span className="d-none d-md-inline">Zakończ</span> <FaFlagCheckered className="fw-bold" />
                </Nav.Link>
            </Nav.Item>:null}
        </Nav>
    </>
}