import { Dispatch, SetStateAction, useContext, useState } from "react";
import GlobalContext from "../../GlobalContext";
import { propsQuestion, stateQuestionV2 } from "../Question";
import { MdEdit, MdFavorite, MdHeartBroken } from "react-icons/md";
import { Button, ButtonGroup, CardTitle, Dropdown, Modal } from "react-bootstrap";
import { recordQuestionV2 } from "../Record";
import axios from "axios";
import DrawSpinner from "../../DrawSpinner";
import { useNavigate } from "react-router-dom";

export function QuestionV2ElementTitleText({question}:{question:recordQuestionV2}){
    let qSubTitle = null;
    if (question.LibraryName!==null)
        qSubTitle = <>
            Autorskie <i>{question.SpecName}</i> : {question.LibraryName}
        </>
    if (question.SessionYear!==null)
        qSubTitle = <>
            PES <i>{question.SpecName!}</i> : {question.SessionYear!}/{question.SessionSeasonName!}/pytanie {question.Order!}
        </>
    if (question.UserExamCurrentQuestionOrder===null)
        return <><small className="align-self-lg-center">{qSubTitle}</small></>

    return <>
        {/* <h2 className="position-relative mb-2 mb-lg-0">
            Pytanie {question.UserExamCurrentQuestionOrder===null?question.Order:question.UserExamCurrentQuestionOrder} */}
            <small className="question-source align-self-lg-center">
                {qSubTitle}
            </small>
        {/* </h2> */}
    </>
}

// function QuestionV2ElementTitleButtons({question}:{question:recordQuestionV2}){
//     const {globalState,globalDispatch}              = useContext(GlobalContext);
//     let questionID                                  = question.ID;

//     let buttonAdmin = null;
//     if (globalState){
//         if (globalState.user){
//             if ("Type" in globalState.user){
//                 if (globalState.user.Type==='admin'){
//                     let editHref = `https://zdajlekko.pl/admin/library/edit-question/${questionID}`;
//                     if (question.SessionYear!==null)
//                         editHref = `https://zdajlekko.pl/admin/question/edit/${questionID}`;
//                     buttonAdmin = <Button target="_blank" variant="primary" href={editHref} size="sm"><MdEdit /> Edytuj<span className="d-none d-md-inline">  pytanie</span></Button>
//                 }
//             }
//         }
//     }

//     return <>
//     </>
// }

export interface stateQuestionFavoriteButton{
    saveFavorite:boolean;
    isFavorite:boolean;
    isLoading:boolean;
};

export function QuestionFavoriteButton({QuestionID,FavoriteID}:{QuestionID:number,FavoriteID:number|null}){
    let [state,setState]  = useState<stateQuestionFavoriteButton>({saveFavorite:false,isFavorite:FavoriteID?true:false,isLoading:false});

    if (state.saveFavorite && state.isLoading===false){
        setState({...state,isLoading:true});
        axios.post('/pytanie/favorite',{id:QuestionID}).then((r) => {
            setState({...state,saveFavorite:false,isFavorite:r.data.data,isLoading:false});
          }, (error) => {
        });
    }

    return <>
        <Button disabled={state.isLoading} variant={state.isFavorite?'primary':'outline-primary'} size="sm" onClick={() => setState({...state,saveFavorite:true})}>
            <DrawSpinner size="sm" show={state.isLoading} />
            {state.isFavorite?
                <><MdFavorite /> Lubisz! </>
            :
                <><MdHeartBroken /> Lubisz<span className="d-none d-md-inline"> to pytanie</span>?</>
            }
        </Button>
    </>
}

export function QuestionEdit({question}:{question:recordQuestionV2}){
    const {globalState,globalDispatch}              = useContext(GlobalContext);
    let questionID                                  = question.ID;

    let buttonAdmin = null;
    if (globalState){
        if (globalState.user){
            if ("Type" in globalState.user){
                if (globalState.user.Type==='admin'){
                    let editHref = `https://zdajlekko.pl/admin/library/edit-question/${questionID}`;
                    if (question.SessionYear!==null)
                        editHref = `https://zdajlekko.pl/admin/question/edit/${questionID}`;
                    buttonAdmin = <Button target="_blank" variant="primary" href={editHref} size="sm"><MdEdit /> Edytuj<span className="d-none d-md-inline">  pytanie</span></Button>
                }
            }
        }
    }
    return <>{buttonAdmin}</>
}

interface stateQuestionProposal{
    showModal:boolean;
};

// function QuestionProposal({rootState,setRootState}:{rowQuestion:recordQuestionV2,rootState:stateQuestionV2,setRootState:Dispatch<SetStateAction<stateQuestionV2>>}){
//     let [state,setState] = useState<stateQuestionProposal>({showModal:false});

//     const handleClose = () => setState({...state,showModal:false});
//     const handleYes = () => { 
//         setState({...state,showModal:false});
//         setRootState({...rootState,showProposal:true,showAbout:true});
//     }

//     return <>
//         <Button variant="info" size="sm" onClick={()=>setState({...state,showModal:true})}>Zaproponuj</Button>

//         <Modal show={state.showModal} onHide={handleClose}>
//             <Modal.Header closeButton>
//                 <Modal.Title>Czy chcesz coś zaproponować?</Modal.Title>
//             </Modal.Header>
//             <Modal.Body>Jeśli chcesz możesz zaproponować zmiany w omówieniu, kategorii, tagach, źródle lub poprawnej odpowiedzi.</Modal.Body>
//             <Modal.Footer>
//                 <Button variant="secondary" onClick={handleClose}>
//                     Nie chcę
//                 </Button>
//                 <Button variant="primary" onClick={handleYes}>
//                     Mam propozycję!
//                 </Button>
//             </Modal.Footer>
//         </Modal>
//     </>
// }

export function QuestionV2ElementUserTitle({rowQuestion,state,setState}:{rowQuestion:recordQuestionV2,state:stateQuestionV2,
    setState:Dispatch<SetStateAction<stateQuestionV2>>}){
    let navigate                        = useNavigate();
    let questionID = rowQuestion.ID;
        
    return <>
        <CardTitle className="d-flex flex-lg-row position-relative flex-column-reverse">
            <Dropdown as={ButtonGroup} className="align-self-lg-center me-lg-2 me-auto mt-2 mt-lg-0">
                <Button variant="primary" onClick={()=>navigate(`/app/egzaminy/pytania-uzytkownikow/pytanie/${questionID}`)} size="sm"><MdEdit /> Edytuj<span className="d-none d-md-inline">  pytanie</span></Button>
            </Dropdown>    
            <small className="question-source align-self-lg-center">
                Twoje pytanie w kategorii <b>{rowQuestion.CategoryName}</b> dla specjalizacji <b>{rowQuestion.SpecName}.</b>
            </small>
        </CardTitle>
    </>
}

export function QuestionV2ElementTitle({rowQuestion,state,setState}:{rowQuestion:recordQuestionV2,state:stateQuestionV2,
    setState:Dispatch<SetStateAction<stateQuestionV2>>}){
    let questionID = rowQuestion.ID;

    return <>
        <CardTitle className="d-flex flex-lg-row position-relative flex-column-reverse">
            <Dropdown as={ButtonGroup} className="align-self-lg-center me-lg-2 me-auto mt-2 mt-lg-0">
                <QuestionFavoriteButton QuestionID={questionID!} FavoriteID={rowQuestion.UserFavoriteID!} />
                <QuestionEdit question={rowQuestion} />
                {/* <QuestionProposal setRootState={setState} rootState={state} rowQuestion={rowQuestion} /> */}
            </Dropdown>
            <QuestionV2ElementTitleText question={rowQuestion} />
            {/* <QuestionTitle question={rowQuestion} /> */}
        </CardTitle>
    </>
}