import { Accordion, Breadcrumb } from "react-bootstrap";
import { FaToggleOn } from "react-icons/fa6";
import { Link, Navigate, useNavigate } from "react-router-dom";

export default function DrawHowItWorks(){
    let navigate = useNavigate();

    return <>
        <Accordion defaultActiveKey="0" flush>
            <Accordion.Item eventKey="-1">
                <Accordion.Header>Gdzie jest nawigacja?</Accordion.Header>
                <Accordion.Body>
                    <p>Nawigacja główna jest na górze strony, na belce górnej. W przypadku korzystania z telefonu nawigacja jest ukryta pod przyciskiem z trzema poziomymi paskami.</p>
                    <p>W działach <b>Egzaminy</b> i <b>Przeglądaj</b> występuje jeszcze nawigacja pomocnicza, która wyświetlana jest na komputerze po lewej stronie. W przypadku telefonów jest ona ukryta w przycisku obok tytułu strony.</p>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="0">
                <Accordion.Header>Gdzie są egzaminy CEM?</Accordion.Header>
                <Accordion.Body>
                    <p>Jeśli chcesz porobić egzaminy z bazy CEM musisz przejść do zakładki <b>Egzaminy</b>. Na tej podstronie możesz utworzyć egzaminy na podstawie sesji PES i go rozwiązać.</p>
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={()=>navigate('/app/egzaminy')}>Egzaminy</Breadcrumb.Item>
                        <Breadcrumb.Item onClick={()=>navigate('/app/egzaminy/cem')}>Baza pytań CEM</Breadcrumb.Item>
                    </Breadcrumb>
                    <p>Jeśli chcesz przeglądać sesje PES, bez rozwiązywania możesz skorzystać z <b>przeglądarki pytań</b>.</p>
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={()=>navigate('/app/przegladaj')}>Przeglądaj</Breadcrumb.Item>
                        <Breadcrumb.Item onClick={()=>navigate('/app/przegladaj/sesje')}>Sesje</Breadcrumb.Item>
                    </Breadcrumb>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header>Zakładka Egzaminy i Przeglądaj</Accordion.Header>
                <Accordion.Body>
                    <p>W zakładce <a onClick={()=>navigate('/app/egzaminy')}>Egzaminy</a> możesz tworzyć zestawy pytań do rozwiązywania, sprawdzać się jak idzie Ci nauka, po zamknięciu każdego egzaminu prezentowana jest statystyka z kategoriami.</p><p>Z tej zakładki korzystasz gdy się umawiamy na zrobienie np. pytań z jakiegoś działu, sesji itp. Potem przygotowujemy statystykę jak poszło całej grupie i prezentujemy ją na czacie grupowym.</p>
                    <p>W zakładce <a onClick={()=>navigate('/app/przegladaj')}>Przeglądaj</a> jest możliwość przeglądania pytań z podziałem na sesje, kategorie, tagi i źródła. Możesz czytać omówienia i sprawdzać prawidłowe odpowiedzi na pytania, ale nie możesz odpowiadać.</p>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
                <Accordion.Header>Otwarte egzaminy</Accordion.Header>
                <Accordion.Body>
                    <p>W zakładce <a onClick={()=>navigate('/app/egzaminy')}>otwarte</a> są egzaminy, które utworzyłeś i nie skończyłeś rozwiązywać, nie zostały zamknięte. Wszystkie zamknięte egzaminy są przenoszone do <a onClick={()=>navigate('/app/egzaminy/historia')}>historii</a>.</p>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
                <Accordion.Header>Historia egzaminów</Accordion.Header>
                <Accordion.Body>
                    <p>W zakładce <a onClick={()=>navigate('/app/egzaminy/historia')}>historia</a> są wszystkie zamknięte egzaminy, możesz przeglądać swoje wyniki. Dodatkowo z zakończonych egzaminów możesz utworzyć kolejny egzamin, który będzie zawierać tylko pytania na które zostały udzielone błędne odpowiedzi, formularz znajduje się pod tabelą.</p>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
                <Accordion.Header>Pytania których nie widziałeś</Accordion.Header>
                <Accordion.Body>
                    <p>W zakładce <a onClick={()=>navigate('/app/egzaminy/pytania-ktorych-nie-widziales')}>pytania których nie widziałeś</a> znajdują się pytania, na które nigdy nie odpowiedziałeś. Na początku przygody z naszą aplikacją będą tutaj wszystkie pytania z bazy cem, autorskie i użytkowników. Gdy zaczniesz rozwiązywać pytania w zakładce egzaminy ta lista będzie się zmniejszać. </p>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
                <Accordion.Header>Czym różni się tryb nauka i egzamin?</Accordion.Header>
                <Accordion.Body>
                    <p>W trybie nauka jest możliwość wyświetlenia <b>omówienia</b> i <b>dyskusji</b> przed udzieleniem odpowiedzi, w trybie <b>egzamin</b> można to zrobić dopiero po udzieleniu odpowiedzi.</p>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
                <Accordion.Header>Ulubione pytania</Accordion.Header>
                <Accordion.Body>
                    <p>Każde pytanie może zostać oznaczone jako ulubione, bez względu na to skąd pochodzi. Nie ma limitu na liczbę polubionych pytań.</p>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
                <Accordion.Header>Generator i Generator V2</Accordion.Header>
                <Accordion.Body>
                    <p>Generator V2 to wersja poprawiona generatora, która obsługuje wszystkie specjalizacje i posiada wiele usprawnień, np. możliwość mieszania pytań pomiędzy specjalizacjami jeśli masz do nich dostęp. Pierwszy generator działa tylko z <b>medycyną rodzinną</b>. </p>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
                <Accordion.Header>Problemy techniczne?</Accordion.Header>
                <Accordion.Body>
                    <p>Jeśli w aplikacji coś nie działa to najlepiej napisz do mnie na <a href="https://www.facebook.com/tomaszx6">messengera</a> lub na <a href="mailto:kontakt@zdajlekko.pl">kontakt@zdajlekko.pl</a>.</p>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    </>
}