import { Dispatch, SetStateAction, useContext } from "react";
import { Alert, Button, CardBody, ListGroup, ListGroupItem } from "react-bootstrap";
import { propsQuestion, stateQuestionV2 } from "../Question";
import { recordQuestionV2, recordQuestionV2Description, recordQuestionV2Option } from "../Record";
import { RecordQuestionOptionDescription } from "../../../Type/RecordQuestionDescription";
import DrawSpinner from "../../DrawSpinner";
import { MdCleanHands } from "react-icons/md";

function QuestionV2ElementOption({rootLoading,item,about,state,setState, onClick,variant,disabled,userAnswer,isUserAnswer,allowReset}:
        {item:recordQuestionV2Option,about:RecordQuestionOptionDescription|null,state:stateQuestionV2,setState:Dispatch<SetStateAction<stateQuestionV2>>,
            onClick?:(answer:string|null,questionID:number) => void,
            variant?:string,
            disabled:boolean,
            userAnswer:boolean,
            isUserAnswer:boolean,
            allowReset?:boolean,
            rootLoading:string|null
        })
{
    return <>
        <ListGroup.Item disabled={disabled} 
                        action={!isUserAnswer}
                        className="fs-5" 
                        key={`questionOption-${item.ID}`} 
                        onClick={onClick&&(!isUserAnswer)?(()=>onClick(item.Letter,item.QuestionID)):undefined} variant={variant}>
            <span className="text-primary fw-bold">{item.Letter}.</span> <span dangerouslySetInnerHTML={{__html:item.Content}}></span>
            {state.isLoading==='submit-answer'&&userAnswer?<DrawSpinner className="ms-1" />:null}
            {userAnswer&&allowReset?
                <Button onClick={()=>setState({...state,resetAnswer:true})} disabled={rootLoading!==null} size="sm" className="ms-2" variant="outline-primary">
                        <DrawSpinner show={rootLoading==='reset'} />
                        <MdCleanHands size="16" /> Usuń odpowiedź</Button>:null}
        </ListGroup.Item>        
        {state.showAbout && about!==null && (about.Content?.length??0)>0 ? 
                <ListGroup.Item><Alert variant="info mt-2 fs-6"><div dangerouslySetInnerHTML={{__html:about.Content}}></div></Alert></ListGroup.Item>
        :null}        
    </>
}

export function QuestionV2ElementOptions({rootLoading,disableAnswerClean,rowQuestion,rowAbout,state,setState,mode,onOptionClick}:
        {rowQuestion:recordQuestionV2,rowAbout:recordQuestionV2Description|null,state:stateQuestionV2,setState:Dispatch<SetStateAction<stateQuestionV2>>
            onOptionClick:(answer:string|null,questionID:number) => void,
            mode?:'exam'|'nauka'|'result'|'userQuestion',
            disableAnswerClean?:boolean,
            rootLoading:string|null
        })
{
    // let questionID = rowQuestion.ID;

    let forceDisabled = false;
    if (rowQuestion.CorrectAnswer==='X')
        forceDisabled=true;
    // if (mode===undefined)
    //     forceDisabled=true;

    let isUserAnswer = ['A','B','C','D','E'].some((x)=>x===rowQuestion.UserExamAnswer);
    if (mode===undefined)
        isUserAnswer=true;


    return <>
        {/* <CardBody> */}
            {/* <div className="fs-5 mb-2" style={{whiteSpace: "pre-wrap"}}  dangerouslySetInnerHTML={{__html:rowQuestion.Content}} /> */}
            <ListGroup variant="flush">
                {rowQuestion?.Options?.map(function(item,idx){
                    let variant = undefined;
                    if (state.isLoading==='submit-answer')
                        variant = item.Letter === rowQuestion.UserExamAnswer ? 'info' : undefined;
                    else{
                        if (mode===undefined ){
                            if ((state.showAnswer && item.Letter === rowQuestion.CorrectAnswer))
                                variant = 'success';
                        }
                        if (mode==='exam')
                        {
                            if (rowQuestion.UserExamAnswer===item.Letter)
                                variant = 'info';
                        }
                        if (mode==='nauka'){
                            if (rowQuestion.UserExamAnswer===item.Letter && item.Letter===rowQuestion.CorrectAnswer)
                                variant = 'success';
                            else{
                                if (isUserAnswer){
                                    if (item.Letter===rowQuestion.UserExamAnswer)
                                        variant = 'danger';
                                    if (item.Letter===rowQuestion.CorrectAnswer)
                                        variant = 'success';
                                }
                            }
                        }
                        if (mode==='result')
                        {
                            if (item.Letter === rowQuestion.CorrectAnswer )
                                variant = 'success';
                            if (item.Letter !== rowQuestion.CorrectAnswer && rowQuestion.UserExamAnswer === item.Letter )
                                variant = 'danger';
                        }
                    }

                    return <QuestionV2ElementOption key={`questionOption-${item.ID}`}
                                                    rootLoading={rootLoading}
                                                    disabled={forceDisabled}
                                                    allowReset={(rowQuestion.UserExamAnswer===item.Letter && mode!=='exam' && mode!=='result')}
                                                    isUserAnswer={isUserAnswer}
                                                    userAnswer={rowQuestion.UserExamAnswer===item.Letter}
                                                    state={state} 
                                                    setState={setState} 
                                                    item={item} 
                                                    variant={variant}
                                                    onClick={onOptionClick}
                                                    about={rowAbout?.Options.find(x=>x.Letter===item.Letter)??null} 
                                                    // onClick={()=>setState({...state,saveAnswer:true,userAnswer:item.Letter})}
                                                    />
                })}
            </ListGroup>
        {/* </CardBody> */}
    </>
}