import { useContext } from "react";
import { Alert, Badge, CardBody, Form, ListGroup } from "react-bootstrap";
import { propsQuestion, stateQuestionV2 } from "../Question";
import { recordQuestionV2, recordQuestionV2Description } from "../Record";
// import AsyncSelect from "react-select/async";
// import { RecordCategory } from "../../../Type/RecordCategory";
// import axios from "axios";

function QuestionV2ElementContentDescription({show,about,showProposal}:{showProposal:boolean,show:boolean,about:recordQuestionV2Description|null}){
    if (about===null) return <></>
    if (show===false) return <></>
    if (showProposal===true) return <></>
    return <>
        <Alert variant="info">
            <div className="questionAbout" dangerouslySetInnerHTML={{__html:about?.Content}}></div>
            {about?.Category?<h3 className="h5 text-primary mb-2">Kategoria : <span className="badge bg-primary text-white ms-2">{about?.Category}</span></h3>:null}
            {about?.Source?.length>0?<>
                <h3 className="h5 text-primary mb-2">Źródła</h3>
                <ListGroup className="mb-4">
                    {about?.Source.map(function(item,idx){
                        return <ListGroup.Item key={idx}>{item.Content} {item.Addon!==undefined&&item.Addon?.length!==undefined?(item.Addon?.length>0?item.Addon:null):null}</ListGroup.Item>
                    })}
                </ListGroup>
            </>:null}
            {about?.Tags?.length>0?<>
                <h3 className="h5 text-primary mb-2">Tagi</h3>
                <div className="mb-4">
                    {about?.Tags.map(function(i,idx){
                        return <Badge pill bg="primary" key={idx} className="me-2">{i.Name}</Badge>
                    })}
                </div>
            </>:null}
        </Alert>
    </>
}

// function QuestionV2ElementContentProposal({about}:{about:recordQuestionV2Description|null}){

//     const promiseOptions = (i:string) => 
//         new Promise<RecordCategory[]>(async (resolve) => {
//             let catReq = await axios.post(`/v2/pytanie/kategorie`);
//             resolve(catReq.data.data);
//     });
//     /*{
//         let getCats = async () => {
//             let catReq = await axios.post(`/v2/pytanie/kategorie`);
//             return (catReq.data.data as RecordCategory[]).map((i)=>{
//                 return {value:i.ID,label:i.Name};
//             });
//         };
//         return getCats();
//     };*/


//     return <>
//         <h2>Zaproponuj</h2>
//         <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
//             <Form.Label>Omówienie</Form.Label>
//             <Form.Control as="textarea" rows={3} />
//         </Form.Group>
//         <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
//             <Form.Label>Kategoria</Form.Label>
//             <AsyncSelect cacheOptions isClearable defaultOptions value={about?.Category!} loadOptions={promiseOptions} />
//         </Form.Group>
//     </>
// }

export function QuestionV2ElementContent({rowQuestion,rowAbout,state}:{rowQuestion:recordQuestionV2,rowAbout:recordQuestionV2Description|null,state:stateQuestionV2}){
    let questionID = rowQuestion.ID;

    return <>
        <div className="fs-5 mb-2" style={{whiteSpace: "pre-wrap"}}  dangerouslySetInnerHTML={{__html:rowQuestion.Content}} />
        {/* <QuestionV2ElementContentProposal about={rowAbout} /> */}
        <QuestionV2ElementContentDescription show={state.showAbout} about={rowAbout} showProposal={state.showProposal!} />
    </>
}