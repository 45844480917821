import { Spinner } from "react-bootstrap";

interface DrawSpinnerProps{
    show:boolean;
    size:"sm"|undefined;
    text:string|null;
    className:string|undefined;
}

const DrawSpinnerDefaultProps = {
    show:true,
    size:"sm",
    text:undefined,
    className:undefined
}

function MyDrawSpinner({show=true,size=undefined,text,className}:DrawSpinnerProps){
    // console.log(show);
    return <>
        {show?<>
            <Spinner animation="border" role="status" size={size} className={className}><span className="visually-hidden">Loading...</span></Spinner> {text} 
            </>
        : null }
    </>
}

const DrawSpinner = (props:any) => (
    <MyDrawSpinner {...DrawSpinnerDefaultProps} {...props} />
)

export default DrawSpinner;

// DrawSpinner.defaultProps = {size:undefined,show:true,text:null,className:'me-1'};