import { Alert, Button, Col, Row, ToggleButton } from "react-bootstrap";
import { MdArrowRight, MdCheck, MdCheckBoxOutlineBlank, MdInfo, MdStar } from "react-icons/md";
import DrawSpinner from "../../Componets/DrawSpinner";
import axios from "axios";
import checkResponse from "../../Helpers/CheckResponse";
import { Dispatch, SetStateAction, useState } from "react";
import { ExamPageGeneratorV2State } from "../PageGenerator";

export function ExamPageGeneratorV2StepSpec({state,setState}:{state:ExamPageGeneratorV2State,setState:Dispatch<SetStateAction<ExamPageGeneratorV2State>>}){
    let [loading,setLoading]    = useState<boolean>(false);

    if (state.specList===null && loading===false){
        setLoading(true);

        axios({url:'/specjalizacja/list',maxRedirects:0}).then((r)=>{
            setState({...state,specList:r.data.data});
            setLoading(false);
        }).catch((e)=>{
            if (e.response){
                if (checkResponse(e.response.status))
                    return;
            }
        });
        return <></>
    }

    function onBtnChange(value:number){
        let isChecked = state.specID?.find(x=>x===value) ? true : false;
        if (isChecked)
            setState({...state,specID:state.specID?.filter(x=>x!==value)});
        else
            setState({...state,specID:[...state.specID,value]});
    }

    if (loading===true){
        return <><DrawSpinner text="Pobieranie listy specjalizacji..." show={true} /></>
    }else{
        return <>
            <Row>
                <Col xs={12}>
                    <div className="main-content">
                        <Alert variant="info">
                            <p><MdInfo /> <strong>Generator V2</strong> różni się od zwykłego Generatora przede wszystkim zmianami w interfejsie (dodane filtry w kategoriach, tagach, sesjach).</p><p>Dodatkową funkcją jest możliwość mieszania pytań między różnymi specjalizacjami jeśli masz dostęp do więcej niż jednej.</p>
                            <p className="mb-0"><MdStar /> W pierwszej wersji generator pomijał pytania <b>bez kategorii i tagów</b>, w V2 jest to poprawione i takie pytania są umieszone w <b>bez kategorii</b> oraz <b>bez tagów</b>.</p>
                        </Alert>
                        <p>Masz dostęp do większej liczby specjalizacji niż jedna, z których chcesz korzystać w generatorze?</p>
                        {/* <Form.Select aria-label="Wybierz specjalizacje z których chcesz skorzystać w generatorze" multiple size="lg" onChange={onSpecChange}>
                            {state.specList ? state.specList.map(function(r){
                                return <option key={`spec${r.ID}`} value={r.ID}>{r.Name}</option>
                            }):null}
                        </Form.Select> */}
                        <div className="d-grid gap-2 d-md-block">
                            {state.specList ? state.specList.map(function(r){
                                let isChecked = state.specID?.find(x=>x===r.ID) ? true : false;
                                return <ToggleButton className="me-md-2" key={`specCol-${r.ID}`} id={`tbSpec-${r.ID}`} type="checkbox" variant="outline-primary" checked={isChecked} onChange={()=>onBtnChange(r.ID)} value={r.ID}>
                                        {isChecked ? <MdCheck size={32} /> : <MdCheckBoxOutlineBlank size={32} />}
                                        <span className="ms-1">{r.Name}</span>
                                    </ToggleButton>
                            }):null}
                        </div>
                        {state.specID.length>0 ? 
                            <div className="d-grid mt-2">
                                <Button disabled={state.specID.length===0} onClick={() => setState({...state,page:'source',categories:null,tags:null,sessions:null,useLib:false,useCEM:false})}>Przejdź dalej <MdArrowRight /></Button>
                            </div>:null}
                    </div>
                </Col>
            </Row>
        </>
    }
}