import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { Outlet } from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import IdentityNavBar from './IdentityNavBar';
import { useNavigation } from 'react-router-dom';
import AppContainer from './Container';
import { Button, Offcanvas } from 'react-bootstrap';
import { MdHomeMini, MdModeNight, MdMoney, MdOutlinePreview, MdSunny, MdVerified } from 'react-icons/md';
import { useNavigate } from "react-router-dom";
import { useContext } from 'react';
import DrawSpinner from './DrawSpinner';
import GlobalContext, { UserAccessListItem } from './GlobalContext';
import { Helmet } from 'react-helmet-async';
import { useCookies } from 'react-cookie';

export function AppNavBar() {
  const [cookies, setCookie, removeCookie]  = useCookies(['siteTheme']);
  const {globalState,globalDispatch}        = useContext(GlobalContext);
  let navigate = useNavigate();
  const { state } = useNavigation();
  // let [show,setShow] = useState<boolean>(false);

  function onNavClick(e:any,location:string){
    e.preventDefault();
    navigate(location);
  }
  // console.log(cookies);
  // useEffect(()=>function(){
  //   // console.log({cookie:cookies.siteTheme,state:globalState.siteTheme});
  //   let curTheme = cookies.siteTheme==='dark'?'dark':'white';

  //   if (curTheme!==globalState.siteTheme){
  //     globalDispatch({type:'SET_THEME',value:curTheme});
  //     setCookie('siteTheme',curTheme);
  // }},[globalState.siteTheme]);

  function changeTheme(){
    globalDispatch({type:'SWITCH_THEME'});
    // let curTheme = globalState.siteTheme==='dark'?'dark':'white';
    // setCookie('siteTheme',curTheme);
  }

  return (
    <>
    <Helmet>
      <html data-bs-theme={globalState.siteTheme==='dark'?'dark':null} />
    </Helmet>
    <div>
      <Navbar className="z-navbar border-bottom border-primary" expand="md" collapseOnSelect>
        <Container>
          <Navbar.Brand className='fw-bold'>ZdajLEKko.pl</Navbar.Brand>
          <Button onClick={changeTheme} className="me-auto" variant="outline-primary"> 
            Tryb {globalState.siteTheme?<>nocny <MdModeNight /></>:<>dzienny <MdSunny /></>}
          </Button>
          
          <div className="justify-content-end">
            <Navbar.Toggle aria-controls={`navbar-off`} 
              // onClick={()=>setShow(!show)} 
              />
            <Navbar.Offcanvas
                // show={show}
                id={`navbar-off`}
                aria-labelledby={`navbar-off`}
                placement="end"
                // restoreFocus={false}
                // onHide={()=>setShow(false)}
              >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`navbar-off`}>
                  
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="me-auto">
                  <IdentityNavBar />
                  <Nav.Link href="/app/" onClick={(e)=>onNavClick(e,'/app/')}><MdHomeMini /> Index</Nav.Link>
                  <Nav.Link href="/app/oferta" onClick={(e)=>onNavClick(e,'/app/oferta')}><MdMoney /> Oferta</Nav.Link>
                  {/* {globalState.access && (globalState.access as UserAccessListItem[]).find(x=>x.SpecID===81) ? 
                    <Nav.Link href="/app/egzamin-lek" onClick={(e)=>onNavClick(e,'/app/egzamin-lek')}><MdVerified /> LEK</Nav.Link> : null } */}
                  {globalState.access && (globalState.access as UserAccessListItem[]).find(x=>x.SpecID!==81) ? 
                    <Nav.Link href="/app/egzaminy" onClick={(e)=>onNavClick(e,'/app/egzaminy')}><MdVerified /> Egzaminy</Nav.Link> : null }
                   <Nav.Link href="/app/przegladaj" onClick={(e)=>onNavClick(e,'/app/przegladaj')}><MdOutlinePreview /> Przeglądaj</Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </div>
        </Container>
      </Navbar>
      <AppContainer>
        <DrawSpinner show={state === 'loading'} className="my-5" />
        {state === 'loading'?null:<Outlet />}
      </AppContainer>
      </div>
    </>
  );
}

export default AppNavBar;