import { Col, Row } from "react-bootstrap";
import ExamNav from "./Nav";
import ExamPageCEM from "./PageCEM";
import { Suspense, useState, lazy } from "react";
import ExamPageLibrary from "./PageLibrary";
import ExamPageHistory from "./PageHistory";
import ExamPageGenerator, { ExamPageGeneratorV2 } from "./PageGenerator";
import ExamPageGeneratorCategories from "./PageGeneratorCategories";
import ExamPageFavorites from "./PageFavorites";
import ExamPageNotSeen from "./PageNotSeen";
// import ExamPageOpened from "./ExamPageOpened";
import ExamPageIndexMainTitle from "../Componets/ExamPageIndexMainTitle";
import UserLibraryIndexPage from "../UserLibrary/Index";
import ExamPageOpened from "./ExamPageOpened";

export default function ExamPageIndex({activeTab}:{activeTab:string}){
    let [showNav,setShowNav]    = useState<boolean>(false);

    // let LazyComponent = null;
    // if (activeTab==='otwarte'){
    //     LazyComponent = lazy(() => import('./ExamPageOpened'));
    // }

    return <>
        <Row>
            <Col lg={2}>
                <ExamNav activeTab={activeTab} setShow={setShowNav} show={showNav} />
            </Col>
            <Col lg={10} xs={12}>
                <Row>
                {activeTab==='otwarte'?<>
                        <ExamPageIndexMainTitle title="Otwarte egzaminy" setShowNav={setShowNav} />
                        <Col xs={12}>
                            <ExamPageOpened />
                            {/* <Suspense fallback={<div>Loading...</div>}>
                                {LazyComponent!==null?<LazyComponent />:null}
                             </Suspense> */}
                        </Col>
                    </>
                :null}
                {activeTab==='cem'?<>
                        <ExamPageIndexMainTitle title="Baza danych CEM" setShowNav={setShowNav} />
                        <Col xs={12}>
                            <ExamPageCEM />
                        </Col>
                    </>
                :null}
                {activeTab==='autorskie'?<>
                        <ExamPageIndexMainTitle title="Pytania autorskie" setShowNav={setShowNav} />
                        <Col xs={12}>
                            <ExamPageLibrary />
                        </Col>
                    </>
                :null}
                {activeTab==='usersLibrary'?<>
                        <ExamPageIndexMainTitle title="Pytania użytkowników" setShowNav={setShowNav} />
                        <Col xs={12}>
                            <UserLibraryIndexPage />
                        </Col>
                    </>
                :null}
                {activeTab==='usersLibraryAdd'?<>
                        <ExamPageIndexMainTitle title="Pytania użytkowników - Pytanie" setShowNav={setShowNav} />
                        <Col xs={12}>
                            <UserLibraryIndexPage page="question" />
                        </Col>
                    </>
                :null}
                {activeTab==='historia'?<>
                        <ExamPageIndexMainTitle title="Historia egzaminów" setShowNav={setShowNav} />
                        <Col xs={12}>
                            <ExamPageHistory />
                        </Col>
                    </>
                :null}
                {activeTab==='generator'?<>
                        <ExamPageIndexMainTitle title="Generator" setShowNav={setShowNav} />
                        <Col xs={12}>
                            <ExamPageGenerator />
                        </Col>
                    </>
                :null}
                {activeTab==='generatorv2'?<>
                        <ExamPageIndexMainTitle title="Generator V2" setShowNav={setShowNav} />
                        <Col xs={12}>
                            <ExamPageGeneratorV2 />
                        </Col>
                    </>
                :null}
                {activeTab==='egzamin-probny'?<>
                        <ExamPageIndexMainTitle title="Próbny egzamin" setShowNav={setShowNav} />
                        <Col xs={12}>
                            <ExamPageGeneratorCategories />
                        </Col>
                    </>
                :null}
                {activeTab==='ulubione'?<>
                        <ExamPageIndexMainTitle title="Ulubione pytania" setShowNav={setShowNav} />
                        <Col xs={12}>
                            <ExamPageFavorites />
                        </Col>
                    </>
                :null}
                {activeTab==='not-seen'?<>
                        <ExamPageIndexMainTitle title="Pytania nie widziane" setShowNav={setShowNav} />
                        <Col xs={12}>
                            <ExamPageNotSeen />
                        </Col>
                    </>
                :null}
                </Row>
                {/* {activeTab==='generator'?<ExamCreatePageGenerator />:null}
                {activeTab==='autorskie'?<ExamCreatePageAutorskie />:null}
                {activeTab==='ulubione'?<ExamCreatePageFavorites />:null}
                {activeTab==='historia'?<ExamCreatePageHistory />:null}
                {activeTab==='not-seen'?<ExamCreateNotSeen />:null}
                {activeTab==='generator-kategorie'?<ExamCreatePageGeneratorByCategories />:null} */}
            </Col>
        </Row>
    </>
}