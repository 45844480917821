import axios from "axios";
import { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from "react";
import { Alert, Button, Card, CardBody, CardHeader, Col, FloatingLabel, Form, Row, ToggleButton } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { FaCheck } from "react-icons/fa6";
import DrawSpinner from "../Componets/DrawSpinner";
import { MdArrowBack, MdInfo } from "react-icons/md";
import * as formik from 'formik';
import * as yup from 'yup';
import GlobalContext from "../Componets/GlobalContext";

interface recordProductPrice{
    ID:number;
    ProductID:number;
    Title:string;
    Content:string;
    Price:number;
    TimeDelta:string;
}

interface recordUserAccess{
    Created:string;
    Expiration:string;
    SpecID:number;
    ProductTTL:string;
}

interface recordProduct{
    ID:number;
    Title:string;
    Content:string;
    SpecID:number;
    SpecName:string;
    UserAccess:recordUserAccess;
    Prices:recordProductPrice[]|null;
}

interface stateProductIndex{
    productsList:recordProduct[]|null;
    selectedSpecID:number|null;

    Step:string;

    UserSelectedProductPriceID:number|null;
    UserSelectedProductRow:recordProduct|null;
    UserSelectedProductProceRow:recordProductPrice|null;
    UserAgree:boolean;
    UserFavNeed:boolean;
    UserNewsletterAgree:boolean;

    PayLink:string|null;
}

function ProductFavForm(){
    return <>

        <FloatingLabel controlId="favBusinessName" label="Pełna nazwa firmy..." className="mb-3">
            <Form.Control type="favBusinessName" placeholder="Nazwa mojej firmy..." size="sm" />
        </FloatingLabel>
        <FloatingLabel controlId="favBusinessNIP" label="Numer NIP" className="mb-3">
            <Form.Control type="favBusinessNIP" placeholder="" size="sm" />
        </FloatingLabel>
    </>
}

function ProductIndexCard({row,state,setState}:{row:recordProductPrice,state:stateProductIndex,setState:Dispatch<SetStateAction<stateProductIndex>>}){
    if (!row)return <></>
    return <>
        <Col xs={12} lg={3}>
            <Card className="mb-4 rounded-3 shadow-sm">
                <CardHeader className="py-3 txt-c bg-secoundary">
                    {row.Title}
                </CardHeader>
                <CardBody>
                    <div dangerouslySetInnerHTML={{__html:row.Content}} />
                </CardBody>
                <ToggleButton id={`productPrice-${row.ID}`}
                              type="radio" variant="outline-primary" name="radio" 
                              value={row.ID} checked={state.UserSelectedProductPriceID===row.ID} onChange={() => setState({...state,UserSelectedProductPriceID:row.ID})} >
                    {row.Price} zł {state.UserSelectedProductPriceID===row.ID?<FaCheck />:null} 
                </ToggleButton>
                {state.UserSelectedProductPriceID===row.ID?
                    <Button variant="outline-primary" className="mt-1" onClick={()=>setState({...state,Step:'agrees',UserSelectedProductProceRow:row})}>Przejdź dalej</Button>
                :null}

                {/* {productState.ID===row.ID?
                    <Form.Check type="checkbox" id={`userTermsAgree${row.ID}`} className="my-2 mx-2">
                            <Form.Check.Input type="checkbox" checked={productState.UserAgree} onClick={(e)=>setProductState({...productState,UserAgree:!productState.UserAgree})} />
                            <Form.Check.Label>Oświadczam, że zapoznałem/am się z treścią <a target="_blank" href="http://zdajlekko.laminas.local/regulamin-2024-11-20.pdf">Regulaminu</a> oraz <a target="_blank" href="http://zdajlekko.laminas.local/polityka-prywatnosci.pdf">Polityki Prywatności</a>. Wyrażam zgodę na spełnienie świadczenia i dostarczenie treści cyfrowych przed upływem terminu do odstąpienia od umowy. Jestem świadomy/a, że jest to równoznaczne z utratą prawa do odstąpienia od umowy.</Form.Check.Label>
                    </Form.Check>
                    :null}
                {productState.UserAgree===true&&productState.ID===row.ID?
                <>
                    <ProductFavForm />
                    <Button variant="primary">Kupuję za {row.Price} zł</Button>
                </>
                :null} */}
            </Card>
        </Col>
    </>
}

function DrawUserAccessInfo({userAccess,selectedProduct}:{userAccess:recordUserAccess,selectedProduct:recordProduct}){
    return <>
        <Col xs={12}>
            <Alert variant="info">
                <div className="d-flex"><MdInfo size={40} /> <h2 className="ms-3">Masz już dostęp!</h2></div>
                <p>Masz już dostęp <b>{selectedProduct.Title}</b> od <b>{userAccess.Created}</b> do <b>{userAccess.Expiration}</b>. <br />
                Jeśli zdecydujesz się zakupić kolejny pakiet dostępu dla <b>{selectedProduct.SpecName}</b> to przedłużymy Twój dostęp o wybraną ilość dni.
                </p>
            </Alert>
        </Col>
    </>
}

function ProductSelectSpecStep({state,setState}:{state:stateProductIndex,setState:Dispatch<SetStateAction<stateProductIndex>>}){
    let selectedSpecID                  = useRef<HTMLSelectElement|null>(null);
    let selectedProduct                 = state.productsList?.find(x=>x.ID===state.selectedSpecID);
    let isLoading                       = useRef<boolean>(false);

    if (state.productsList===null){
        axios.get(`/oferta`).then((r) => {
            setState({...state,productsList:r.data.data});
        }, (error) => {
            // console.log(error);
        });

        return <>
            <Helmet>
                <title>Nasza Oferta - Wybierz specjalizację oraz pakiet dostępu</title>
            </Helmet>
            <Row>
                <Col xs={12}>
                    <div className="main-title">
                        <h1>Nasza oferta</h1>
                    </div>
                </Col>
                <Col xs={12}><DrawSpinner text="Pobieram listę specjalizacji..." /></Col>
            </Row>
        </>
    }

    if ((state.selectedSpecID??0)>0&&selectedProduct?.Prices===null&&isLoading.current===false){
        isLoading.current=true;
        axios.post(`/oferta/prices`,{
            ID:state.selectedSpecID
        }).then((response) => {
            isLoading.current=false;
            let productCopy = state.productsList?.map(function(i){
                if (i.ID===state.selectedSpecID){
                    i.UserAccess = response.data.userAccess;
                    i.Prices=response.data.data;
                }
                return i;
            });
            setState({...state,productsList:(productCopy??null)});
        }, (error) => {
            isLoading.current=false;
        });
    }

    // console.log(state);

    function specSelectChange(e:any) {
        e.preventDefault();
        let id=parseInt(e.target.value);
        setState({...state,selectedSpecID:id,UserSelectedProductRow:state.productsList?.find(x=>x.ID===id)??null});
    }

    return <>
    <Helmet>
        <title>Nasza Oferta - Wybierz specjalizację oraz pakiet dostępu</title>
    </Helmet>
    <Row>
        <Col xs={12}>
            <div className="main-title">
                {/* <Button className="navback" variant="btn-outline-secoundary"><MdArrowBack /></Button> */}
                <h1 className="text-primary">Nasza oferta</h1>
                {/* <div className="btn-group">
                    <Button>Bla bla bal</Button>
                    <Button>X</Button>
                </div> */}
            </div>
        </Col>
        <Col xs={12}>
            <div className="main-content">
                <Form.Group as={Row} className="mb-3" controlId="formSpec">
                    <p>Wybierz specjalizację jaka Cię interesuje.</p>
                    <Form.Label column sm={2}>Specjalizacja</Form.Label>
                    <Col sm={10}>
                        <Form.Select aria-label="Wybierz specjalizację..." ref={selectedSpecID} onChange={specSelectChange} defaultValue={state.selectedSpecID!}>
                            <option value="0">Wybierz specjalizację...</option>
                            {state.productsList?.map(function(d, idx){
                                    return (<option value={d.ID} key={d.ID}>{d.SpecName}</option>)
                            })}
                        </Form.Select>
                    </Col>
                </Form.Group>
            </div>
        </Col>
        {state.selectedSpecID===0?
            <><Alert variant="info">Wybierz specjalizację!</Alert></>
        :
            selectedProduct?.Prices?.length!>0?<>
                {selectedProduct?.UserAccess ? <DrawUserAccessInfo selectedProduct={selectedProduct} userAccess={selectedProduct.UserAccess} /> : null }
                <Col xs={12}>
                    <Card className="mb-4"><CardBody><div dangerouslySetInnerHTML={{__html:selectedProduct?.Content!}}></div></CardBody></Card>
                </Col>
                {selectedProduct?.Prices?.map(function(i){
                    return <ProductIndexCard key={`productCard-${i.ID}`} row={i} state={state} setState={setState} />
                })}</>
                :
            <Col xs={12}><Alert variant="info">Brak pakietów dostępu do wyświetlenia</Alert></Col>
        }
    </Row>
    </>
}

function ProductSelectAgreeStep({state,setState}:{state:stateProductIndex,setState:Dispatch<SetStateAction<stateProductIndex>>}){
    const {globalState,globalDispatch} = useContext(GlobalContext);
    // const schema = yup.object().shape({
    const schema = yup.object({
        UserAgree: yup.bool().required('Oświadczenie jest wymagane.'),
        UserFavNeed: yup.bool().nullable(),
        UserNewsletterAgree: yup.bool().nullable(),
        favNIP:yup.string().nullable().when('UserFavNeed',([UserFavNeed],s)=>{
            return UserFavNeed===true?s.required('Numer NIP jest wymagany.').min(9,'NIP jest za krótki.').max(10,'Kod NIP jest za długi.'):s.notRequired();
        }),
        favName:yup.string().nullable().notRequired().when('UserFavNeed',([UserFavNeed],s)=>{
            return UserFavNeed===true?s.required('Nazwa firmy jest wymagana do faktury.').min(2,'Podaj nazwę firmy do faktury.').max(64,'Nazwa firmy jest zbyt długa.'):s.notRequired();
        }),
        favPostCode:yup.string().nullable().when('UserFavNeed',([UserFavNeed],s)=>{
            return UserFavNeed===true?
                (s.required('Kod pocztowy jest wymagany.').matches(/\d{2}-\d{3}/,'Nieprawidłowy kod pocztowy lub format, 00-000.'))
                :s.notRequired();
        }),
        favCity:yup.string().nullable().when('UserFavNeed',([UserFavNeed],s)=>{
            return UserFavNeed===true?s.required('Nazwa miasta jest wymagana.').max(128,'Nazwa miasta jest za długa.'):s.notRequired();
        }),
        favStreet:yup.string().nullable().when('UserFavNeed',([UserFavNeed],s)=>{
            return UserFavNeed===true?s.required('Nazwa ulicy jest wymagana.').max(128,'Nazwa ulicy jest za długa.'):s.notRequired();
        }),
        favHomeNo:yup.string().nullable().when('UserFavNeed',([UserFavNeed],s)=>{
            return UserFavNeed===true?s.required('Numer domu/lokalu jest wymagany.').max(16,'Numer budynku/lokalu jest za długi.'):s.notRequired();
        }),
        UserProductPriceID:yup.number().required(),
        UserProductID:yup.number().required(),
        UserSpecID:yup.number().required(),
        // UserID:yup.number().required()
    });

    const f = formik.useFormik({
        validationSchema:schema,
        onSubmit(values, formikHelpers) {
            axios.post(`/platnosc`,values).then((r)=>{
                if (r.data.isValid===true){
                    setState({...state,PayLink:r.data.redirect,Step:'pay'});
                }
            }, (e) => {
            });
        },
        initialValues:{
            // UserID:globalState.user.ID,
            UserAgree: false,
            UserFavNeed: false,
            UserNewsletterAgree: false,
            favNIP:'',
            favName:'',
            favPostCode:'',
            favCity:'',
            favStreet:'',
            favHomeNo:'',
            UserProductPriceID:state.UserSelectedProductPriceID!,
            UserProductID:state.UserSelectedProductRow?.ID,
            UserSpecID:state.selectedSpecID
        }
    });
    // console.log(globalState.user);
    // f.validateField

    // myFormik.errors.UserAgree

    return <>
        <Helmet>
            <title>Nasza Oferta - Wybierz specjalizację oraz pakiet dostępu</title>
        </Helmet>
        <Row>
            <Col xs={12}>
                <div className="main-title">
                    <h1 className="text-primary">Twój wybór</h1>
                    <div className="btn-group">
                        <Button variant="secondary" onClick={()=>setState({...state,Step:'start'})}>Wróć</Button>
                    </div>
                </div>
            </Col>
            <Col xs={12}>
                <Form noValidate onSubmit={f.handleSubmit}>
                    {/* <Form.Control type="hidden" name="UserID" value={f.values.UserID!} /> */}
                    <Form.Control type="hidden" name="UserProductPriceID" value={f.values.UserProductPriceID!} />
                    <Form.Control type="hidden" name="UserProductID" value={f.values.UserProductID!} />
                    <Form.Control type="hidden" name="UserSpecID" value={f.values.UserSpecID!} />
                <div className="main-content">
                    <div className="row mb-1">
                        <label htmlFor="userSelectedSpec" className="col-sm-2 col-form-label">Specjalizacja</label>
                        <div className="col-sm-10">
                            <input type="text" readOnly={true} className="form-control-plaintext form-control-sm" id="userSelectedSpec" value={state.UserSelectedProductRow?.SpecName} />
                        </div>
                    </div>
                    <div className="row mb-1">
                        <label htmlFor="userSelectedAccessDelta" className="col-sm-2 col-form-label">Pakiet</label>
                        <div className="col-sm-10">
                            <input type="text" readOnly={true} className="form-control-plaintext form-control-sm" id="userSelectedAccessDelta" value={state.UserSelectedProductProceRow?.Title} />
                        </div>
                    </div>
                    <div className="row mb-1">
                        <label htmlFor="userSelectedAccessPrice" className="col-sm-2 col-form-label">Cena</label>
                        <div className="col-sm-10">
                            <input type="text" readOnly={true} className="form-control-plaintext form-control-sm" id="userSelectedAccessPrice" value={`${state.UserSelectedProductProceRow?.Price} zł`} />
                        </div>
                    </div>
                    <Form.Check type="checkbox" id={`userTermsAgree${state.UserSelectedProductProceRow?.ID}`} className="m-1">
                        {/* <Form.Check.Input name="UserAgree" type="checkbox" checked={f.values.UserAgree!} onChange={f.handleChange} isValid={f.touched.UserAgree&&f.errors.UserAgree?false:true} isInvalid={f.touched.UserAgree&&f.errors.UserAgree?true:false} /> */}
                        {/* <Form.Check.Input name="UserAgree" type="checkbox" checked={f.values.UserAgree!} onChange={f.handleChange} /> */}
                        <Form.Check.Input type="checkbox" {...f.getFieldProps('UserAgree')} />
                        <Form.Check.Label>* Oświadczam, że zapoznałem/am się z treścią <a target="_blank" href="http://zdajlekko.laminas.local/regulamin-2024-11-20.pdf">Regulaminu</a> oraz <a target="_blank" href="http://zdajlekko.laminas.local/polityka-prywatnosci.pdf">Polityki Prywatności</a>. Wyrażam zgodę na spełnienie świadczenia i dostarczenie treści cyfrowych przed upływem terminu do odstąpienia od umowy. Jestem świadomy/a, że jest to równoznaczne z utratą prawa do odstąpienia od umowy.</Form.Check.Label>
                        <Form.Control.Feedback type="invalid">{f.errors.UserAgree}</Form.Control.Feedback>
                    </Form.Check>

                    <Form.Check type="checkbox" id={`userNewsletter${state.UserSelectedProductProceRow?.ID}`} className="m-1">
                        <Form.Check.Input name="UserNewsletterAgree" type="checkbox" checked={f.values.UserNewsletterAgree!} onChange={f.handleChange} />
                        <Form.Check.Label>Wyrażam zgodę na przetwarzanie przez administratora strony zdajlekko.pl  - Tomasza Jarosz prowadzącego działalność gospodarczą pod firmą Hello Code Tomasz Jarosz z siedzibą w Zazdrości, moich danych osobowych tj. adresu e-mail w celu przesyłania mi Newslettera, informacji handlowych dotyczących produktów i usług oferowanych za pomocą środków komunikacji elektronicznej. Oświadczam, że zostałem poinformowany o możliwości wycofania udzielonych zgód w dowolnym momencie.</Form.Check.Label>
                    </Form.Check>

                    <Form.Check type="checkbox" id={`userFAV${state.UserSelectedProductProceRow?.ID}`} className="m-1">
                        {/* <Form.Check.Input name="UserFavNeed" type="checkbox" checked={f.values.UserFavNeed!} onChange={f.handleChange} /> */}
                        <Form.Check.Input type="checkbox" {...f.getFieldProps('UserFavNeed')} />
                        <Form.Check.Label>Chcę otrzymać fakturę drogą elektroniczną na adres podany przy tworzeniu konta w serwisie.</Form.Check.Label>
                    </Form.Check>

                    {f.values.UserFavNeed===true?
                        <>
                        <Row>
                            <Col xs={12} md={3}>
                                <FloatingLabel controlId="favNIP" label="Numer NIP" className="my-2">
                                    <Form.Control type="text" name="favNIP" value={f.values.favNIP!} onChange={f.handleChange} isValid={f.touched.favNIP&&!f.errors.favNIP} isInvalid={f.touched.favNIP&&f.errors.favNIP?true:false} />
                                    <Form.Control.Feedback type="invalid">{f.errors.favNIP}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col xs={12} md={5}>
                                <FloatingLabel controlId="favName" label="Nazwa firmy" className="my-2">
                                    <Form.Control type="text" name="favName" value={f.values.favName!} onChange={f.handleChange} isValid={f.touched.favName&&!f.errors.favName} isInvalid={f.touched.favName&&f.errors.favName?true:false} />
                                    <Form.Control.Feedback type="invalid">{f.errors.favName}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={3}>
                                <FloatingLabel controlId="favName" label="Kod pocztowy" className="my-2">
                                    <Form.Control type="text" name="favPostCode" value={f.values.favPostCode!} onChange={f.handleChange} isValid={f.touched.favPostCode&&!f.errors.favPostCode} isInvalid={f.touched.favPostCode&&f.errors.favPostCode?true:false} />
                                    <Form.Control.Feedback type="invalid">{f.errors.favPostCode}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col xs={12} md={5}>
                                <FloatingLabel controlId="favName" label="Miasto" className="my-2">
                                    <Form.Control type="text" name="favCity" value={f.values.favCity!} onChange={f.handleChange} isValid={f.touched.favCity&&!f.errors.favCity} isInvalid={f.touched.favCity&&f.errors.favCity?true:false} />
                                    <Form.Control.Feedback type="invalid">{f.errors.favCity}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={4}>
                                <FloatingLabel controlId="favName" label="Nazwa ulicy" className="my-2">
                                    <Form.Control type="text" name="favStreet" value={f.values.favStreet!} onChange={f.handleChange} isValid={f.touched.favStreet&&!f.errors.favStreet} isInvalid={f.touched.favStreet&&f.errors.favStreet?true:false} />
                                    <Form.Control.Feedback type="invalid">{f.errors.favStreet}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col xs={12} md={3}>
                                <FloatingLabel controlId="favName" label="Numer domu/lokal" className="my-2">
                                    <Form.Control type="text" name="favHomeNo" value={f.values.favHomeNo!} onChange={f.handleChange} isValid={f.touched.favHomeNo&&!f.errors.favHomeNo} isInvalid={f.touched.favHomeNo&&f.errors.favHomeNo?true:false} />
                                    <Form.Control.Feedback type="invalid">{f.errors.favHomeNo}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        </>
                    :null}
                    <div className="d-grid mt-5">
                        <Button type="submit" disabled={!f.values.UserAgree}>Kupuję! przelewy24</Button>
                    </div>
                    {state.UserAgree===false?
                     <div className="form-text">* Oświadczenie, że zapoznałem/am się z treścią regulaminu i polityki prywatności jest wymagane.</div>
                    :null}
                </div>
                </Form>
            </Col>
        </Row>
    </>
}

function ProductPayStep({state,setState}:{state:stateProductIndex,setState:Dispatch<SetStateAction<stateProductIndex>>}){
    useEffect(() => {
        setTimeout(() => {
            window.location.href=state.PayLink!;
        }, 2000)
      }, [])

    return <>
        <Helmet>
            <title>Nasza Oferta - Wybierz specjalizację oraz pakiet dostępu</title>
        </Helmet>
        <Row>
            <Col xs={12}>
                <div className="main-title">
                    <h1 className="text-primary">Przekierowanie do płatności...</h1>
                </div>
            </Col>
            <Col xs={12}>
                <div className="main-content">
                    <h2>Dziękujemy za zamówienie!</h2>
                    <p>Za chwilę nastąpi przekierowanie do bramki płatności, jesli nie zostaniesz przekierowany skorzystaj z przycisku poniżej.</p>
                    <Button onClick={()=>window.location.href=state.PayLink!}>Przejdź do bramki płatności</Button>
                </div>
            </Col>
        </Row>
    </>
}

function ProductIndex(){
    let [state,setState]                = useState<stateProductIndex>({productsList:null,selectedSpecID:null,UserFavNeed:false,
                                                                    Step:'start',UserAgree:false,UserSelectedProductPriceID:null,UserNewsletterAgree:false,
                                                                    UserSelectedProductProceRow:null,UserSelectedProductRow:null,PayLink:null});

    if (state.Step==='start'){
        return <>
            <ProductSelectSpecStep state={state} setState={setState} />
        </>
    }
    if (state.Step==='agrees'){
        return <ProductSelectAgreeStep state={state} setState={setState} />
    }
    if (state.Step==='pay'){
        return <ProductPayStep state={state} setState={setState} />
    }

    return <>
        <Helmet>
            <title>Wybierz plan - nasza oferta</title>
        </Helmet>
        <div className="d-flex align-items-center">
            <h1 className="my-3 text-primary">Nasza oferta</h1>
        </div>
        <Row>
            
        </Row>
    </>
}

export default ProductIndex;