import { Dispatch, SetStateAction, useState } from "react";
import { RecordQuestionDescription } from "../../Type/RecordQuestionDescription";
import { Alert, Card, CardBody } from "react-bootstrap";
import { QuestionV2ElementTitle, QuestionV2ElementUserTitle } from "./Element/Title";
import { recordQuestionV2, recordQuestionV2Description } from "./Record";
import { QuestionV2ElementContent } from "./Element/Content";
import { QuestionV2ElementOptions } from "./Element/Options";
import axios from "axios";
import { QuestionV2ElementTitleButtons } from "./Element/Buttons";
import QuestionV2ElementComments from "./Element/Comments";
import { MdDangerous } from "react-icons/md";

export interface stateQuestionV2{
    showAbout:boolean;
    showComments:boolean;
    showAnswer:boolean;
    showProposal?:boolean;
    resetAnswer:boolean;
    isLoading:string|null;
}

export interface propsQuestion{
    rowQuestion:recordQuestionV2|null|'error'|'access-denied',
    // rowAbout?:recordQuestionV2Description|null,
    state:stateQuestionV2,
    setState:Dispatch<SetStateAction<stateQuestionV2>>,
    disableCardTitle?:boolean;
    mode?:'exam'|'nauka'|'result'|'userQuestion';           // undefined to będzie odczyt 
    showAnswerButton?:boolean;
    onOptionClick?:(answer:string|null,questionID:number) => void,

    onNext?:()=>void,
    disableNextBtn?:boolean,
    onPrev?:()=>void,
    disablePrevBtn?:boolean,

    onExamClose?:()=>void,

    disableOptionsDisplay?:true;
    showFinishBtn?:boolean;
    disableAnswerClean?:boolean;
    disableCommentsBtn?:boolean;
    // onNextClick:React.MouseEvent<Element>
}

export function QuestionV2({disableAnswerClean,disableCommentsBtn,rowQuestion,state,setState,disableCardTitle,showAnswerButton,mode,disableOptionsDisplay,onOptionClick,onNext,onPrev,disableNextBtn,disablePrevBtn,showFinishBtn,onExamClose}:propsQuestion){
    // let [ questionState, setQuestionState ]         = useState<stateQuestionV2>({showAbout:false,showComments:false,isLoading:false});
    let [ questionAbout, setQuestionAbout ]             = useState<recordQuestionV2Description|null>(null);
    let [ loading, setLoading ]                         = useState<string|null>(null);
    // let [showComments,setShowComments]                  = useState<boolean>(false);
// console.log(rowQuestion);
    if (rowQuestion==='access-denied'){
        return <>
        <Alert variant="danger">
            <p>Nie masz dostępu do tego pytania.</p>
        </Alert>
        </>
    }
    if (rowQuestion==='error'){
        return <>
        <Alert variant="danger">
            <p>Wystąpił błąd przy pobieraniu pytania.</p>
        </Alert>
        </>
    }

    if (rowQuestion===null) return <></>
    if ( questionAbout !== null ){
        if (questionAbout.QuestionID !== rowQuestion.ID){
            setQuestionAbout(null);
            return <></>
        }
    }

    let questionID                                      = rowQuestion.ID;

    if (questionAbout===null && state.showAbout===true ){
        if (state.isLoading===null){
            setState({...state,isLoading:'description'});
            axios.post(`/v2/pytanie/get-description`,{id:questionID,UserAnswerID:rowQuestion.UserExamAnswerID??null}).then((r) => {
                setQuestionAbout(r.data.data);
                setState({...state,isLoading:null});
            }, (error) => {
                setState({...state,isLoading:null,showAbout:false});
            });
        }
    }

    if (state.resetAnswer===true){
        if (loading===null){
            // setState({...state,isLoading:'reset'});
            setLoading('reset');
            axios.post(`/v2/pytanie/reset-answer`,{id:questionID,UserAnswerID:rowQuestion.UserExamAnswerID??null}).then((r) => {
                if (onOptionClick)
                    onOptionClick(null,questionID);
                setLoading(null);
                setState({...state,resetAnswer:false});
            }, (error) => {
                setLoading(null);
                setState({...state,resetAnswer:false});
            });
        }
    }

    function questionOptionClick(option:string|null,questionID:number){
        if (rowQuestion===null) return;
        if (rowQuestion.UserExamAnswerID!=undefined && rowQuestion.UserExamAnswerID!=null&&rowQuestion.UserExamType){
            setState({...state,isLoading:'submit-answer'});
            if (state.isLoading===null){
                axios.post(`/v2/pytanie/submit-answer`,{id:questionID,QuestionOrder:rowQuestion.UserExamCurrentQuestionOrder,UserAnswerID:rowQuestion.UserExamAnswerID??null,UserAnswer:option,UserExamID:rowQuestion.UserExamID}).then((r) => {
                    setState({...state,isLoading:null});
                }, (error) => {
                    setState({...state,isLoading:null,showAbout:false});
                });
            }
        }
        if (onOptionClick)
            onOptionClick(option,questionID);
    }

    return <>
        <Card>
            <CardBody>
                {mode==='userQuestion'?<QuestionV2ElementUserTitle rowQuestion={rowQuestion} state={state} setState={setState} />
                    :<QuestionV2ElementTitle rowQuestion={rowQuestion} state={state} setState={setState} />}

                <QuestionV2ElementContent rowQuestion={rowQuestion} rowAbout={questionAbout} state={state} />
                {rowQuestion.CorrectAnswer==='X'?<Alert variant="danger" className="my-4"><MdDangerous size={64} /> <span className="fs-5">Pytanie niezgodne z aktualną wiedzą medyczną. Nie ma prawidłowej odpowiedzi.</span></Alert>:null}
            </CardBody>
            {disableOptionsDisplay===undefined?
                <QuestionV2ElementOptions rootLoading={loading} disableAnswerClean={disableAnswerClean} rowQuestion={rowQuestion} rowAbout={questionAbout} state={state} setState={setState} mode={mode} onOptionClick={questionOptionClick} />
            :null}
            <QuestionV2ElementTitleButtons rowQuestion={rowQuestion} rowAbout={questionAbout}
                                           state={state} setState={setState} showAnswerButton={showAnswerButton} mode={mode} onOptionClick={questionOptionClick} 
                                           onNext={onNext} onPrev={onPrev}
                                           disableNextBtn={disableNextBtn} disablePrevBtn={disablePrevBtn}
                                           showFinishBtn={showFinishBtn}
                                           onExamClose={onExamClose}
                                           disableCommentsBtn={disableCommentsBtn}
                                           />
        </Card>

        <QuestionV2ElementComments questionID={rowQuestion.ID} state={state} setState={setState} />
    </>
}