import { Accordion, AccordionBody, Alert, Button, Card, CardBody, CardText, CardTitle, Col, Nav, Row } from 'react-bootstrap';
import { useNavigate, useLoaderData } from "react-router-dom"; 
import { RowExamResult } from '../Type/RowExamResult';
import { Helmet } from 'react-helmet-async';
import Moment from 'react-moment';
import 'moment/locale/pl'
import ProgressBar from 'react-bootstrap/ProgressBar';
import { MdList } from 'react-icons/md';
import { RecordResultCategoryStat } from '../Type/RecordResultCategoryStat';
import { RowUserExamQuestion } from '../Type/RowUserExamQuestion';
import { RowExamNew } from '../Type/RowExamNew';
import { recordQuestionV2 } from '../Componets/QuestionV2/Record';
import { QuestionV2, stateQuestionV2 } from '../Componets/QuestionV2/Question';
import { useState } from 'react';

function CategoryInfo({category}:{category:RecordResultCategoryStat}){
    let score = ((category.Correct ?? 0) * 100.0) / category.Total;
    return <>
        <dt className="text-primary-emphasis fs-6 col-12 col-md-3 mb-1">{category.Name}</dt>
        <dd className="col-12 col-md-9">
        <ProgressBar>
            <ProgressBar variant='success' now={score} label={`${category.Correct ?? 0} / ${category.Total}`} key="1" />
            {score < 100 ?
                <ProgressBar variant="danger" key="2" now={100-score} label={`${category.Total-(category.Correct ?? 0)} / ${category.Total}`} />
                : null }
        </ProgressBar>
        </dd>
        </>
}

function DrawQuestion({q,e}:{q:recordQuestionV2,e:RowExamNew}){
    let [state,setState] = useState<stateQuestionV2>({isLoading:null,resetAnswer:false,showAbout:false,showAnswer:false,showComments:false});
    return <>
        <QuestionV2 rowQuestion={q} state={state} setState={setState} mode="result" disableCommentsBtn={true} />
    </>
}


export default function MyExamResultPage(){
    let examData = useLoaderData() as RowExamResult;
    let navigate = useNavigate();
    let examTime = (new Date(examData.EndTime)).getTime() - (new Date(examData.BeginTime)).getTime();
    let invalidQuestions = ((examData.QuestionCount - examData.ValidQuestionCount));
    let score           = ((examData.CorrectAnswerCount * 100.0) / examData.ValidQuestionCount) as number;
    let answersNow      = ((examData.AnswerCount * 100.0) / examData.ValidQuestionCount) as number;
    let wrongNow        = (((examData.AnswerCount - examData.CorrectAnswerCount) * 100.0) / examData.ValidQuestionCount) as number;

    // console.log({an:typeof answersNow});
    if (answersNow>100)
        answersNow=100;

    return <>
    <Helmet>
        <title>{examData.Name} - podsumowanie</title>
    </Helmet>
    <h1 className="my-3 text-primary">{examData.Name} - podsumowanie</h1>
    <Row className="mt-3">
        <Col sm={6} lg={3}>
            <Card className="mb-1">
                <CardBody>
                    <CardTitle>Czas rozpoczęcia</CardTitle>
                    <CardText>{examData.BeginTime}</CardText>
                </CardBody>
            </Card>
        </Col>
        <Col sm={6} lg={3}>
            <Card className="mb-1">
                <CardBody>
                    <CardTitle>Czas zakończenia</CardTitle>
                    <CardText>{examData.EndTime}</CardText>
                </CardBody>
            </Card>
        </Col>
        <Col sm={6} lg={3}>
            <Card className="mb-1">
                <CardBody>
                    <CardTitle>Odpowiedzi/Liczba pytań</CardTitle>
                    <CardText>{examData.AnswerCount}/{examData.QuestionCount}
                        {invalidQuestions>0?<><small> (unieważnione {invalidQuestions})</small></>:null}
                    </CardText>
                </CardBody>
            </Card>
        </Col>
        <Col sm={6} lg={3}>
            <Card className="mb-1">
                <CardBody>
                    <CardTitle>Czas rozwiązywania</CardTitle>
                    <CardText><Moment duration={examData.BeginTime} date={examData.EndTime} locale="pl" /></CardText>
                </CardBody>
            </Card>
        </Col>
        <Col xs={12}>
            <h2 className="my-3 text-primary">Wynik</h2>
            <Row>
                <Col xs={12} md={4}>
                    <ProgressBar style={{height:"30px"}} variant="info" now={answersNow} label={`Odpowiedzi ${answersNow.toFixed(2)}%`} />
                </Col>
                <Col xs={12} md={8}>
                    <ProgressBar style={{height:"30px"}}>
                        <ProgressBar variant="success" now={score} label={`Poprawne odpowiedzi ${score.toFixed(2)}%`} />
                        <ProgressBar variant="danger" now={wrongNow} label={`${wrongNow.toFixed(2)}%`} />
                    </ProgressBar>
                </Col>
            </Row>
        </Col>
        <Col xs={12}>
            <Accordion className="my-3">
            {examData.Categories && examData.Categories?.length > 0 ?
                
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <span className="text-primary">Kategorie</span> 
                        <span className="ms-2 text-primary-emphasis fs-6">Pytania w teście zostały podzielone na <span className='text-primary text-bold'>{examData.Categories.length}</span> kategorii.</span>
                        
                    </Accordion.Header>
                    <Accordion.Body key="categories-body">
                        <dl className="row">
                            {examData.Categories.map(function(i,idx){
                                return <CategoryInfo category={i} key={`exam-category-${idx}`} />
                            })}
                        </dl>
                    </Accordion.Body>
                </Accordion.Item>
                :
                null
            }
            {examData.CorrectAnswerQuickest ? <>
                <Accordion.Item eventKey="1">
                    <Accordion.Header><span className="text-primary">Najszybsza prawidłowa odpowiedź</span></Accordion.Header>
                    <Accordion.Body>
                        <DrawQuestion q={examData.CorrectAnswerQuickest as recordQuestionV2} e={examData} />
                    </Accordion.Body>
                </Accordion.Item>
            </> : '' }
            {examData.CorrectAnswerLatest ? <>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>
                        <span className="text-primary">Złoty strzał?</span>
                        <span className="ms-2 text-primary-emphasis fs-6">Pytanie, które zabrało najwięcej czasu i odpowiedź była poprawna.</span>
                    </Accordion.Header>
                    <Accordion.Body>
                        <DrawQuestion q={examData.CorrectAnswerLatest as recordQuestionV2} e={examData} />
                    </Accordion.Body>
                </Accordion.Item>
            </> : '' }
            {examData.WrongAnswerQuickest ? <>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>
                        <span className="text-primary">Najszybsza błędna odpowiedź</span>
                        <span className="ms-2 text-primary-emphasis fs-6">Pytanie, na które bardzo szybko została odzielona błędna odpowiedź.</span>
                    </Accordion.Header>
                    <Accordion.Body>
                        <DrawQuestion q={examData.WrongAnswerQuickest as recordQuestionV2} e={examData} />
                    </Accordion.Body>
                </Accordion.Item>
            </> : '' }
            {examData.WrongAnswerLastest ? <>
                <Accordion.Item eventKey="4">
                    <Accordion.Header>
                        <span className="text-primary">Najtrudniejsze pytanie dla Ciebie</span>
                        <span className="ms-2 text-primary-emphasis fs-6">To pytanie zabrało najwięcej czasu i odpowiedź była błędna.</span>
                    </Accordion.Header>
                    <Accordion.Body>
                        <DrawQuestion q={examData.WrongAnswerLastest as recordQuestionV2} e={examData} />
                    </Accordion.Body>
                </Accordion.Item>
            </> : '' }
            </Accordion>
            <Nav fill variant="underline" className="mt-3 mb-5">
                <Nav.Item><Nav.Link className="fw-bold" onClick={() => navigate(`/app/egzamin-przegladaj/${examData.ID}`)}><MdList /> Przeglądaj pytania</Nav.Link></Nav.Item>
            </Nav>
        </Col>
    </Row>
    </>;
}