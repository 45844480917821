import { useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { RowExam } from "../Type/RowExam";
import axios from 'axios';
import { AppResponse } from '../Type/AppResponse';
import { useNavigate } from 'react-router-dom';
import { Form, InputGroup } from 'react-bootstrap';
import DrawSpinner from './DrawSpinner';

type OpenExamCreateModalProps = {
  rowExam: RowExam|null;
  show:boolean,
  onClose:() => void;
};
function OpenExamCreateModal(props:OpenExamCreateModalProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  let inputNameNauka              = useRef<HTMLInputElement|null>(null);
  let inputNameEgzamin            = useRef<HTMLInputElement|null>(null);
  const navigate = useNavigate();

  function openNewExam(exam:RowExam|null,type:string){
    setIsLoading(true);
    let inputName = null;
    if (type==='nauka')
      inputName=inputNameNauka.current?inputNameNauka.current.value:null;
    else
      inputName=inputNameEgzamin.current?inputNameEgzamin.current.value:null;

    axios.post<AppResponse>('/egzamin/new', {
      examID : exam?.ID,
      examName:inputName,
      examType:type
    })
    .then(function (response) {
      navigate(`/app/egzamin/${response.data.id}`)
      setIsLoading(false);
      
    })
    .catch(function (error) {
      console.log(error);
      setIsLoading(false);
    });
    /*
    fetch('/egzamin/new',{
      method: 'POST',
      body: JSON.stringify({
        examID : exam?.ID,
        examType:type
      })
    })
      .then((res) => {
          return res.json();
      })
      .then((data) => {
          setIsLoading(false);
          console.log(data);
      }).catch(error => {
        setIsLoading(false);
      });*/
  }

  if (isLoading){
    return <>
    <Modal
      show={props.show}
      onHide={props.onClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Rozpocznij egzamin {props.rowExam?props.rowExam.SessionSeasonName:null}/{props.rowExam?props.rowExam.SessionYear:null}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DrawSpinner />
      </Modal.Body>
    </Modal>
    </>;
  }

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Rozpocznij egzamin {props.rowExam?props.rowExam.SessionSeasonName:null}/{props.rowExam?props.rowExam.SessionYear:null}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col><h2 className="text-center text-secondary">Nauka</h2>
              <p>Tryb <strong>nauka</strong> służy do nauki, po udzieleniu odpowiedzi na każde pytanie z egzaminu zostaną wyświetlone omówienia i źródła, które warto przeczytać.</p>
            </Col>
            <Col><h2 className="text-center text-secondary">Egzamin</h2>
              <p>Jeśli chcesz się sprawdzić możesz skorzystać z trybu <strong>egzamin</strong>, w nim omówienia pytań i odpowiedzi nie zostaną zaprezentowane, nastąpi natychmiastowe przejście do kolejnego pytania. </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <InputGroup>
                <Form.Control maxLength={40} ref={inputNameNauka} disabled={isLoading} type="text" placeholder="Nazwa..." aria-label="Wprowadź nazwę dla egzaminu..." />
                <Button disabled={isLoading} onClick={() => openNewExam(props.rowExam,'nauka')}>
                  <DrawSpinner show={isLoading} size="sm" />
                  Nauka
                </Button>
              </InputGroup>
            </Col>
            <Col>
              <InputGroup>
                <Form.Control maxLength={40} ref={inputNameEgzamin} disabled={isLoading} type="text" placeholder="Nazwa..." aria-label="Wprowadź nazwę dla egzaminu..." />
                <Button disabled={isLoading} onClick={() => openNewExam(props.rowExam,'egzamin')}>
                  <DrawSpinner show={isLoading} size="sm" />
                  Egzamin
                </Button>
              </InputGroup>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onClose}>Powrót</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default OpenExamCreateModal;