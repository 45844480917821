import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import DrawSpinner from "../Componets/DrawSpinner";

interface paymentState{
    message:string|null;
    code:number|null;
    isLoading:boolean;
    display:string|null;
    timeoutId:number|null;
    count:number;
    authRefresh:boolean;
}

function ProductPaymentDone(){
    let { sessionID }       = useParams();
    let [ state, setState ] = useState<paymentState>({message:null,isLoading:false,code:null,display:null,timeoutId:null,count:5,authRefresh:false});

    if (state.code===1){
        if (state.authRefresh===true){
            window.location.href="/app/";
        }
    }
    

    useEffect(function(){
        // console.log('useEffect');
        if (sessionID){
            setTimeout(function(){
                if ((state.isLoading===false && state.message===null && state.count) ||
                    (state.isLoading===false && state.code===0 && state.count )){
                axios.post(`/platnosc/check/${sessionID}`,{}).then((r)=>{
                    setState({message:r.data.message,code:r.data.code,isLoading:false,display:r.data.message,timeoutId:null,count:state.count-1,authRefresh:r.data.authRefresh});
                }, (e) => {
                    let msg = 'Nie udało się pobrać statusu transakcji z serwera.';
                    setState({message:msg,code:500,isLoading:false,display:msg,timeoutId:null,count:state.count-1,authRefresh:false});
                }); 
            }
            },4000);
        }
    },[state]);


    if (!sessionID)
        return <>
        <Helmet>
            <title>Brak informacji o płatności</title>
        </Helmet>
        <Row>
            <Col xs={12}>
                <div className="main-title"></div>
            </Col>
        </Row>
    </>

    return <>
        <Helmet>
            <title>Dziękujemy za płatność!</title>
        </Helmet>
        <Row>
            <Col xs={12}>
                <div className="main-title">
                    <h1 className="text-primary">Dziękujemy za płatność!</h1>
                </div>
            </Col>
            <Col xs={12}>
                <div className="main-content">
                    <p>Jak tylko otrzymamy potwierdzenie od dostawy płatności, Twój pakiet zostanie aktywowany!</p>
                    {state.code!==500 && state.count>0 ?
                        <Button variant="primary" disabled={state.code!==500 && state.count>0}>
                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                            <span className="visually-hidden">Loading...</span>
                            &nbsp; {state.display?state.display:'Pobieranie statusu transakcji z serwera....'}
                        </Button>
                        :
                        <Button variant="primary" onClick={()=>setState({...state,count:2,isLoading:false,message:null,code:null})}>
                            Spróbuj ponownie!
                        </Button>
                    }
                </div>
            </Col>
        </Row>
    </>
}

export default ProductPaymentDone;