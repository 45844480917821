import { Dispatch, SetStateAction } from "react";
import { Alert, Button, Col, Row, ToggleButton } from "react-bootstrap";
import { MdArrowLeft, MdArrowRight, MdCheck, MdCheckBoxOutlineBlank, MdInfo, MdStar } from "react-icons/md";
import { ExamPageGeneratorV2State } from "../PageGenerator";

export function ExamPageGeneratorV2StepSource({state,setState}:{state:ExamPageGeneratorV2State,setState:Dispatch<SetStateAction<ExamPageGeneratorV2State>>}){

    let nextStep = 'session';
    if (state.useCEM===false && state.useLib===true)
        nextStep = 'categories';
    return <>
        <Row>
            <Col xs={12}>
                <div className="main-content">
                    <Alert variant="info">
                        <p><MdInfo /> <strong>Generator V2</strong> różni się od zwykłego Generatora przede wszystkim zmianami w interfejsie (dodane filtry w kategoriach, tagach, sesjach).</p><p>Dodatkową funkcją jest możliwość mieszania pytań między różnymi specjalizacjami jeśli masz dostęp do więcej niż jednej.</p>
                        <p className="mb-0"><MdStar /> W pierwszej wersji generator pomijał pytania <b>bez kategorii i tagów</b>, w V2 jest to poprawione i takie pytania są umieszone w <b>bez kategorii</b> oraz <b>bez tagów</b>.</p>
                    </Alert>
                    <p>Pytania w bazie mamy podzielone na CEM oraz autorskie. Z jakich pytań chcesz korzystać?</p>
                    <div className="d-flex">
                        <ToggleButton className="me-md-2 flex-fill" id="tgbSourceCEM" type="checkbox" variant="outline-primary" checked={state.useCEM} onChange={(e)=>setState({...state,useCEM:e.target.checked})} value="cem">
                            {state.useCEM ? <MdCheck size={32} /> : <MdCheckBoxOutlineBlank size={32} />}
                            <span className="ms-1">Baza CEM</span>
                        </ToggleButton>
                        <ToggleButton className="me-md-2 flex-fill" id="tgbSourceLib" type="checkbox" variant="outline-primary" checked={state.useLib} onChange={(e)=>setState({...state,useLib:e.target.checked})} value="cem">
                            {state.useLib ? <MdCheck size={32} /> : <MdCheckBoxOutlineBlank size={32} />}
                            <span className="ms-1">Baza pytań autorskich</span>
                        </ToggleButton>
                    </div>

                    <div className="d-flex mt-3">
                        {state.userHasMoreSpecs ? 
                            <Button variant="secoundary" onClick={()=>setState({...state,page:'spec'})}><MdArrowLeft /> powrót</Button>
                        :null}
                        <Button className="flex-fill" disabled={(state.useCEM===false && state.useLib===false)} 
                            onClick={() => setState({...state,page:nextStep,sessions:null,categories:null,tags:null,sessionsChecks:[],tagsChecks:[],categoriesChecks:[]})}>Przejdź dalej <MdArrowRight /></Button>
                    </div>
                </div>
            </Col>
        </Row>
    </>
}