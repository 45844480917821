import axios from "axios";
import { Dispatch, SetStateAction, useRef, useState } from "react";
import { Button, FloatingLabel, Form, Offcanvas } from "react-bootstrap";
import DrawSpinner from "../../DrawSpinner";
import { stateQuestionV2 } from "../Question";
import { FaCrown, FaReply } from "react-icons/fa6";
import { Helmet } from "react-helmet-async";

interface UserCommentRecord{
    ID:number;
    QuestionID:number;
    ParentID:number|null;
    UserID:number;
    UserName:string;
    Created:EpochTimeStamp,
    Updated:EpochTimeStamp|null;
    Content:string;
    Childs:UserCommentRecord[]|null;
}


function ExamQuestionCommentItem({comment,subComments,setReply,depth}:
    {comment:UserCommentRecord,subComments:UserCommentRecord[]|null|undefined,depth:number,setReply:(parent:number)=>void|null})
{
    let parentClass = depth===0?'pb-1':'pb-1';
    if (depth===1)
        parentClass = 'pb-2 ps-3 pt-1';
    if (comment.UserID===2){
        parentClass += ' bg-warning-subtle';
    }

    return <>
        <div className={parentClass}>
            <div className="d-flex align-items-center mb-1">
                <div className=" flex-grow-1">{comment.UserID===2?<FaCrown className="me-1" />:null}<b>{comment.UserName}</b> - <small>{comment.Created}</small></div>
                {depth===0?<Button onClick={()=>setReply(comment.ID)} className="align-self-end" size="sm" variant="outline-primary">odpowiedz <FaReply /></Button>:null}
            </div>
            <div className="comment-content" dangerouslySetInnerHTML={{__html:comment.Content}} />
        </div>
        {subComments !== null ? subComments?.map(function(i){
            return <ExamQuestionCommentItem key={`comment-${i.ID}`} comment={i} setReply={setReply} depth={1} subComments={null} />
        }) :null}
    </>
}

function QuestionV2ElementComments({questionID,state,setState}:{questionID:number,state:stateQuestionV2,setState:Dispatch<SetStateAction<stateQuestionV2>>}){
    let [edit,setEdit]              = useState<boolean>(false);
    let [comments,setComments]      = useState<Array<UserCommentRecord>|null>(null);
    let [submit,setSubmit]          = useState<boolean>(false);
    let [loading, setLoading]       = useState<boolean>(false);
    let parentComment               = useRef<HTMLInputElement>(null);
    let userComment                 = useRef<HTMLTextAreaElement>(null);

    if (state.showComments===false) return <></>

    if (comments===null && loading===false){
        setLoading(true);
        axios.post(`/pytanie/komentarze`,{
            id:questionID
        }).then((r)=>{
            setLoading(false);
            setComments(r.data.data);
        }, (e) => {
            setState({...state,showComments:false});
            setLoading(false);
        });
    }

    // console.log(submit);
    if (submit&&loading===false){
        setLoading(true);
        if ((userComment.current?.value?.length??0)>0){
            axios.post(`/pytanie/komentarz`,{
                questionID:questionID,
                parentComment:parentComment.current?.value,
                content:userComment.current?.value
            }).then((response) => {
                setLoading(false);
                setSubmit(false);
                if (parentComment.current)
                    parentComment.current.value="";
                if (userComment.current)
                    userComment.current.value="";
                let newItem = response.data.data as UserCommentRecord;
                // let 
                setComments([...comments??[],newItem]);
                // setState({...state,templateCategories:response.data.data.categories,templateSessions:response.data.data.sessions});
            }, (error) => {
                setLoading(false);
                setSubmit(false);
            });
        }else{
            setLoading(false);
            setSubmit(false);
        }
    }

    function setEditForm(id:number|null){
        setEdit(true);
        userComment.current?.focus();
        if (id!==null){
            if (parentComment.current)
                parentComment.current.value    = id.toString();
        }
    }

    return <>
    {/* <Helmet>
        <body className="pb-50" />
    </Helmet> */}
        <Offcanvas show={state.showComments} onHide={()=>setState({...state,showComments:false})} placement="bottom" className="h-50" keyboard={false} scroll={true} backdrop={false}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Dyskusja</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Form className="mb-4" autoComplete="off">
                    <input type="hidden" defaultValue={questionID} name="QuestionID" />
                    <input type="hidden" ref={parentComment} name="ParentCommentID" />
                    <FloatingLabel controlId="commentContent" label="Twój komentarz">
                        <Form.Control disabled={submit} ref={userComment} autoComplete="off" as="textarea" placeholder="Dołącz do dysuksji na temat pytania..."
                            onFocus={()=>setEditForm(null)}
                        // style={{height:(edit?'100px':'auto')}}
                         />
                    </FloatingLabel>
                    {
                        edit ? <div className="my-2 d-grid"><Button disabled={submit} variant="primary" onClick={()=>setSubmit(true)}>
                            <DrawSpinner show={submit} /> Dodaj
                            </Button></div> : null
                    }
                </Form>
                {
                    comments !== null ? comments.filter(x=>x.ParentID===null).map(
                        function(i){
                            return <ExamQuestionCommentItem key={`comment-${i.ID}`} setReply={setEditForm} comment={i} depth={0} subComments={comments!.filter(x=>x.ParentID===i.ID)} /> 
                        } ) 
                    : null
                }
            </Offcanvas.Body>
        </Offcanvas>
      </>
}

export default QuestionV2ElementComments;