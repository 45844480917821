import { Helmet } from 'react-helmet-async';
import { Link, useParams } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import { MouseEventHandler, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RowExamNew } from "../Type/RowExamNew";
import { Button, Col, ListGroup, ListGroupItem, Nav, Placeholder, Row } from "react-bootstrap";
import axios from "axios";
import { RowExamIndex } from "../Type/RowExamIndex";
import { RowUserExamQuestion } from "../Type/RowUserExamQuestion";
import {  MdQuestionMark } from "react-icons/md";
import QuestionLoadingPlaceholder from '../Componets/QuestionLoadingPlaceholder';
import { FaAngleLeft, FaAngleRight, FaCheck, FaXmark } from 'react-icons/fa6';
import { stateQuestion } from '../Componets/Question/State';
import { QuestionV2, stateQuestionV2 } from '../Componets/QuestionV2/Question';
import { recordQuestionV2 } from '../Componets/QuestionV2/Record';

function MyExamResultPreviewPagePaginator({examID,current}:{examID:number,current:number}){
    const [examIndexData,setExamIndexData]          = useState<Array<RowExamIndex>|null>(null);
    let navigate = useNavigate();
    
    if (examIndexData===null){
        axios.get(`/egzamin/get-exam-index/${examID}`).then((response) => {
            setExamIndexData(response.data.data);
        }, (error) => {
            // console.log(error);
        });
        return null;
    }

    return <>
        {/* <Pagination className="text-center justify-content-center"> */}
            {/* <Pagination.First title="Skok do pierwszego pytania" onClick={() => navigate(`/app/egzamin-przegladaj/${examID}/${firstQuestion.Order}`)} /> */}
            {examIndexData.map(function(item,idx){
                let type = item.Answer===item.CorrectAnswer?'outline-success':'outline-danger';
                if (current===item.Order)
                    type = item.Answer===item.CorrectAnswer?'success':'danger';
                return <Button size="sm" className="m-1" variant={type} onClick={() => navigate(`/app/egzamin-przegladaj/${examID}/${item.Order}`)} key={`page-${idx}`}>
                    {item.Order} {item.Answer===item.CorrectAnswer?<FaCheck />:<FaXmark />}
                </Button>;
            })}
            {/* <Pagination.Last title="Skok do ostatniego pytania" onClick={() => navigate(`/app/egzamin-przegladaj/${examID}/${lastQuestion.Order}`)} /> */}
        {/* </Pagination> */}
    </>
}

function MyExamResultPreviewPageIndexView({examID}:{examID:number}){
    const [examIndexData,setExamIndexData]          = useState<Array<RowExamIndex>|null>(null);
    let navigate = useNavigate();
    
    if (examIndexData===null){
        axios.get(`/egzamin/get-exam-index/${examID}`).then((response) => {
            setExamIndexData(response.data.data);
        }, (error) => {
            // console.log(error);
        });
    }
        
    return <>
        <h2 className="my-3 text-primary">Index <span className="d-none d-lg-inline">pytań</span></h2>
        {examIndexData === null ?
            <Spinner key="question-index-loader" animation="border" role="status" className='my-5'><span className="visually-hidden">Loading...</span></Spinner>
            :
            <ListGroup as="ul" variant="flush" key="question-index-list-group">
                {examIndexData.map(function(item,idx){
                    return <ListGroupItem key={item.ID} variant={item.CorrectAnswer===item.Answer?'success':'danger'} action onClick={() => navigate(`/app/egzamin-przegladaj/${examID}/${item.Order}`)}>
                            <Button variant={item.CorrectAnswer===item.Answer?'success':'danger'} className="text-white">{item.Order}</Button> 
                            {item.Answer !== null ? 
                                <span className="text-white badge text-bg-primary ms-2 d-none d-md-inline">{item.Answer}</span> 
                                : 
                                <span className="badge text-bg-info ms-2 text-dark d-none d-md-inline">Brak odpowiedzi</span> 
                            }
                         </ListGroupItem>;
                })}
            </ListGroup>
        }
    </>
}

function MyExamResultPreviewPageQuestionNav({rowQuestion,onClickShowMoreAboutQuestion,examID,data}:
        {rowQuestion:RowUserExamQuestion,examID:number,onClickShowMoreAboutQuestion:MouseEventHandler<HTMLElement>,data:stateQuestion}){    
    let prevQuestionLink = null;
    let nextQuestionLink = null;
    let isMore = false;
    if (rowQuestion.PreviousOrder!==null)
        prevQuestionLink = <Nav.Item><Nav.Link as={Link} to={`/app/egzamin-przegladaj/${examID}/${rowQuestion.PreviousOrder}`}><FaAngleLeft /><span className="d-none d-md-inline"> Poprzednie pytanie</span></Nav.Link></Nav.Item>
    else
        prevQuestionLink = <Nav.Item><Nav.Link disabled><FaAngleLeft /><span className="d-none d-md-inline"> Poprzednie pytanie</span></Nav.Link></Nav.Item>

    if (rowQuestion.NextOrder!==null)
        nextQuestionLink = <Nav.Item><Nav.Link as={Link} to={`/app/egzamin-przegladaj/${examID}/${rowQuestion.NextOrder}`}><span className="d-none d-md-inline">Kolejne pytanie</span> <FaAngleRight className="fw-bold" /></Nav.Link></Nav.Item>
    else
        nextQuestionLink = <Nav.Item><Nav.Link disabled><span className="d-none d-md-inline">Kolejne pytanie</span> <FaAngleRight className="fw-bold" /></Nav.Link></Nav.Item>

    
    if (rowQuestion.IsMore===true){
        isMore=true;
    }else{
        if (rowQuestion.Options?.some(a => a.IsMore === true))
            isMore=true;
    }
        
    return <>
        <Nav fill variant="underline" className="mt-3">
            {prevQuestionLink}
            {isMore === true ? 
            <Nav.Item><Nav.Link className="position-relative" title="Pokaź omówienie do tego pytania" onClick={onClickShowMoreAboutQuestion}><MdQuestionMark /> Pokaż omówienie
                {/* {isQuestionMoreLoading===true ? <Spinner animation="border" role="status" size="sm" className='mx-5'><span className="visually-hidden">Loading...</span></Spinner> : null } */}
            </Nav.Link></Nav.Item> : null }
            {nextQuestionLink}
        </Nav>
    </>
}

function MyExamResultPreviewPagePlaceholder(){
    return <>
        <Row>
            <Col lg="2" md="2" xs="4"></Col>
            <Col lg="10" md="10" xs="8">
                <h1 className="my-3 text-primary"><Placeholder xs={10} animation='glow' /></h1>
                <QuestionLoadingPlaceholder />
            </Col>
        </Row>
    </>
}

export default function MyExamResultPreviewPage(){
    let [ loading, setLoading ]                     = useState<string|null>(null);
    let [ error, setError ]                         = useState<string|null>(null);
    let [ question, setQuestion ]                   = useState<recordQuestionV2|null|'error'|'access-denied'>(null);
    let [ questionState, setQuestionState ]         = useState<stateQuestionV2>({showAbout:false,showComments:false,isLoading:null,showAnswer:false,resetAnswer:false});
    let [ examData, setExamData ]                   = useState<RowExamNew|null>(null);
    const [examIndexData,setExamIndexData]          = useState<Array<RowExamIndex>|null>(null);
    let { examID, questionID }                      = useParams();
    let navigate                                    = useNavigate();
    if (!questionID) questionID                     = '1';
    let iQuestionOrder                              = parseInt(questionID!);

    useEffect(()=>{
        // if (question!==null){
        //     
        //     setQuestion(null);
        // }

        setQuestion(null);
        setQuestionState({...questionState,showAnswer:false,showComments:false,showAbout:false});
        axios.post(`/v2/pytanie/get-exam-question`,
            {UserExamID:examID,Order:questionID,QuestionID:null}
        ).then((response) => {
            if (response.data.access==='Brak dostępu.'){
                setQuestion('access-denied');
            }
            else
                setQuestion(response.data.data);
        }, (error) => {
            setError('Nie udało się pobrać indeksu pytań w Twoim egzaminie.');
        });

    },[questionID]);
    
    if (examData===null&&loading===null){
        setLoading('egzam-data');
        axios.get(`/egzamin/get-user-exam/${examID}`).then((response) => {
            setExamData(response.data.data);
            setLoading(null);
          }, (error) => {
            setLoading(null);
        });

        return <MyExamResultPreviewPagePlaceholder />
    }

    if (examData===null) return <MyExamResultPreviewPagePlaceholder />

    // if (question===null&&loading===null){
    //     setLoading('question');
        
    // }

    if (question===null) return <MyExamResultPreviewPagePlaceholder />

    function navNextQuestion(){
        let next = iQuestionOrder + 1;
        setQuestion(null);
        setQuestionState({...questionState,showAnswer:false,showComments:false,showAbout:false});
        navigate(`/app/egzamin-przegladaj/${examData?.ID}/${next}`);
    }

    function navPrevQuestion(){
        let next = iQuestionOrder - 1;
        setQuestion(null);
        setQuestionState({...questionState,showAnswer:false,showComments:false,showAbout:false});
        navigate(`/app/egzamin-przegladaj/${examData?.ID}/${next}`);
    }

    return <>
    <Helmet>
        <title>{examData.Name} - przegląd pytań i odpowiedzi - pytanie {questionID}</title>
    </Helmet>
    <Row>
        {/* <Col lg="2" md="2" xs="2"><MyExamResultPreviewPageIndexView examID={examData.ID} /></Col> */}
        <Col xs={12}>
            <h1 className="my-3 text-primary">{examData.Name} - przegląd</h1>
            <QuestionV2 setState={setQuestionState} state={questionState}
                        mode='result'
                        rowQuestion={question} disableCardTitle={true} 
                        disableAnswerClean={true}
                        onNext={navNextQuestion} onPrev={navPrevQuestion} 
                        disableNextBtn={iQuestionOrder===examData.QuestionCount}
                        disablePrevBtn={iQuestionOrder===1}
                        />
            <MyExamResultPreviewPagePaginator examID={examData.ID} current={parseInt(questionID)} />
        </Col>
    </Row>
    
    {/* <Question questionID={questionID} exam={examData} /> */}
    </>;
}