import { Button, ButtonGroup, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import BrowseNav from "./Nav";
import { Dispatch, SetStateAction, useState } from "react";
import { Helmet } from "react-helmet-async";
import { RowSpec } from "../Type/RowSpec";
import axios from "axios";
import checkResponse from "../Helpers/CheckResponse";
import DrawSpinner from "../Componets/DrawSpinner";
import { RecordCategory } from "../Type/RecordCategory";
import { RecordTag } from "../Type/RecordTag";
import { RecordSource } from "../Type/RecordSource";
import { RecordGeneratorV2Session } from "../Type/RecordGeneratorSession";
import { BrowseCategoryPaginator, BrowseCategoryPreview, BrowseSessionPaginator, BrowseSessionPreview, BrowseSourcePreview, BrowseTagPaginator, BrowseTagPreview } from "./BrowseItem";
import { FaBars } from "react-icons/fa6";

interface routeParams{
    SpecID:number|null,
    ID:number|null,
    QuestionID:number|null
}

interface stateBrowseIndex{
    specList:RowSpec[]|null,
    specID:number|null,
    isLoading:boolean;
}

interface stateBrowseCategory{
    loadedSpecID:number|null;
    categoryList:RecordCategory[]|null;
    loading:boolean;
}

interface stateBrowseTag{
    loadedSpecID:number|null;
    tagsList:RecordTag[]|null;
    loading:boolean;
}

interface stateBrowseSource{
    loadedSpecID:number|null;
    sourcesList:RecordSource[]|null;
    loading:boolean;
}

interface stateBrowseSession{
    loadedSpecID:number|null;
    sessionsList:RecordGeneratorV2Session[]|null;
    loading:boolean;
}

function BrowseSpecSelect({state,setState}:{state:stateBrowseIndex,setState:Dispatch<SetStateAction<stateBrowseIndex>>}){
    if (state.specList===null){
        if (state.isLoading===false){
            axios({url:'/specjalizacja/list',maxRedirects:0}).then((r)=>{
                setState({specList:r.data.data,specID:null,isLoading:false});
            }).catch((e)=>{
                if (e.response){
                    if (checkResponse(e.response.status))
                        return;
                }
            });
        }
    }

    return <>
    <div className="main-content">
        <Form.Group as={Row} className="mb-3" controlId="formSpec">
            <Form.Label column sm={2}>
                Specjalizacja
            </Form.Label>
            <Col sm={10}>
                {state.specList===null && state.isLoading===true ? <DrawSpinner text="Pobieranie listy specjalizacji..." /> :
                <Form.Select defaultValue={state.specID!} aria-label="Wybierz specjalizację..." onChange={(e)=>setState({...state,specID:parseInt(e.target.value)})}>
                    <option value="0">Wybierz specjalizację...</option>
                    {
                        state.specList?.map(function(d, idx){
                            return (<option value={d.ID} key={d.ID}>{d.Name} ({d.QuestionCount})</option>)
                        })
                    }
                </Form.Select>}
            </Col>
        </Form.Group>
    </div>
    </>
}

function BrowseDrawItem({name,count,location}:{name:string,count:number,location:string}){
    let navigate = useNavigate();
    return <>
        <Button variant="outline-primary" className="mb-2 d-flex text-start align-items-center me-2 flex-fill" onClick={()=>navigate(location)}>
            <span className="ms-2">
                {name}<br />
                <small className="badge text-bg-secondary">{count} pytań</small>
            </span>
        </Button>
    </>
}

function BrowseDrawItemFilter({placeholder,setFilter}:{placeholder:string,setFilter:Dispatch<SetStateAction<string>>}){
    return <FloatingLabel controlId="itemsFilter" label="Filtruj... Czego szukasz?" className="mb-3">
                <Form.Control type="text" placeholder={placeholder} onChange={(e)=>setFilter(e.target.value!)} autoComplete="false" />
            </FloatingLabel>
}

function BrowseCategory({indexState}:{indexState:stateBrowseIndex}){
    let [state,setState]                = useState<stateBrowseCategory>({loadedSpecID:null,categoryList:null,loading:false});
    let [filter,setFilter]              = useState<string>('');

    // console.log(ID,QuestionID);

    if (indexState.specID!==state.loadedSpecID){
        setState({loading:false,loadedSpecID:indexState.specID,categoryList:null});
        return <></>
    }

    if ((state.loadedSpecID??0)>0 && state.loading === false && state.categoryList===null){
        setState({...state,loading:true});
        axios.post(`/browse/categories`,{ID:indexState.specID}).then((r)=>{
            setState({categoryList:r.data.data,loading:false,loadedSpecID:indexState.specID});
        }).catch((e)=>{
        });
    }

    let filteredList = state.categoryList;
    if (filter.length>=1&&state.categoryList){
        filteredList  = state.categoryList.filter(x=>x.Name.toLowerCase().includes(filter.toLowerCase()));
    }
    
    return <>
        <Helmet>
            <title>Przeglądaj bazę pytań po kategoriach</title>
        </Helmet>
        <div className="main-content">
            {state.loading===true?<DrawSpinner text="Pobieranie listy kategorii dla wybranej specjalizacji..." />:null}
            {state.loading===false&&state.categoryList?.length===0?<p>Niestety wybrana specjalizacja nie została jeszcze podzielona na kategorie.</p>:null}
            {(state.categoryList?.length??0)>0?
            <>
                <BrowseDrawItemFilter placeholder="np. Kardiologia..." setFilter={setFilter} />
                <div className="d-flex flex-wrap mt-1">{filteredList?.map(function(i){
                    return <BrowseDrawItem name={i.Name} count={i.Count} key={`categoryBtn-${i.ID}`} location={`/app/przegladaj/kategorie/${indexState.specID}/${i.ID}`} />
                })}</div>
            </>
            :null}
        </div>
    </>
}



function BrowseTag({indexState}:{indexState:stateBrowseIndex}){
    let [state,setState]    = useState<stateBrowseTag>({loadedSpecID:null,tagsList:null,loading:false});
    let [filter,setFilter]  = useState<string>('');

    if (indexState.specID!==state.loadedSpecID){
        setState({loading:false,loadedSpecID:indexState.specID,tagsList:null});
        return <></>
    }

    if ((state.loadedSpecID??0)>0 && state.loading === false && state.tagsList===null){
        setState({...state,loading:true});
        axios.post(`/browse/tags`,{ID:indexState.specID}).then((r)=>{
            setState({tagsList:r.data.data,loading:false,loadedSpecID:indexState.specID});
        }).catch((e)=>{
        });
    }

    let filteredList = state.tagsList;
    if (filter.length>=1&&state.tagsList){
        filteredList  = state.tagsList.filter(x=>x.Name.toLowerCase().includes(filter.toLowerCase()));
    }
    
    return <>
        <Helmet>
            <title>Przeglądaj bazę pytań po kategoriach</title>
        </Helmet>
        <div className="main-content">
            {state.loading===true?<DrawSpinner text="Pobieranie listy tagów dla wybranej specjalizacji..." />:null}
            {state.loading===false&&state.tagsList?.length===0?<p>Niestety wybrana specjalizacja nie została jeszcze podzielona na szczegółowe tagi.</p>:null}
            {(state.tagsList?.length??0)>0?
            <>
                <BrowseDrawItemFilter placeholder="np. Kardiologia..." setFilter={setFilter} />
                <div className="d-flex flex-wrap mt-1">{filteredList?.map(function(i){
                    return <BrowseDrawItem name={i.Name} count={i.Count??0} key={`tagBtn-${i.ID}`} location={`/app/przegladaj/tagi/${indexState.specID}/${i.ID}`}  />
                })}</div>
            </>
            :null}
        </div>
    </>
}


function BrowseSource({indexState}:{indexState:stateBrowseIndex}){
    let [state,setState]    = useState<stateBrowseSource>({loadedSpecID:null,sourcesList:null,loading:false});
    let [filter,setFilter]  = useState<string>('');

    if (indexState.specID!==state.loadedSpecID){
        setState({loading:false,loadedSpecID:indexState.specID,sourcesList:null});
        return <></>
    }

    if ((state.loadedSpecID??0)>0 && state.loading === false && state.sourcesList===null){
        setState({...state,loading:true});
        axios.post(`/browse/sources`,{ID:indexState.specID}).then((r)=>{
            setState({sourcesList:r.data.data,loading:false,loadedSpecID:indexState.specID});
        }).catch((e)=>{
        });
    }

    let filteredList = state.sourcesList;
    if (filter.length>=1&&state.sourcesList){
        filteredList  = state.sourcesList.filter(x=>x.Name.toLowerCase().includes(filter.toLowerCase()));
    }
    
    return <>
        <Helmet>
            <title>Przeglądaj bazę pytań po źródłach</title>
        </Helmet>
        <div className="main-content">
            {state.loading===true?<DrawSpinner text="Pobieranie listy źródeł dla wybranej specjalizacji..." />:null}
            {state.loading===false&&state.sourcesList?.length===0?<p>Niestety wybrana specjalizacja nie została jeszcze podzielona na szczegółowe tagi.</p>:null}
            {(state.sourcesList?.length??0)>0?
            <>
                <BrowseDrawItemFilter placeholder="np. Kardiologia..." setFilter={setFilter} />
                <div className="d-flex flex-wrap mt-1">{filteredList?.map(function(i){
                    return <BrowseDrawItem name={i.Name} count={i.Count??0} key={`tagBtn-${i.ID}`} location={`/app/przegladaj/zrodla/${indexState.specID}/${i.ID}`}  />
                })}</div>
            </>
            :null}
        </div>
    </>
}
function BrowseSession({indexState}:{indexState:stateBrowseIndex}){
    let [state,setState]    = useState<stateBrowseSession>({loadedSpecID:null,sessionsList:null,loading:false});
    let [filter,setFilter]  = useState<string>('');

    if (indexState.specID!==state.loadedSpecID){
        setState({loading:false,loadedSpecID:indexState.specID,sessionsList:null});
        return <></>
    }

    if ((state.loadedSpecID??0)>0 && state.loading === false && state.sessionsList===null){
        setState({...state,loading:true});
        axios.post(`/browse/sessions`,{ID:indexState.specID}).then((r)=>{
            setState({sessionsList:r.data.data,loading:false,loadedSpecID:indexState.specID});
        }).catch((e)=>{
        });
    }

    let filteredList = state.sessionsList;
    if (filter.length>=1&&state.sessionsList){
        filteredList  = state.sessionsList.filter(x=>(x.Year+' '+x.SeasonName).toLowerCase().includes(filter.toLowerCase()));
    }
    
    return <>
        <Helmet>
            <title>Przeglądaj bazę pytań po źródłach</title>
        </Helmet>
        <div className="main-content">
            {state.loading===true?<DrawSpinner text="Pobieranie listy sesji PES dla wybranej specjalizacji..." />:null}
            {state.loading===false&&state.sessionsList?.length===0?<p>Niestety nie mamy sesji z wybranej specjalizacji.</p>:null}
            {(state.sessionsList?.length??0)>0?
            <>
                <BrowseDrawItemFilter placeholder="np. 2022..." setFilter={setFilter} />
                <div className="d-flex flex-wrap mt-1">{filteredList?.map(function(i){
                    return <BrowseDrawItem name={i.Year + ' ' + i.SeasonName} count={i.QuestionCount??0} key={`tagBtn-${i.ID}`} location={`/app/przegladaj/sesje/${indexState.specID}/${i.ID}`}  />
                })}</div>
            </>
            :null}
        </div>
    </>
}

export function BrowseIndexShowNavBtn({setShowNav}:{setShowNav:Dispatch<SetStateAction<boolean>>}){
    return <div className="d-block d-lg-none mb-1 me-2">
        <Button onClick={()=>setShowNav(true)} variant="outline-primary" size="sm"><FaBars /></Button>
    </div>
}

function BrowseIndex({page}:{page:string}){
    let navigate                = useNavigate();
    let [state,setState]        = useState<stateBrowseIndex>({specID:null,specList:null,isLoading:false});
    let [showNav,setShowNav]    = useState<boolean>(false);
    let {SpecID,ID,QuestionID}  = useParams();
    const iSpecID       = parseInt(SpecID!);
    const iPreviewID    = parseInt(ID!);
    const iQuestionID   = parseInt(QuestionID!);

    // console.log(iPreviewID);

    if (iSpecID>0&&state.specID===null){
        setState({...state,specID:iSpecID});
    }

    if (page==='index'){
        return <>
        <Row>
            <Col lg={2}>
                <BrowseNav activeTab={page} setShow={setShowNav} show={showNav} />
            </Col>
            <Col lg={10} xs={12}>
                <div className="main-title">
                    <h1>Przeglądaj</h1>
                </div>
                <div className="main-content">
                    Możesz przeglądać pytania według podziału na sesjach, kategoriach, tagach i źródła.
                </div>
                <ButtonGroup className="mt-2 mb-4">
                    <Button variant="outline-primary" onClick={()=>navigate('/app/przegladaj/sesje')}>sesjach</Button>
                    <Button variant="outline-primary" onClick={()=>navigate('/app/przegladaj/kategorie')}>kategoriach</Button>
                    <Button variant="outline-primary" onClick={()=>navigate('/app/przegladaj/tagi')}>tagach</Button>
                    <Button variant="outline-primary" onClick={()=>navigate('/app/przegladaj/zrodla')}>źródła</Button>
                </ButtonGroup>
            </Col>
        </Row>
        </>
    }

    return <>
        <Row>
            <Col lg={2}>
                <BrowseNav activeTab={page} setShow={setShowNav} show={showNav} />
            </Col>
            <Col lg={10} xs={12}>
                {iPreviewID>0?
                <>
                    {page==='cat'?<>
                        <BrowseCategoryPreview setShowNav={setShowNav} />
                        <BrowseCategoryPaginator SpecID={iSpecID} CategoryID={iPreviewID} ID={iQuestionID} />
                    </>
                    :null}
                    {page==='tag'? <>
                        <BrowseTagPreview setShowNav={setShowNav} />
                        <BrowseTagPaginator SpecID={iSpecID} TagID={iPreviewID} ID={iQuestionID} />
                    </>
                    :null}
                    {page==='sur'?<BrowseSourcePreview setShowNav={setShowNav} />:null}
                    {page==='ses'?
                        <><BrowseSessionPreview setShowNav={setShowNav} />
                          <BrowseSessionPaginator SpecID={iSpecID} SessionID={iPreviewID} ID={iQuestionID} />
                        </>
                    :null}
                </>
                :<>
                {page==='cat'?<>        
                    <div className="main-title">
                        <BrowseIndexShowNavBtn setShowNav={setShowNav} />
                        <h1>Przeglądaj pytania po kategoriach</h1>
                    </div>
                    <BrowseSpecSelect state={state} setState={setState} />
                    <BrowseCategory indexState={state} />
                </>:null}
                {page==='tag'?<>        
                    <div className="main-title">
                        <BrowseIndexShowNavBtn setShowNav={setShowNav} />
                        <h1>Przeglądaj pytania po tagach</h1>
                    </div>
                    <BrowseSpecSelect state={state} setState={setState} />
                    <BrowseTag indexState={state} />
                </>:null}
                {page==='sur'?<>        
                    <div className="main-title">
                        <BrowseIndexShowNavBtn setShowNav={setShowNav} />
                        <h1>Przeglądaj pytania po źródłach</h1>
                    </div>
                    <BrowseSpecSelect state={state} setState={setState} />
                    <BrowseSource indexState={state} />
                </>:null}
                {page==='ses'?<>        
                    <div className="main-title">
                        <BrowseIndexShowNavBtn setShowNav={setShowNav} />
                        <h1>Przeglądaj pytania po sesjach PES</h1>
                    </div>
                    <BrowseSpecSelect state={state} setState={setState} />
                    <BrowseSession indexState={state} />
                </>:null}
                </>}
            </Col>
        </Row>
    </>
}

export default BrowseIndex;