import { Dispatch, SetStateAction, useRef, useState } from "react";
import { Button, Col, Form, InputGroup, Row, ToggleButton } from "react-bootstrap";
import { MdArrowLeft, MdArrowRight, MdCheck, MdCheckBoxOutlineBlank } from "react-icons/md";
import { ExamPageGeneratorV2State } from "../PageGenerator";
import { ExamPageGeneratorV2StepCategoryDrawCategory } from "./Category";
import { ExamPageGeneratorV2StepTagsDrawTag } from "./Tag";
import axios from "axios";
import DrawSpinner from "../../Componets/DrawSpinner";
import { useNavigate } from "react-router-dom";

interface stateExamPageGeneratorV2Sum{
    sum:number|null;
    useCEM:boolean;
    useLib:boolean;
    specID:number[];
    sessionsChecks:number[];
    categoriesChecks:number[];
    tagsChecks:number[];
}

export function ExamPageGeneratorV2Sum({state,setState}:{state:ExamPageGeneratorV2State,setState:Dispatch<SetStateAction<ExamPageGeneratorV2State>>}){
    // let [loading,setLoading]    = useState<boolean>(false);
    let navigate                = useNavigate();
    let inputName               = useRef<HTMLInputElement|null>(null);
    let inputType               = useRef<HTMLSelectElement|null>(null);
    let [sumState,setSumState]  = useState<stateExamPageGeneratorV2Sum>({
        useCEM:state.useCEM,
        useLib:state.useLib,
        specID:state.specID,
        sessionsChecks:state.sessionsChecks,
        categoriesChecks:state.categoriesChecks,
        tagsChecks:state.tagsChecks,
        sum:state.sum
    });
    // console.log(state);

    let backStep = 'tags';
    if (state.tags?.length===0)
        backStep='categories';
    if (state.categories?.length===0)
        backStep='session';
    if (state.useCEM===false)
        backStep='source';


    // let disabledCreated = false;

    let selectedCategories  = null;
    let selectedTags        = null;
    if (state.categories && state.categories.length > 0 && sumState.categoriesChecks?.length > 0 )
        selectedCategories = state.categories.filter(x=>sumState.categoriesChecks.includes(x.ID));

    if (state.tags && state.tags.length > 0 && sumState.tagsChecks?.length > 0 )
        selectedTags = state.tags.filter(x=>sumState.tagsChecks.includes(x.ID));

    if (state.questionCount===null){
        if (state.loading===false){
            setState({...state,loading:true});
            axios.post(`/generator-v2/precreate`,{
                useSpec:state.specID,
                useSession:state.sessionsChecks,
                useCEM:state.useCEM,
                useLib:state.useLib,
                useTags:state.tagsChecks,
                useCategories:state.categoriesChecks
            }).then((r) => {
                setState({...state,loading:false,questionCount:r.data.data});
            }, (error) => {
            });
        }
    }

    if (state.page==='create'){
        if (state.loading===false){
            setState({...state,loading:true});
            let selectedType = inputType.current?inputType.current.options[inputType.current.selectedIndex].value:'nauka';
            axios.post(`/generator-v2/create`,{
                useSpec:state.specID,
                useCEM:state.useCEM,
                useLib:state.useLib,
                useSession:state.sessionsChecks,
                useCategories:state.categoriesChecks,
                useTags:state.tagsChecks,
                type:selectedType,
                name:inputName.current?inputName.current.value:null,
            }).then((response) => {
                navigate(`/app/egzamin/${response.data.data.ID}/1`);
                        
            }, (error) => {
                setState({...state,loading:false,page:'sum'});
            });
        }
    }

    let questionCountNotNull = state.questionCount??0;
    let formDisabled         = ( (questionCountNotNull<=0 || questionCountNotNull>460 ) || ( state.page==='create' && state.loading===true ) );

    return <>
        <Row>
            <Col xs={12}>
                <div className="main-content">
                    <h2 className="pc">Podsumowanie</h2>
                    <h3 className="pc my-1">Źródła pytań</h3>
                    <div className="d-flex">
                        <ToggleButton disabled={true} className="me-md-2 flex-fill" id="tgbSourceCEM" type="checkbox" variant="outline-primary" checked={state.useCEM} value="cem">
                            {sumState.useCEM ? <MdCheck size={32} /> : <MdCheckBoxOutlineBlank size={32} />}
                            <span className="ms-1">Baza CEM</span>
                        </ToggleButton>
                        <ToggleButton disabled={true} className="me-md-2 flex-fill" id="tgbSourceLib" type="checkbox" variant="outline-primary" checked={state.useLib} value="cem">
                            {sumState.useLib ? <MdCheck size={32} /> : <MdCheckBoxOutlineBlank size={32} />}
                            <span className="ms-1">Baza pytań autorskich</span>
                        </ToggleButton>
                    </div>
                    {selectedCategories !== null && selectedCategories.length > 0 ? 
                    <>
                        <h3 className="pc my-1">Kategorie</h3>
                        <div className="d-flex flex-wrap">
                            {selectedCategories.map(function(i){
                                return <ExamPageGeneratorV2StepCategoryDrawCategory key={`category-${i.ID}`} category={i} state={state} setState={setState} size='sm' />
                            })}
                        </div>
                    </>
                    :null}
                    {selectedTags !== null && selectedTags.length > 0 ? 
                    <>
                        <h3 className="pc my-1">Tagi</h3>
                        <div className="d-flex flex-wrap">
                            {selectedTags.map(function(i){
                                return <ExamPageGeneratorV2StepTagsDrawTag key={`tag-${i.ID}`} tag={i} state={state} setState={setState} size='sm' />
                            })}
                        </div>
                    </>
                    :null}

                    <div className={questionCountNotNull>460||questionCountNotNull===0?"alert alert-danger":"alert alert-success"} role="alert">
                        <p className="fa-5 fw-bold">Rzeczywista liczba pytań : 
                            {state.loading===true && state.questionCount===null ? <DrawSpinner className="ms-1" /> : <>{questionCountNotNull}</>}
                        <br />
                        {questionCountNotNull>460&&state.loading===false?<>Musisz ograniczyć liczbę pytań do 460.</>:null}
                        {questionCountNotNull===0&&state.loading===false?<>Nie można utworzyć pustego egzaminu.</>:null}
                        {questionCountNotNull>0&&questionCountNotNull<=460&&state.loading===false?<>Wszystko ok!</>:null}
                        </p>
                    </div>

                    <div className="d-flex mt-3">
                        <Button variant="secoundary" onClick={()=>setState({...state,page:backStep})}><MdArrowLeft /> powrót</Button>
                        <InputGroup className="flex-fill">
                            <Form.Select ref={inputType} disabled={formDisabled} aria-label="Wybierz tryb" defaultValue={"nauka"}>
                                <option value="nauka">Tryb nauki</option>
                                <option value="egzamin">Tryb egzaminu</option>
                            </Form.Select>
                            <Form.Control ref={inputName} maxLength={40} disabled={formDisabled} type="text" placeholder="Nazwa..." aria-label="Wprowadź nazwę dla egzaminu..." />
                            <Button disabled={formDisabled} onClick={() => setState({...state,page:'create',loading:false})}>Stwórz <MdArrowRight /></Button>
                        </InputGroup>
                    </div>
                </div>
            </Col>
        </Row>
    </>
}